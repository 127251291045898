import { Pipe, PipeTransform } from '@angular/core';
import { Formatting } from '../formatting';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return '-';
    }

    if (args && isNaN(args)) {
      return null;
    }

    return Formatting.formatReduce(value, args);
  }

}