import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ScenarioService } from 'app/services/scenario.service';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/collateralRampUp/collateralRampUp.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';
import { ICollateralRampUpEdit } from '@acl-cq-lib-pages-scenario-edit/collateralRampUp/ICollateralRampUpEdit';
import { ICarrier } from 'collateralq-components/src/lib/scenarios-analysis/scenarioEdit/collateralRampUp/ICarrier';
import { ReferenceDataService } from 'app/services/referenceData.service';
import { CollateralRampUpValidator } from 'app/shared/validators/collateralRampUp.validator';
import { CarrierType } from 'app/models';

@Component({
    selector: 'app-scenario-collateral-rampup',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CollateralRampUpComponent extends ReactiveComponent implements ICollateralRampUpEdit {
    @Input() form: FormGroup;
    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT.COLLATERAL_RAMP_UP';
    public translation: any = {};
    public carrierList: ICarrier[];
    public rampupRows: number[];

    public get isIncumbentComparison(): boolean {
        return +this.form.get('comparisonType').value === CarrierType.incumbent;
    }

    constructor(private _fb: FormBuilder,
        private _scenarioService: ScenarioService,
        private _refDataService: ReferenceDataService,
        private _changeDetection: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.carrierList = this.carriers.controls.map((c, i) => {

            const carrierRampUpFormArray = c.get('collateralRampUps') as FormArray;

            return {
                isFormDisabled: i < 1 ? false : this.isIncumbentComparison,
                isAddButtonDisabled: carrierRampUpFormArray.controls.length >= 5,
                rampUpFormArray: carrierRampUpFormArray,
                rampUpLength: carrierRampUpFormArray.controls.length
            };
        });

        this.setFormDisable();

        this.rampupRows = this.rampUpMerge;

        this._subscriptions.push(

            this.form.get('comparisonType').valueChanges.subscribe(c => {
                this.toggleComparisonType();
            }),

            this.form.get('projectionPeriod').valueChanges.subscribe(c => {
                this.updateValidators(c);
            })
        );

        if (this._scenarioService.selectedScenario.isSaved || this._scenarioService.selectedScenario.isRTriggered) {
            // this._scenarioService.markFormGroupTouched(this.form);
            // this._scenarioService.markFormArrayTouched(this.carriers);
            this._scenarioService.markFormArrayTouched(this.carriers); //TODO: Is this correct?
        }

    }

    public addRampUp(carrierIndex: number): void {
        const carrier = this.carrierList[carrierIndex];

        if (carrier.isFormDisabled || carrier.rampUpFormArray.length === 5) {
            return;
        }

        carrier.rampUpFormArray.push(this._fb.group({
            collateralRequired: [this._refDataService.referenceData.collateralRampUpTypes.filter(c => c.isDefault)[0].value, [Validators.required, Validators.min(0)]]
            , day: ['', [Validators.required, Validators.min(1),
            Validators.max(this._scenarioService.selectedScenario.projectionPeriod * 365),
            CollateralRampUpValidator(carrier.rampUpFormArray.length, carrier.rampUpFormArray)]]
        }));

        carrier.rampUpLength = carrier.rampUpFormArray.controls.length;
        this.rampupRows = this.rampUpMerge;
        this.setAddButtonDisabled(carrierIndex);
    }

    public deleteCollateralRampUp(carrierIndex: number, rampUpIndex: number): void {
        const carrier = this.carrierList[carrierIndex];

        if (carrier.isFormDisabled) {
            return;
        }

        carrier.rampUpFormArray.removeAt(rampUpIndex);
        carrier.rampUpLength = carrier.rampUpFormArray.controls.length;
        this.setAddButtonDisabled(carrierIndex);
        this.forceAngularChangeDetection();
    }

    private toggleComparisonType(): void {
        this.carrierList.forEach((carrier, i) => carrier.isFormDisabled = i < 1 ? false : this.isIncumbentComparison);

        this.setFormDisable();
    }

    private get rampUpMerge() {
        const rampUpsLengthsList = this.carriers.controls.map((c) => (c.get('collateralRampUps') as FormArray).length);
        const maxLength = Math.max(...rampUpsLengthsList);

        return Array.from({ length: maxLength + 1 }, (v, i) => i);
    }

    private get carriers(): FormArray {
        return this.form.get('carriers') as FormArray;
    }

    private setAddButtonDisabled(carrierIndex?: number): void {
        if (typeof carrierIndex === 'undefined') {
            this.carrierList.forEach((carrierTable) => {
                carrierTable.isAddButtonDisabled = carrierTable.rampUpFormArray.length === 5;
            });
        } else {
            this.carrierList[carrierIndex].isAddButtonDisabled = this.carrierList[carrierIndex].rampUpFormArray.length === 5;
        }
    }

    private forceAngularChangeDetection(): void {
        this.rampupRows = [];
        this._changeDetection.detectChanges();
        this.rampupRows = this.rampUpMerge;
    }

    private updateValidators(c): void {
        this.carriers.controls.forEach((carrierControl) => {
            const formArray = carrierControl.get('collateralRampUps') as FormArray;
            formArray.controls.forEach(abCtrl => {
                const formGroup = abCtrl as FormGroup;
                formGroup.controls.day.setValidators([Validators.required, Validators.min(1),
                Validators.max(+c * 365),
                CollateralRampUpValidator(formArray.length, formArray)]);
                formGroup.controls.day.updateValueAndValidity();
            });
        });
    }

    private setFormDisable(): void {
        this.carrierList.filter(c => c.isFormDisabled).forEach(carrier => {
            carrier.rampUpFormArray.controls.forEach(formArraycontrol => {
                formArraycontrol.disable();
            });
        });

        this.carrierList.filter(c => !c.isFormDisabled).forEach(carrier => {
            carrier.rampUpFormArray.controls.forEach(formArraycontrol => {
                formArraycontrol.enable();
            });
        });
    }
}

