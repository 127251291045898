import { FormArray, FormBuilder, FormGroup } from '@angular/forms';


Object.toKeyValue = function <T>(obj: T, key?: string): { [key: string]: any, value: any }[] {
    const keys = Object.keys(obj);
    return keys.map(c => {
        const aux: { [key: string]: any, value: any } = {
            value: obj[c]
        };
        aux[key || 'key'] = c;
        return aux;
    });
};

Object.toArray = function <T>(obj: T, key: string): any[] {
    const aux = JSON.parse(JSON.stringify(obj));
    const keys = Object.keys(aux);
    return keys.map(c => {
        aux[c][key] = c;
        return aux[c];
    });
};


Object.toFormGroup = function <T>(object: any, fb: FormBuilder, customValidators?: any): FormGroup {
    return createForm(object);
    function createForm(obj, validators?): any {
        if (isArray(obj)) {
            return traverseArray(obj);
        } else if (isObject(obj)) {
            return traverseObject(obj, customValidators);
        } else {
            if (validators) {
                return fb.control(obj, validators);
            }
            return fb.control(obj);
        }
    }

    function traverseArray(arr) {
        const array = new FormArray([]);
        arr.forEach(c => {
            array.push(createForm(c));
        });
        return array;
    }

    function traverseObject(obj, customValidators) {
        const group = new FormGroup({});
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (customValidators && customValidators[key]) {
                    group.addControl(key, createForm(obj[key], customValidators[key]));
                } else {
                    group.addControl(key, createForm(obj[key]));
                }
            }
        }
        return group;
    }

    function isArray(o) {
        return Object.prototype.toString.call(o) === '[object Array]';
    }

    function isObject(obj) {
        return (typeof obj === 'object') && (obj !== null);
    }
};


Object.markFormGroupTouched = function <T>(fg: FormGroup): void {
    return markFormGroupTouched(fg);
    function markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (control.controls) {
                markFormGroupTouched(control);
            }
        });
    }
};

Object.markFormArrayTouched = function <T>(fg: FormArray): void {
    return markFormArrayTouched(fg);
    function markFormArrayTouched(formArray: FormArray) {
        (<any>Object).values(formArray.controls).forEach(control => {
            control.markAsTouched();
            if (control.controls) {
                Object.markFormGroupTouched(control);
            }
        });
    }
};
