import { Component, OnInit, OnDestroy } from '@angular/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results-summary/scenarioResultsSummaryContainer.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-results-summary/scenarioResultsSummaryContainer.component.scss';
import { IScenarioResultsSummary } from '@acl-cq-lib-pages-scenario-results-summary/IScenarioResultsSummary';
import { ScenarioService } from 'app/services/scenario.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'scenario-results-summary-container',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioResultsSummaryContainerComponent implements OnInit, IScenarioResultsSummary, OnDestroy {

    public isWarningMsgVisible = false;
    public translation: any = {};
    public translationKey: string = 'COLLATERAL.SCENARIOS.RESULTS.SUMMARY_TAB';
    private _subs: Subscription = new Subscription();

    constructor(private _scenarioService: ScenarioService) {
        this._subs.add(this._scenarioService.isCoveragesMismatch$.subscribe((isCoverageMismatch: boolean) => {
            this.isWarningMsgVisible = isCoverageMismatch;
        }));
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }
}
