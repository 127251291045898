/**
    The service file will keep all the common & general functions
    to be used across all the components & services
 */
import { Injectable }             from '@angular/core';
import { Observable ,  Subject }             from 'rxjs';

@Injectable()
export class UtilityService {
    public ChartConfiguration_Height: number = 260;
    public ChartConfiguration_ZoomType: string = 'x';
    public ChartConfiguration_BackgroundColor: string = 'rgba(255, 255, 255, 0.0)';
    public ChartConfiguration_FontFamily: string = 'Neue Haas Grotesk, Arial, sans-serif !important';
    public ChartConfiguration_FontSize: string = '10px';
    public ChartConfiguration_FontWeight: number  =  500;
    public ChartConfiguration_Color: string = '#A0A0A0';
    public ChartConfiguration_CharType: ChartType = ChartType.Line;
    sub$: Subject<any> = new Subject<any>();
    constructor() {
    }
    //Pass the file to read its data in Buffer array
    readFile(file: File, fileModel: any): Observable<any> {
        let reader = new FileReader();
        reader.onload = () => {
            this.sub$.next( {result: reader.result, fileModel});
        };
        reader.readAsArrayBuffer(file);
        return this.sub$.asObservable();
    }//end of _readFile


    _readFile(file: File, fileModel: any): Promise<any> {
        return new Promise((resolve) => {
          let reader = new FileReader();
          reader.onload = () => {
             resolve( {res: reader.result, fileModel });
          };
          reader.readAsArrayBuffer(file);
        });
    }
}

export class ChartType {
    static Line = 'line';
    static Column = 'column';
}
