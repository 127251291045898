import { Injectable, EventEmitter } from '@angular/core';
import { RunService, ModalService, ApplicationInsightsService } from '@wtw/platform/services';
import { ReferenceDataService } from './referenceData.service';
import { RiskType, CarrierAssessmentData, CarrierComparisonType, CarrierStatus, RunViewModel, AssessmentLOBInputSummary } from 'app/models';
import { IStandardModalConfig } from '@wtw/platform/interfaces';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { RunModel } from '@wtw/platform/api/dtos';
import { Subject, Observable, of } from 'rxjs';
import { ExecutionLogService } from './ExecutionLogService';
import { ExecutionLogProxy } from 'app/api/generated/ExecutionLogController';

@Injectable()
export class NegotiationService {

    navigationModel: any[];
    pageSelected: string;
    carrierList: CarrierAssessmentData[];
    selectedCarrier: CarrierAssessmentData;
    run: RunViewModel;
    currencyInfo: any;
    runId: number;
    riskTypes: { [key: string]: RiskType; };
    navigateAwayTriggered: EventEmitter<{ item: string, obj: any }> = new EventEmitter<{ item: string, obj: any }>();
    subscription: any;
    rFinishedEvent: Subject<boolean> = new Subject();
    selectedRule: any;
    showChart: boolean = false;
    private _triggerUiSignal: Observable<boolean>; //Edgar: This is to hack run service SignalUi

    constructor(private _runService: RunService, private _referenceDataService: ReferenceDataService,
        private _modalService: ModalService, private _translate: TranslateService,
        private _executionLogService: ExecutionLogService,
        private _executionLogProxy: ExecutionLogProxy,
        private _applicationInsightsService: ApplicationInsightsService
    ) { }

    init() {
        this._runService.activeRun.subscribe(c => {
            this.initActivatedRun(c);
            this.carrierList.forEach(c => (c as any).collapsed = true);
        });

        this.navigationModel = [
            {
                class: 'selected',
                key: 'CARRIERLIST',
                action: () => this.clicked('CARRIERLIST', this.selectedCarrier)
            },
            {
                class: '',
                key: 'ASSESSMENT',
                action: () => this.clicked('ASSESSMENT', this.selectedCarrier)
            }, {
                class: '',
                key: 'RULES',
                action: () => this.clicked('RULES', this.selectedCarrier)
            }
        ];
        this.pageSelected = 'CARRIERLIST';
    }

    initActivatedRun(c: RunModel) {
        this.run = c.data;
        this.runId = c.runId;
        this.currencyInfo = c.currencyInfo;
        let uniqueValues = Array.from(new Set(this.run.collateralYear.map(c => c.riskTypeId)));
        this.riskTypes = this._referenceDataService.referenceData.riskTypes.filter(c => uniqueValues.some(d => d === c.id)).toDictionary('id');
        this.carrierList = this.run.carrierAssessmentData;
    }

    clicked(item: string, obj?: any): any {
        if (this.pageSelected !== item && this.pageSelected === 'ASSESSMENT') {
            this.navigateAwayTriggered.emit({ item, obj });
        } else {
            this.actionExecute(item, obj);
        }
    }

    actionExecute(item: string, obj?: any) {
        this.navigationModel.forEach(c => {
            if (item === c.key) {
                if (c.class === '') {
                    c.class = 'selected';
                }
            } else if (c.class === 'selected') {
                c.class = '';
            }
        });
        this.pageSelected = item;
        this.selectedCarrier = obj;
    }

    setCarrierListNavigation() {
        this.carrierList.forEach(c => {
            (c as any).actions = [
                {
                    translation: 'ASSESSMENT',
                    action: () => this.actionExecute('ASSESSMENT', c),
                    enabled: true,
                    class: 'col-sm-6'
                },
                {
                    translation: 'RULES',
                    action: () => this.actionExecute('RULES', c),
                    enabled: true,
                    class: 'col-sm-6'
                }
            ];
        });
    }

    collapseAllCarrierList() {
        this.carrierList.forEach(c => (c as any).collapsed = true);
    }

    save() {
        this._runService.persist(this.runId, this.run, this.currencyInfo);
    }

    getRiskTypeIdByLob(lob) {
        return this._referenceDataService.referenceData.riskTypes.find(c => c.riskTypeName === lob);
    }

    runCarrierTrigger(): any {
        return this._runService.executeTrigger(this.runId, this.run,
            { number: 'processCarrierAssessment' as any, param: `${this.selectedCarrier.negotiationCarrierId}` },
            this.currencyInfo, undefined, true);
    }

    downloadData(lobInputs: AssessmentLOBInputSummary[], year: number, carrierName: string, status: CarrierStatus) {
        const data = this.exportAsExcelFile(this.getCarrierDataByYear(lobInputs, year));
        this.saveAsExcelFile(data, `${carrierName}_${CarrierStatus[status]}_${year}.xlsx`);
    }

    downloadAllData() {
        this.subscription = this._runService.activeRun.subscribe(c => {
            this.initActivatedRun(c);
            const zip = new JSZip();
            this.carrierList.forEach(c => {
                const lobInputs = c.carrierAssessmentInputs.find(c => c.carrierComparisonType === CarrierComparisonType.carrierRenewal).lobInputs;
                const data = {
                    [`${c.carrierName}_${CarrierStatus[c.carrierStatus]}_${c.previousYearDataStatus.year}.xlsx`]: this.exportAsExcelFile(this.getCarrierDataByYear(lobInputs, c.previousYearDataStatus.year)),
                    [`${c.carrierName}_${CarrierStatus[c.carrierStatus]}_${c.currentYearDataStatus.year}.xlsx`]: this.exportAsExcelFile(this.getCarrierDataByYear(lobInputs, c.currentYearDataStatus.year))
                };
                Object.keys(data).forEach(d => zip.file(d, data[d]));
            });
            zip.generateAsync({ type: 'blob' })
                .then(c => {
                    FileSaver.saveAs(c, `${this.runId}_${this.run.clientName}_negotiation_carrierRenewalInputData.zip`);
                });
        });
        this.subscription.unsubscribe();
    }

    clearCarrierResults(): any {
        const carrierData = this.selectedCarrier as CarrierAssessmentData;
        const aux = carrierData.carrierAssessmentInputs.find(c => c.carrierComparisonType === CarrierComparisonType.carrierRenewal);
        aux.summaryInputs.adjustmentValues.paidLossCredit = 0;
        aux.summaryInputs.adjustmentValues.otherCollateralAdjustments = 0;
        aux.summaryInputs.adjustmentValues.total = 0;
        aux.summaryInputs.paidValues.actualPaidAtRenewal = 0;
        aux.summaryInputs.paidValues.expectedAdditionalPaid = 0;
        aux.summaryInputs.paidValues.total = 0;
        aux.summaryInputs.ultimateValues.completeYear = 0;
        aux.summaryInputs.ultimateValues.inForceYear = 0;
        aux.summaryInputs.ultimateValues.lossForecast = 0;
        aux.summaryInputs.ultimateValues.total = 0;
        aux.summaryInputs.total = 0;
    }

    runCalculation(messageKey: string): void {
        this._triggerUiSignal = of(false)
            .uiSignal({
                debugInfo: 'execute trigger ',
                uiLabel: this._translate.instant('GLOBAL.BUSY.EXECUTING')
            }); // false call to show the calculating window

        this.selectedCarrier.isRTriggered = true;
        this.runCarrierTrigger().subscribe(results => {
            this._triggerUiSignal.subscribe(_ => null).unsubscribe(); //hack to call uisignal then clear it
            const selectedCarrier = (results.data as RunViewModel).carrierAssessmentData.find(c => c.negotiationCarrierId === this.selectedCarrier.negotiationCarrierId);
            this.selectedCarrier = selectedCarrier;
            let data = this._executionLogService.generateExecutionLogForCarrier(this.selectedCarrier.carrierAssessmentOutputs.length - 1, this.selectedCarrier);
            this._executionLogProxy.saveExecutionLogs(data).subscribe(c => {
                this.rFinishedEvent.next(true);
                if (!this.run.isNegotiationAssessmentComplete) {
                    this._applicationInsightsService.send({
                        eventCategory: 'Negotiation',
                        eventAction: 'AssessmentComplete',
                        runId: this.runId
                    });
                    this.run.isNegotiationAssessmentComplete = true;
                }
            });
        }, error => {
            this._triggerUiSignal.subscribe(_ => null).unsubscribe(); //hack to call uisignal then clear it
            this.showRFailedModal(messageKey);
        });
    }

    downloadRulesExtract(json: any) {
        const data = this.exportAsExcelFile(json);
        this.saveAsExcelFile(data, `${this.selectedCarrier.carrierName}_${CarrierStatus[this.selectedCarrier.carrierStatus]}_ruleExtract.xlsx`);
    }

    hasRVersionChanged(carrier?: CarrierAssessmentData): boolean {
        const c = carrier ? carrier : this.selectedCarrier;

        const latestExecutionDate = new Date(c.carrierAssessmentOutputs[0].executionDate);
        const rVersionReleaseDate = new Date(this._referenceDataService.referenceData.rVersionReleaseDate);

        return latestExecutionDate < rVersionReleaseDate;
    }

    private showRFailedModal(messageKey: string): void {
        const translationKey = 'COLLATERAL.NEGOTIATION';

        const confirmSettings: IStandardModalConfig = {
            title: this._translate.instant(`${translationKey}.RFAILED.TITLE`),
            withHeader: false,
            message: this._translate.instant(`${translationKey}.${messageKey}`),
            yesButton: { display: true, text: this._translate.instant(`${translationKey}.RFAILED.CLOSE`) },
            noButton: { display: false, text: '' }
        };
        this._modalService.confirm(confirmSettings);

    }

    private getCarrierDataByYear(lobInputs: AssessmentLOBInputSummary[], year) {
        const aggregateData = [];
        const annualData = [];

        lobInputs.forEach(c => {
            const riskTypeData = JSON.parse(JSON.stringify(c.yearLOBInputs.find(d => d.year === year)));
            riskTypeData.annualLOBInputDetails = riskTypeData.annualLOBInputDetails.map(d => {
                return { ...{ lob: this.riskTypes[c.riskType].riskTypeName }, ...d };
            });
            riskTypeData.aggregateLOBInputDetails = { ...{ lob: this.riskTypes[c.riskType].riskTypeName }, ...riskTypeData.aggregateLOBInputDetails };
            aggregateData.push(riskTypeData.aggregateLOBInputDetails);
            annualData.push(riskTypeData.annualLOBInputDetails);
        });
        return {
            _ultAnalysis_annual: annualData.flatten(),
            _ultAnalysis_aggregate: aggregateData.flatten()
        };
    }

    private exportAsExcelFile(json: any): any {

        const worksheet = Object.keys(json).map(c => XLSX.utils.json_to_sheet(json[c]));
        const sheets = {};

        Object.keys(json).forEach((c, i) => {
            sheets[c] = worksheet[i];
        });
        const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: Object.keys(sheets) };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {

        const data: Blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        });
        FileSaver.saveAs(data, fileName);
    }
}
