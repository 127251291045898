import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ScenariosAnalysisResultContent } from 'app/models/index';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results/scenarios-analysis-result-content/scenarios-analysis-result-content.component.html';
import { ScenarioService } from 'app/services/scenario.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'scenarios-analysis-result-content',
    templateUrl: `${componentTemplate}`
})

export class ScenariosAnalysisResultContentComponent implements OnInit, OnDestroy {
    @Input() config: ScenariosAnalysisResultContent;

    public isReadyToDisplayCharts = false;
    private _subscriptions: Subscription = new Subscription();

    constructor(private _scenarioService: ScenarioService, private _changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this._subscriptions.add(this._scenarioService.scenarioChanged.subscribe(isTabChanged => {
            if (isTabChanged) {
                this.isReadyToDisplayCharts = false;

                this._changeDetectorRef.detectChanges();
                this.isReadyToDisplayCharts = true;

            }
        }));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }
}

