import { Component, OnInit } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary-container/scenario-summary-container.component.html';

@Component({
    selector: 'app-scenario-summary-container',
    templateUrl: `${componentTemplate}`
})

export class ScenarioSummaryContainerComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}