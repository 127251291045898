import { ScenariosModel } from '../../../models/index';
import { UltimateAnalysisProxy } from '../../../api/generated/UltimateAnalysisController';
import { ExcelUploadService } from '../../../services/excel-upload.service';
import { ViewChild, Component, OnInit, TemplateRef }
    from '@angular/core';
import { SiteHeaderService, RunService, ModalService }
    from '@wtw/platform/services';
import { RunNavigationComponent } from '@wtw/platform/pages/run-navigation/run-navigation.component';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';

import { RunModel, CurrencyInfo } from '@wtw/platform/api/dtos';
import { FormAwareNavigation }
    from '@wtw/platform/interfaces';
import { FormGroup }
    from '@angular/forms';
import { Coverage, RunViewModel, ClientCoverageData, FileUploadType }
    from '../../../api/dtos';

import * as componentTemplate from '@acl-cq-lib-pages-datainput/coverage-data/coverage-data.component.html';
import { ICoverageData } from '@acl-cq-lib-pages-datainput/coverage-data/IICoverageData';
import { BsModalService } from 'ngx-bootstrap';
import { NewFileUploadModalComponent } from './newFileUploadModal';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'col-coverage-data',
    templateUrl: `${componentTemplate}`
})

export class CoverageDataComponent extends ReactiveComponent implements OnInit, ICoverageData {
    translationKey = 'COLLATERAL.COVERAGEDATA.RISKTYPE';
    translation = {};
    currencyInfo: CurrencyInfo;
    runId: number;
    @ViewChild('customNavigationTemplate') customSiteFooterTemplate: TemplateRef<any>;
    runNavigation: RunNavigationComponent;
    parent?: FormAwareNavigation;
    rootForm: FormGroup;
    coverages: Coverage[];
    runViewModel: RunViewModel;

    uploadfilesSelected: boolean = false;
    clientId: number;
    uploadFilesButton: boolean = false;

    editMode = 'edit';

    dataTypes = ['Policy History', 'Loss Data', 'Risk Profile', 'Triangle'];
    baseCustomId = 1000;
    // Fake coverage data list for now
    clientCoverageDatas: ClientCoverageData[];
    dataUpload: any;
    constructor(private header: SiteHeaderService, private _run: RunService, private _uploadDataService: ExcelUploadService,
        private _ultimateAnalysisProxy: UltimateAnalysisProxy, private _bsModalService: BsModalService, private _translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this._subscriptions = [
            this._run.activeRun.subscribe(r => {
                this.loadRun(r);
            })
        ];
    }

    public persistRun() {
        this.runViewModel.dataUpload = this.dataUpload;
        this._subscriptions.push(this._ultimateAnalysisProxy.getCollateralYearData(this.runId).subscribe(c => {
            this.runViewModel.collateralYear = c.data;
            this.runViewModel.isNegotiationAssessmentComplete = false;
            this.runViewModel.isScenarioAnalysisComplete = false;
            if (this.runViewModel.scenariosModel.scenarios) {
                this.runViewModel.scenariosModel.scenarios.forEach(d => {
                    d.scenarioResult = null;
                    d.isRTriggered = false;
                });
            }
            this._run.persist(this.runId, this.runViewModel, this.currencyInfo);
        }));

    }

    public removeCoverage(riskTypeId) {
        this._uploadDataService.removeCoverageDataForRun(this.runId, FileUploadType.dcfFile, riskTypeId)
            .subscribe(() => {
                this._uploadDataService.getCoverageList(this.runId)
                    .subscribe(d => {
                        this.runViewModel.dataUpload.collateralCoverage = d;
                        if (!d || !d.length) {
                            this.runViewModel.dataUpload.uploaded = false;
                        }
                        this.dataUpload = this.runViewModel.dataUpload;
                        this.persistRun();
                    });
            });
    }

    public runModel() {
        this._ultimateAnalysisProxy.prePopulateCarrierAssessmentData(this.runViewModel).subscribe(c => {
            this.runViewModel.carrierAssessmentData = c.data.carrierAssessmentData;
            this.header.navigation.nextClicked.next();
        });
    }

    uploadNewFile() {
        if (this.runViewModel.carrierAssessmentData && this.runViewModel.carrierAssessmentData.length > 0) {
            const ref = this._bsModalService.show(NewFileUploadModalComponent, { class: 'col-sm-6 modal--without-header' });
            ref.content.data = {};
            ref.content.data.yesButton = { display: true, text: this._translate.instant('GLOBAL.OK') };
            ref.content.data.cancelButton = { display: true, text: this._translate.instant('GLOBAL.BTN.CANCEL') };
            ref.content.onClose.subscribe(c => {
                if (c) {
                    this.clearDataUpload();
                }
                ref.hide();
            });
        } else {
            this.clearDataUpload();
        }
    }

    private clearDataUpload() {
        this.dataUpload.uploaded = false;
        this.dataUpload.collateralCoverage = [];
    }

    private loadRun(r: RunModel) {
        this.runViewModel = r.data;
        this.dataUpload = JSON.parse(JSON.stringify(this.runViewModel.dataUpload));
        this.runId = r.runId;
        this.currencyInfo = r.currencyInfo;
        this.clientId = this.runViewModel.clientId;
    }
}
