
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/shared/aggregateUpload/aggregateUpload.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/shared/aggregateUpload/aggregateUpload.component.scss';
import { AssessmentLOBInputDetails } from 'app/models';
import { IAggregateUpload } from '@acl-cq-lib-pages-negotiation/shared/aggregateUpload/IAggregateUpload';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { UploadErrorType } from '@wtw/platform/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-negotiation-aggregate-upload',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class AggregateUploadComponent implements OnInit, IComponentTranslation, IAggregateUpload {

    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.AGGREGATEUPLOAD';
    runId: number;
    runViewModel: any;
    errorMessage: string;
    fileName: any;
    year: number;

    @Input()
    dataUpload: any = {};

    @Output()
    removeFile = new EventEmitter<boolean>();

    @Output()
    persist = new EventEmitter<any>();

    @Output()
    downloadData = new EventEmitter<any>();

    @Output()
    close = new EventEmitter<boolean>();

    public acceptedSovFileTypes: Array<string> = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
    ];
    private avilableSheets = ['_ultAnalysis_aggregate', '_ultAnalysis_annual'];
    private headerValues = ['lob', 'policyEffectiveDate', 'retention', 'exposure', 'limitedIncurredLoss', 'limitedPaidLoss', 'limitedCaseReserve', 'openClaims', 'nonZeroCount', 'ibnr', 'unpaidLiability', 'ultimate'];
    private data = {};

    constructor(private _translationService: TranslateService) { }

    ngOnInit() {
        this.headerValues = this.headerValues.sort((a, b) => a > b ? 1 : -1);
    }

    uploadCarrierData(file) {
        this.data = {};
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            wb.SheetNames.forEach(c => {
                if (this.avilableSheets.indexOf(c) !== -1) {
                    const ws: XLSX.WorkSheet = wb.Sheets[c];
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    let headerList = data.splice(0, 1)[0] as any;
                    const headerListSorted = JSON.parse(JSON.stringify(headerList)).sort((a, b) => a > b ? 1 : -1);
                    if (JSON.stringify(headerListSorted) === JSON.stringify(this.headerValues) && data.find(d => (d as []).some(e => e)) !== undefined) {
                        const obj = [];
                        data.forEach(d => {
                            if ((d as []).some(f => f)) {
                                const i = obj.length;
                                obj[i] = {};
                                headerList.forEach((h, j) => {
                                    obj[i][h] = (d[j] || '').split('.')[0].split(',').join('');
                                });
                            }
                        });
                        this.data[c] = obj;
                    }
                }
            });
            if (Object.keys(this.data).length > 0) {
                this.persist.emit(this.data);
            } else {
                this.errorMessage = `${this._translationService.instant('COLLATERAL.COVERAGEDATA.FILEUPLOAD.FILEERROR')}`;
            }
        };
        this.fileName = file.name;
        reader.readAsBinaryString(file);
    }

    closeError() {
        this.errorMessage = '';
    }

    fileUploadError(event: { error: UploadErrorType, fileName: string }) {
        this.errorMessage = `${this._translationService.instant('COLLATERAL.COVERAGEDATA.FILEUPLOAD.FILETYPEERROR')}`;
        this.fileName = event.fileName;
    }

    downloadDataLink() {
        this.downloadData.emit();
    }

    closeUpload(): void {
        this.close.emit();
    }
}
