import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ContentChild, ViewChild, ElementRef, HostListener } from
    '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, filter, tap, map, concatAll, catchError } from 'rxjs/operators';

@Component({
    selector: 'cq-autocomplete', templateUrl: 'cqAutocomplete.component.html'
})
export class CqAutocompleteComponent implements OnInit {
    @Input() minimumInputLength: number = 3;
    @Input() results: any[];
    @Input() placeholder = '';
    term: string;
    throttle = new Subject<string>();
    elementFocused: boolean = false;
    @Input() dataSource: (term: string) => Observable<{ data: any[] }>;
    @Input() map: (data: any[]) => any[];
    @Output() resultsChange = new EventEmitter<any[]>();
    @Input() autoClear = true;
    @Input() preventClear = false;
    @Input() notFoundMessage: string;
    @Output('enter') enterPressed = new EventEmitter<any>();
    @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;
    @ViewChild('input') inputRef: ElementRef;
    // @ViewChild('insideElement') insideElement;

    busy = false;
    private _selectionIndex = -1;

    // @HostListener('document:click', ['$event.target'])
    // onClick(targetElement) {
    //     const clickedInside = this.insideElement.nativeElement.contains(targetElement);
    //     if (!clickedInside && this.elementFocused) {
    //         this.clear();
    //         console.log('clicked outside');
    //     }
    // }

    ngOnInit(): void {
        this.throttle
            .pipe(
                debounceTime(700),
                filter((v: string) => !!v && v.trim().length >= this.minimumInputLength),
                tap(() => this.busy = true),
                map(v =>
                    this.dataSource(v)
                        .pipe(
                            catchError(() => {
                                return of({ data: [] });
                            })
                        )
                ),
                concatAll()
            )
            .subscribe(result => {
                this.busy = false;
                this.results = this.map ? this.map(result.data) : result.data;
                this.resultsChange.emit(this.results);
                this._selectionIndex = -1;
            });
    }

    handleSelection(move: number) {
        if (this.results) {
            const next = move !== 0 ? this._selectionIndex + move : -1;
            this.changeSelection(next);
        }
    }

    activate(item?: any) {
        if (item || this.selected) {
            this.enterPressed.emit(item || this.selected);
            if (this.autoClear)
                this.clear();
        }
    }
    set selected(item: any) {
        if (this.results) {
            if (item !== this.selected) {
                const next = this.results.indexOf(item);
                this.changeSelection(next);

            }
        } else this._selectionIndex = -1;
    }
    get selected() {
        return this.results && this._selectionIndex > -1 && this.results[this._selectionIndex];
    }

    clear() {
        if (!this.preventClear && this.elementFocused) {
            this.handleSelection(-1);
            this.results = [];
            this.resultsChange.emit([]);
            this.term = null;
            this.elementFocused = false;
        }
    }

    test() {
    }

    setElementFocused(event) {
        event.target.click();
        this.elementFocused = true;
    }

    focus() {
        if (this.inputRef)
            this.inputRef.nativeElement.focus();
    }

    private changeSelection(next: number) {
        const old = this._selectionIndex;
        this._selectionIndex = next = Math.max(-1, Math.min((this.results || []).length - 1, next));
        if (old !== next) {
            if (old > -1 && this.results.length) {
                this.results[old].$selected = false;
            }
            if (next > -1) {
                this.results[next].$selected = true;
            }
        }
    }
}
