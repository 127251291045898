
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { IndustryGroup, Industry } from '../../api/dtos';
import { WeightingValidator } from '../validators/weighting.validator';
import { DuplicatedIndustryValidator } from '../validators/duplicated-industry.validator';

import * as componentTemplate from '@acl-cq-lib-shared/industry-selection/industry-selection.component.html';
import { IIndustrySelection } from '@acl-cq-lib-shared/industry-selection/IIndustrySelection';
@Component({
    selector: 'col-industry-selection',
    templateUrl: `${componentTemplate}`
})

export class IndustrySelectionComponent implements OnInit, IIndustrySelection {
    @Input() parent: FormGroup;
    @Input() industryList: IndustryGroup[];
    @Input() selectedIndustries: Industry[];
    @Output() addIndustry = new EventEmitter<any>();
    @Output() industryRemoved = new EventEmitter<any>();

    total: number;
    duplicatedIndustries: Industry[] = [];

    translationKey = 'COLLATERAL.DATAINPUT.CLIENT_INFORMATION_PAGE';
    translation = {};

    get industryControls() {
        return (this.parent.get('selectedIndustries') as FormArray).controls;
    }

    ngOnInit() {
        this.calculateTotal(this.parent.get('selectedIndustries').value);
        this.parent.get('selectedIndustries').valueChanges.subscribe(
            (value: Industry[]) => {
                this.calculateTotal(value);
            }
        );
    }

    removeSelectedItem(industry, index) {
        this.industryRemoved.emit({ industry, index });
        this.selectedIndustries = this.selectedIndustries.filter((c, i) => i !== index);
    }

    addItem() {
        this.addIndustry.emit({});
        this.selectedIndustries.push(<Industry>{});

    }

    calculateTotal(value: Industry[]) {
        Promise.resolve(null).then(c => {
            this.total = WeightingValidator.calculateTotal(value);
            this.duplicatedIndustries = DuplicatedIndustryValidator.getDuplicated(value);
        });
    }

    isDuplicated(id: number) {
        return !!this.duplicatedIndustries.find(i => i.id === id);
    }
}

