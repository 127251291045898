import { ScenarioService } from '../../../../../services/scenario.service';
import { Component } from '@angular/core';
import { CollateralCostRate, CommonAssumptions, CarrierDetails, SecurityType } from 'app/api/dtos';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/collateralCostRateSummary/collateralCostRateSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { ICollateralCostRateSummary } from '@acl-cq-lib-pages-scenario-summary/collateralCostRateSummary/ICollateralCostRateSummary';

@Component({
    selector: 'app-collateral-cost-rate-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CollateralCostRateSummaryComponent implements ICollateralCostRateSummary {
    public translationKey: string = 'COLLATERAL.SCENARIOS.SUMMARY.COLLATERAL_COST_RATE';
    public translation = {};

    public get currentScenario() {
        return this.scenarioService.selectedScenario;
    }

    public get securityTypes(): any[] {
        let sec = this.carriers.map(c => c.collateralCostRate).reduce(ccr => ccr).securities.map(s => s.securityType);
        return this.carriers.map(c => c.collateralCostRate).reduce(ccr => ccr).securities.map(s => s.securityType);
    }

    public get carriers(): CarrierDetails[] {
        return this.scenarioService.selectedScenario.carriers;
    }

    public get SecurityType(): any {
        return this.scenarioService.securityTypeDictionary;
    }

    constructor(private scenarioService: ScenarioService) { }
}

