import './shared/arrayExtender';
import './shared/objectExtender';
import { ScenarioProxy } from './api/generated/ScenarioController';
import { ScenarioService } from './services/scenario.service';
import { ExcelUploadService } from './services/excel-upload.service';
import { NgModule } from '@angular/core';
import { PlatformRootComponent } from '@wtw/platform';
import { HttpClientModule } from '@angular/common/http';
import { CoverageDataService } from './services/coverage-data.service';
import { MainComponents } from './pages';
import { UploadService } from './services/upload-data.service';
import { UtilityService } from './shared/utility.service';
import { CommonModule } from './common.module';
import { ChartModule } from 'angular2-highcharts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DecimalPipe, KeyValuePipe } from '@angular/common';
import { ShortNumberPipe } from './shared/pipes/short-number.pipe';
import { RunHelperService } from './services/runHelper.service';
import { NegotiationService } from './services/negotiation.service';
import { ExecutionLogService } from './services/ExecutionLogService';


export declare let require: any;

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ChartModule
  ],
  bootstrap: [PlatformRootComponent],
  entryComponents: [...MainComponents],
  providers: [DecimalPipe, UploadService, UtilityService, CoverageDataService,
    ExcelUploadService, ScenarioService, ScenarioProxy, KeyValuePipe, ShortNumberPipe, NegotiationService, ExecutionLogService
  ]
})
export class AppModule { }
