import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/shared/downloadNegotiationReport/downloadNegotiationReport.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/shared/downloadNegotiationReport/downloadNegotiationReport.component.scss';

@Component(
    {
        selector: 'app-negotiation-download-report',
        templateUrl: `${componentTemplate}`,
        styleUrls: ['' + componentStyle]
    }
)
export class DownloadNegotiationReportComponent {
    @Input() availableDownloadList: any;
    @Output() downloadList: EventEmitter<any> = new EventEmitter<any>();

    translationKey = {};
    translation = {
        TITLE: 'Choose a report',
        SUB_TITLE: '  ',
        ACTION: 'Download your report',
        DOWNLOAD_REPORT: 'Download Report'
    };
    customClass = '';
    collapsed = false;
    disabledButton = false;
    disabled = false;
    downloadReport() {
        this.collapsed = !this.collapsed;
    }

    downloadPdfClick() {
        this.downloadList.emit(this.availableDownloadList);
        this.collapsed = false;
    }

    updateCheckItem(item) {
        item.checked = !item.checked;
        this.disabled = this.availableDownloadList.every(c => !c.checked);
    }

    clickedOutsideReport() {
        this.collapsed = false;
    }
}
