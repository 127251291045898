import { Component, OnInit }  from '@angular/core';
import { BsModalRef }         from 'ngx-bootstrap';
import { Base }               from '@wtw/toolkit';
import { IModalConfirmation } from '@wtw/platform/interfaces';
import * as Rx                from 'rxjs';

@Component({
  selector: 'template-selection',
  templateUrl: './template-selection.component.html',
  styleUrls: ['./template-selection.component.scss']
})

export class TemplateSelectionComponent extends Base.ReactiveComponent implements OnInit, IModalConfirmation {
    public active = false;
    public onClose: Rx.Subject<boolean>;
    public data: {

    };
    constructor(private _bsModalRef: BsModalRef) {
        super();
    }

  public ngOnInit() {
    this.onClose = new Rx.Subject();
  }

  public show() {
    this.active = true;
  }

  public close() {
    this.hide(null);
  }

  public ok() {
      this.hide(true);
  }

  public cancel() {
    this.hide(false);
  }

  public hide(nextVal?: boolean) {
    this.active = false;
    this.onClose.next(nextVal);
    this._bsModalRef.hide();
  }

}
