import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maxLength'
})
export class MaxLength implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value === null || value === undefined) {
            return null;
        }

        return value.length > 19 ? value.slice(0, 16) + '...' : value;
    }
}