
export class Formatting {

    static formatReduce(value: number, maximumFractionDigits: number): string {
        let sign = '';
        if (value < 0) {
            sign = '-';
            value = Math.abs(value);
        }
        if (value === 0) {
            return '0';
        }

        if (value >= 1000 && value <= 999999) {
            return `${sign}${this.formatReduceToLocale(value, 1000, 'K', maximumFractionDigits)}`;
        }

        if (value >= 1000000 && value <= 999999999) {
            return `${sign}${this.formatReduceToLocale(value, 1000000, 'M', maximumFractionDigits)}`;
        }

        if (value >= 1000000000 && value <= 999999999999) {
            return `${sign}${this.formatReduceToLocale(value, 1000000000, 'B', maximumFractionDigits)}`;
        }

        if (value >= 1000000000000) {
            return `${sign}${this.formatReduceToLocale(value, 1000000000000, 'T', maximumFractionDigits)}`;
        }

        return `${sign}${value.toLocaleString('en-US', { maximumFractionDigits: maximumFractionDigits })}`;
    }

    static format(value: number, maximumFractionDigits: number) {
        value = value ? value : 0;
        return value.toLocaleString('en-US', { maximumFractionDigits: maximumFractionDigits });
    }

    private static formatReduceToLocale(value: number, divideBy: number, format: string, maximumFractionDigits: number) {
        return (value / divideBy).toLocaleString('en-US', { maximumFractionDigits: maximumFractionDigits }) + format;
    }
}

