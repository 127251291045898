import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ModalService } from '@wtw/platform/services';
import { FormGroup, FormArray } from '@angular/forms';
import { ScenarioService } from 'app/services/scenario.service';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';
import { ReferenceDataService } from 'app/services/referenceData.service';
import * as Dto from '../../../../api/dtos';
import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/coverageAssumptions/coverageAssumptions.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';
import { ICoverageAssumptions } from '@acl-cq-lib-pages-scenario-edit/coverageAssumptions/ICoverageAssumptions';
import { CarrierType, CoverageName } from '../../../../api/dtos';

@Component({
    selector: 'app-coverage-assumptions',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]

})

export class CoverageAssumptionsComponent extends ReactiveComponent implements ICoverageAssumptions {

    @Input() form: FormGroup;

    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT.COVERAGE_ASSUMPTIONS';
    public translation: any = {};

    public coverageData: string[] = [
        'premium',
        'selectedUltLoss',
        'costRateTrend'
    ];

    private _coverageDisplayDataPreviousValue: { riskTypeId: number, riskTypeName: string, isCarrierChange: boolean }[];

    constructor(private _scenarioService: ScenarioService, private _modalService: ModalService,
        private _translateService: TranslateService, private _refDataService: ReferenceDataService) {
        super();
    }

    ngOnInit() {
        this._coverageDisplayDataPreviousValue = this.coverageDisplayData.value;

        this.setCoverageFormsToDisable(this._coverageDisplayDataPreviousValue);

        this._subscriptions.push(
            this.coverageDisplayData.valueChanges.subscribe(d => {


                this.setCoverageFormsToDisable(d);

                if (d.findIndex(o => o.isDisabled) === -1) {
                    this.coverageDisplayData.setValue(this._coverageDisplayDataPreviousValue, { emitEvent: false });
                    this.showAtLeastOneCheckBoxMustBeCheckedModal();
                } else {
                    this._coverageDisplayDataPreviousValue = d;
                }
            }));

        if (this._scenarioService.selectedScenario.isSaved || this._scenarioService.selectedScenario.isRTriggered) {
            this._scenarioService.markFormArrayTouched(this.coverageAssumptions);
        }
    }

    public get isIncumbentComparison(): boolean {
        return +this.form.get('comparisonType').value === CarrierType.incumbent;
    }

    public get carriers(): FormArray {
        return this.form.get('carriers') as FormArray;
    }

    public get coverageAssumptions(): FormArray {
        return this.form.get('coverageAssumptions') as FormArray;
    }

    public get coverageDisplayData(): FormArray {
        return this.form.get('coverageDisplayData') as FormArray;
    }

    public get displayPercentage() {
        return this.displayPercentageHeader / 2;
    }

    public get displayPercentageHeader() {
        const coverageLength = this.riskTypeList.filter(c => this._scenarioService.selectedScenario.coverageDisplayData[c.id]).length;
        if (coverageLength === 1) {
            return 65 / coverageLength;
        } else {
            return 80 / coverageLength;
        }
    }

    public coveragesAtCarrierIndex(index: number): FormArray {
        return this.carriers.controls[index].get('carrierCoverageData') as FormArray;
    }

    public isCoverageDisabled(riskTypeId: number): boolean {
        return !this.coverageDisplayData.controls.filter(c => +c.value.riskTypeId === +riskTypeId)[0].value.isDisabled;
    }

    public get scenarioCoverageDisplayData(): { [key: number]: CoverageName; } {
        return this._scenarioService.selectedScenario.coverageDisplayData;
    }

    private get riskTypeList(): Dto.RiskType[] {
        return this._refDataService.referenceData.riskTypes.sort((a, b) => { return a.displayOrderId - b.displayOrderId; });
    }

    private showAtLeastOneCheckBoxMustBeCheckedModal(): void {
        const okConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('GLOBAL.OK'),
            cssClass: 'btn btn-primary'
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this._translateService.instant('COLLATERAL.SCENARIOS.SUMMARY.COVERAGEASSUMPTIONS.CHECKBOX_MODAL_TITLE'),
            withHeader: true,
            message: this._translateService.instant('COLLATERAL.SCENARIOS.SUMMARY.COVERAGEASSUMPTIONS.CHECKBOX_MODAL_MSG'),
            yesButton: okConfig,
            noButton: { display: false },
            cancelButton: { display: false }
        };

        this._modalService.confirm(confirmConfig);
    }

    private setCoverageFormsToDisable(cddList): void {
        this.setFormArrayDisable(this.coverageAssumptions, cddList);

        this.carriers.controls.forEach((carrierControl, index) => {
            const formArray = this.coveragesAtCarrierIndex(index);
            this.setFormArrayDisable(formArray, cddList);
        });
    }

    private setFormArrayDisable(formArray: FormArray, cddList): void {
        formArray.controls.forEach(c => {
            const cdd = cddList.find(cdd => +cdd.riskTypeId === +c.value.riskTypeId);
            if (!cdd.isDisabled) {
                c.disable();
            } else {
                c.enable();
            }
            c.updateValueAndValidity();
        });
    }
}