
import { Injectable, Injector } from '@angular/core';
import { RunService } from '@wtw/platform/services';
import { ValidationProxy } from 'app/api/generated/validationController';

@Injectable()
export class RunHelperService {
    _runService: RunService;
    _validationProxy: ValidationProxy;

    constructor(inj: Injector) {
        setTimeout(() => {
            this._runService = inj.get(RunService);
            this._validationProxy = inj.get(ValidationProxy);
            this.init();
        });
    }
    init() {
        if (this._runService) {
            this._runService.activeRun.subscribe(c => {
                if (c.runId !== c.data.runId && c.data.runId) {
                    this._validationProxy.updateClonedRun(c.data, c.runId).uiSignal('Updating Model').subscribe(d => {
                        c.data = d.data;
                        this._runService.persist(c.runId, c.data, c.currencyInfo).subscribe(d => {
                            (this._runService as any)._activeRun.next(c);
                        });
                    });
                } else if (!c.data.runId) {
                    c.data.runId = c.runId;
                    this._runService.persist(c.runId, c.data, c.currencyInfo, c.info.stage);
                }
            });
        }
    }
}
