import { ScenarioStateEnum } from '../../../../models/index';
import { ScenarioService } from '../../../../services/scenario.service';
import { ScenarioProxy } from '../../../../api/generated/ScenarioController';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RunService } from '@wtw/platform/services';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-shared/scenarioTabs/scenarioTabs.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-shared/scenarioTabs/scenarioTabs.component.scss';
import { IScenarioTabs } from '@acl-cq-lib-pages-scenario-shared/scenarioTabs/IscenarioTabs';
import { MenuItem } from 'app/models/menuItem';

@Component({
    selector: 'app-scenario-tabs',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioTabsComponent extends ReactiveComponent implements OnInit, IScenarioTabs {
    isWarning: boolean; //TODO: Implemented by the interface, but not used. Maybe remove?
    isComplete: boolean; //TODO: Implemented by the interface, but not used. Maybe remove?
    @Input() readonly = false;

    @ViewChild('tabNameEdit') tabNameEdit: ElementRef;

    originalScenarioName: string;

    public get tabs() {
        return this.scenarioService.scenarios;
    }
    public isValidName: boolean;
    public maxNameLength: number = 18;
    public translationKey: string = 'COLLATERAL.SCENARIOS.TABS';
    public translation: any = {};
    public tabItems: MenuItem[];

    get scenariosModel() {
        return this.scenarioService.scenariosModel;
    }

    constructor(private scenarioService: ScenarioService, private runService: RunService, private _scenarioProxy: ScenarioProxy) {
        super();
    }

    ngOnInit() {
        this.setMenuItems();
        this._subscriptions.push(this.scenarioService.scenarioChanged.subscribe(c => this.setMenuItems()));
    }

    public selectTab(index: number) {
        this.scenarioService.clickScenarioTab(index);
        this.setMenuItems();
    }

    public canChangeName(tab: any): boolean {
        return (tab.scenarioState === ScenarioStateEnum.selected || tab.scenarioState === ScenarioStateEnum.result) && !tab.nameChangeOn;
    }

    public editName(event: any, tab: any): void {
        event.stopPropagation();
        this.originalScenarioName = tab.name;
        tab.nameChangeOn = true;
        this.validateName(tab.name);

        setTimeout(() => {
            this.tabNameEdit.nativeElement.focus();
        }, 0);
    }
    public validateName(name: string): void {
        if (this.tabs.filter(c => (c as any).name === name).length > 1) {
            this.isValidName = false;
            // this.scenariosModel.isValidScenarioName = false;
            return;
        }
        this.isValidName = true;
        // this.scenariosModel.isValidScenarioName = true;
    }

    public closeEdit(index: number) {
        if (!this.isValidName) {
            return;
        }
        let tab = this.tabs[index] as any;
        tab.nameChangeOn = false;
        if (tab.name !== this.originalScenarioName && tab.name) {
            tab.scenarioName = tab.name;
            this.scenarioService.save();
        } else if (!tab.name) {
            tab.name = this.originalScenarioName;
        }
    }

    public isAddDisabled(): boolean {
        if (this.tabs && this.tabs.length < 5) {
            return false;
        } else {
            return true;
        }
    }

    public get displayAdd(): boolean {
        return this.tabs && this.tabs.length < 5 && !this.readonly;
    }

    public add() {
        this.scenarioService.addScenario();
    }

    public menuItemClicked(item) {
        if (item.key === 'rename') {
            this.editName(new Event('click'), this.scenarioService.selectedScenario);
        } else {
            item.methodCallback(this.scenarioService);
        }
    }

    private selectScenario(scenarioService) {
        let currentScenarioSelect = scenarioService.selectedScenario.isSelected;
        scenarioService.scenarios.forEach(c => {
            c.isSelected = false;
        });
        scenarioService.selectedScenario.isSelected = !currentScenarioSelect;
        scenarioService.save();
    }

    private copyScenario(scenarioService) {
        if (scenarioService.scenarios.length < 5) {
            scenarioService.copyScenario();
        }
    }

    private removeScenario(scenarioService) {
        if (scenarioService.scenarios.length > 1) {
            scenarioService.removeCurrentScenarioPopUp('COLLATERAL.SCENARIOS');
        }
    }

    private setMenuItems() {
        let scenarioSelected = this.scenarioService.selectedScenario.isSelected;
        this.tabItems = [
            {
                key: 'select',
                translationKey: scenarioSelected ? this.translationKey + '.UNSELECT' : this.translationKey + '.SELECT',
                icon: scenarioSelected ? 'icon-flag-filled' : 'icon-empty-flag',
                methodCallback: this.selectScenario,
                disabled: false
            },
            {
                key: 'copy',
                translationKey: this.translationKey + '.COPY',
                icon: 'icon-copy',
                methodCallback: this.copyScenario,
                disabled: this.scenarioService.scenarios.length >= 5
            },
            {
                key: 'rename',
                translationKey: this.translationKey + '.RENAME',
                icon: 'icon-ico_edit',
                methodCallback: this.editName,
                disabled: false
            },
            {
                key: 'remove',
                translationKey: this.translationKey + '.REMOVE_SHORT',
                icon: 'icon-delete',
                methodCallback: this.removeScenario,
                disabled: this.scenarioService.scenarios.length === 1
            }
        ];
    }
}
