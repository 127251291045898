
import { Component, OnInit, Input } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/expandedRow/expandedRow.commponent.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/expandedRow/expandedRow.commponent.scss';
import { IExpandedRow } from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/expandedRow/IExpandedRow';
import { NegotiationService } from 'app/services/negotiation.service';
import { RiskType } from 'app/models';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';

@Component({
    selector: 'app-negotiation-carrier-list-table-expanded-row',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ExpandedRowComponent implements OnInit, IComponentTranslation, IExpandedRow {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.EXPANDEDROWCOMPONENT';
    riskTypes: { [key: string]: RiskType; };
    @Input()
    row: any;

    constructor(private _negotiationService: NegotiationService) { }

    ngOnInit() {
        this.riskTypes = this._negotiationService.riskTypes;
    }

    lessDetails() {
        this.row.collapsed = true;
    }
}
