import { Injectable } from '@angular/core';
import { IStandardModalConfig, IButtonConfig } from '@wtw/platform/interfaces';
import { ChartType } from 'app/shared/utility.service';

@Injectable()
export class UtilService {
    public static ChartConfiguration_Height = 200;
    public static ChartPDFConfiguration_Height = 130;
    public static ChartConfiguration_ZoomType = 'x';
    public static ChartConfiguration_BackgroundColor = 'rgba(255, 255, 255, 0.0)';
    public static ChartConfiguration_FontFamily = 'Neue Haas Grotesk, Arial, sans-serif !important';
    public static ChartConfiguration_FontSize = '10px';
    public static ChartConfiguration_FontWeight = 500;
    public static ChartConfiguration_Color = '#A0A0A0';
    public static ChartConfiguration_CharType = ChartType.Line;

    getDeleteModalConfig(message: string, title: string): IStandardModalConfig {
        return <IStandardModalConfig>{
            message: message,
            title: title,
            yesButton: <IButtonConfig>{
                display: true,
                text: 'Delete',
                cssClass: 'btn btn-danger'
            },
            cancelButton: <IButtonConfig>{
                display: false
            },
            noButton: <IButtonConfig>{
                text: 'Cancel',
                display: true
            }
        };
    }

    getPositiveIntegerRegex() {
        return /^\d*$/;
    }

    getNonZeroPossitiveIntergerRegex() {
        return /^[1-9]+[0-9]*$/;
    }
}