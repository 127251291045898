import { AbstractControl } from '@angular/forms';
import { Industry } from '../../api/dtos';

export class WeightingValidator {
    static TotalPercent(c: AbstractControl): {[key: string]: boolean} | null {
            const total = WeightingValidator.calculateTotal(c.get('selectedIndustries').value);
            if (isNaN(total) || total !== 100) {
                return {'total': true};
            }
            return null;
    }

    static calculateTotal(industries: Industry[]) : number {
        const total = industries.reduce((prev, next) => {
            if (!next.weighting) {
                return prev;
            }
            const nextVal = parseInt(next.weighting.toString(), 10);
            if (isNaN(nextVal)) {
                return prev;
            }
            return prev + nextVal;
        }, 0);

        return total;
    }
}
