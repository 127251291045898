import { ScenarioService } from '../../../../../services/scenario.service';
import { CollateralRampUp, CarrierType } from '../../../../../models/index';
import { Component } from '@angular/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/collateralRampUpSummary/collateralRampUpSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { ICollateralRampUpSummary } from '@acl-cq-lib-pages-scenario-summary/collateralRampUpSummary/ICollateralRampUpSummary';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';

@Component({
    selector: 'app-collateral-ramp-up-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CollateralRampUpSummaryComponent extends ReactiveComponent implements ICollateralRampUpSummary {
    public translationKey: string = 'COLLATERAL.SCENARIOS.SUMMARY.COLLATERAL_RAMP_UP';
    public translation = {};
    private _collateralRampUps: CollateralRampUp[][];
    private _maxRowCount: number[];
    private _carrierCount: number[];

    public get collateralRampUps(): CollateralRampUp[][] {
        return this._collateralRampUps;
    }
    public get isIncumbentComparison(): boolean {
        return this.scenarioService.isIncumbentComparison();
    }

    public get maxRowCount(): number[] {
        return this._maxRowCount;
    }

    public get carrierCount(): number[] {
        return this._carrierCount;
    }

    constructor(private scenarioService: ScenarioService) {
        super();
        this._subscriptions.push(
            scenarioService.scenarioChanged.subscribe(c => {
                this.init();
            })
        );
    }

    ngOnInit() {
        this.init();
    }

    private init() {

        const rampUps = this.scenarioService.selectedScenario.carriers.map(c => c.collateralRampUps);

        let lengths = rampUps.map(c => c.length);
        this._maxRowCount = [].empty(Math.max(...lengths));

        if (this.isIncumbentComparison) {
            this._carrierCount = [].empty(1);
        } else {
            this._carrierCount = [].empty(rampUps.length);
        }

        this._collateralRampUps = this._maxRowCount.map((c, i) => {
            let aux: any = {};
            aux.type = i === 0 ? 'INITIALREQUIRED' : 'ADJUSTMENT';
            aux.values = this._carrierCount.map(d => rampUps[d][c] || { hide: true });
            return aux;
        });
    }
}
