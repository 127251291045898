import { ReferenceDataService } from '../../../../services/referenceData.service';
import { ScenarioService } from '../../../../services/scenario.service';
import { CommonAssumptions } from '../../../../models/index';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { LossCreditType, PaidLossCreditType, CarrierDetails, CarrierType } from 'app/api/dtos';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/commonAssumptions/commonAssumptions.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';
import { ICommonAssumptionsEdit } from '@acl-cq-lib-pages-scenario-edit/commonAssumptions/ICommonAssumptionsEdit';

@Component({
    selector: 'app-scenario-common-assumptions',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CommonAssumptionsComponent extends ReactiveComponent implements ICommonAssumptionsEdit {
    @Input()
    form: FormGroup;

    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT.COMMON_ASSUMPTIONS';
    public translation: any = {};

    public get incumbentLegacyPLCTypeList(): LossCreditType[] {
        return this.referenceDataService.referenceData.lossCreditTypes;
    }

    public get CreditType(): typeof PaidLossCreditType {
        return PaidLossCreditType;
    }

    public get isIncumbentComparison(): boolean {
        return +this.form.get('comparisonType').value === CarrierType.incumbent;
    }

    private _incumbentLegacyPLCMap = new Map<PaidLossCreditType, number>();

    constructor(private scenarioService: ScenarioService, private referenceDataService: ReferenceDataService) {
        super();
        this.setupIncumbentLegacyPLCMap();
    }

    ngOnInit(): void {
        this.setValidatorsForCommonAssumptionsForm();


        this._subscriptions.push(
            this.commonAssumptions.get('incumbentLegacyPLCType').valueChanges.subscribe((c) => {
                const key = parseInt(c);
                const mappedValue = this._incumbentLegacyPLCMap.get(key);

                this.commonAssumptions.get('incumbentLegacyPLC').setValue(mappedValue, { emitEvent: false });

                this.setIncumbentLegacyPLCValidation(key);

                this.commonAssumptions.get('incumbentLegacyPLC').updateValueAndValidity();
            }),

            this.form.get('projectionPeriod').valueChanges.subscribe(c => {
                if (+this.commonAssumptions.get('incumbentLegacyPLCType').value === PaidLossCreditType.months) {
                    this.commonAssumptions.get('incumbentLegacyPLC').setValidators([Validators.required, Validators.min(0), Validators.max(this.projectionPeriod * 12)]);
                    this.commonAssumptions.get('incumbentLegacyPLC').updateValueAndValidity();
                }
            }),

            this.commonAssumptions.get('incumbentLegacyPLC').valueChanges.subscribe((c: number) => {
                const key = parseInt(this.commonAssumptions.get('incumbentLegacyPLCType').value);
                this._incumbentLegacyPLCMap.set(key, c);
            }),

            this.form.get('comparisonType').valueChanges.subscribe((c: number) => {
                this.carriers.controls[0].patchValue({ typeOfCarrier: +c });
            })
        );

        if (this.scenarioService.selectedScenario.isSaved || this.scenarioService.selectedScenario.isRTriggered) {
            this.scenarioService.markFormGroupTouched(this.commonAssumptions);
        }
    }

    public get commonAssumptions() {
        return this.form.get('commonAssumptions') as FormGroup;
    }

    public get projectionPeriod() {
        return this.form.get('projectionPeriod').value;
    }

    public get carriers(): FormArray {
        return this.form.get('carriers') as FormArray;
    }

    private setValidatorsForCommonAssumptionsForm(): void {
        this.commonAssumptions.get('discountRate').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        this.commonAssumptions.get('incumbentLegacyPLCType').setValidators([Validators.required]);
        this.commonAssumptions.get('walkawayCollateral').setValidators([Validators.required, Validators.min(0)]);
        const key = +this.commonAssumptions.get('incumbentLegacyPLCType').value;

        this.setIncumbentLegacyPLCValidation(key);
    }

    private setIncumbentLegacyPLCValidation(key: PaidLossCreditType): void {
        if (key === PaidLossCreditType.months) {
            this.commonAssumptions.get('incumbentLegacyPLC').setValidators([Validators.required, Validators.min(0), Validators.max(this.projectionPeriod * 12)]);
        } else if (key === PaidLossCreditType.percent) {
            this.commonAssumptions.get('incumbentLegacyPLC').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        } else {
            this.commonAssumptions.get('incumbentLegacyPLC').setValidators([Validators.required, Validators.min(0)]);
        }
    }

    private setupIncumbentLegacyPLCMap(): void {
        const model: CommonAssumptions = this.scenarioService.selectedScenario.commonAssumptions;
        this.incumbentLegacyPLCTypeList.forEach(c => {
            this._incumbentLegacyPLCMap.set(c.pdLossCredType, c.pdLossCredValue);
        });

        this._incumbentLegacyPLCMap.set(parseInt(model.incumbentLegacyPLCType.toString()), model.incumbentLegacyPLC);
    }

}

