import { Injectable } from '@angular/core';
import { ExecutionLog, TriggerType, CarrierAssessmentData } from 'app/models';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class ExecutionLogService {

    constructor(private _translate: TranslateService) { }


    public generateExecutionLogs(carrierAssessmentData: CarrierAssessmentData[]) {
        return carrierAssessmentData.map(c =>
            this.generateExecutionLogForCarrier(0, c)
        ).flatten();
    }

    public generateExecutionLogForCarrier(interation: number, carrierAssessmentData: CarrierAssessmentData): ExecutionLog[] {
        return carrierAssessmentData.carrierAssessmentOutputs[interation].negotiationRules.map(d =>
            d.lobRuleData.map(e => {
                return <ExecutionLog>{
                    negotiationCarrierId: carrierAssessmentData.negotiationCarrierId,
                    interationNumber: interation + 1,
                    ruleId: d.ruleId,
                    riskTypeId: e.riskType,
                    ruleMessage: this._translate.instant(`COLLATERAL.NEGOTIATION.RULES.RULESTABLE.${d.ruleId}.${e.localisationKey}`, e.localisationOverride),
                    triggerName: TriggerType.processUploadData
                };
            })
        ).flatten();
    }
}
