
import * as Dto from '../dtos';
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
export { ApiResult };

@Injectable()
export class AlgorithmInputProxy {
    constructor(private _http: ProxyHttp) {
    }


    processUploadDataInputCompleted(runId: number, completed: boolean): Observable<ApiResult<Dto.ProcessDataCompleted>> {
        return this._http.post(`/backend/algorithm/input/processUploadDataInputCompleted/${runId}/${completed}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }

    static processScenario_url(): { method: string, url: string } {
        return { method: 'post', url: `/platform/algorithm/input/processScenario` };
    }
    static processUploadData_url(): { method: string, url: string } {
        return { method: 'post', url: `/platform/algorithm/input/processUploadData` };
    }
    static processCarrierSummaryTotals_url(): { method: string, url: string } {
        return { method: 'post', url: `/platform/algorithm/input/processCarrierSummaryTotals` };
    }
    static processCarrierAssessment_url(): { method: string, url: string } {
        return { method: 'post', url: `/platform/algorithm/input/processCarrierAssessment` };
    }

}


