import { Component, OnInit } from '@angular/core';
import { Formatting } from '../../../../shared/formatting';
import { Scenario, SummaryResult, ScenarioResult } from '../../../../api/dtos';
import { ScenarioService } from 'app/services/scenario.service';
import { TranslateService } from '@ngx-translate/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results-summary/summaryBenefitsChart/summaryBenefitsChart.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-results-summary/scenarioResultsSummaryContainer.component.scss';
import { ISummaryBenefitsChart } from '@acl-cq-lib-pages-scenario-results-summary/summaryBenefitsChart/ISummaryBenefitsChart';
import { IScenarioChart } from '@acl-cq-lib-pages-scenario-results-summary/IScenarioChart';
import { UtilService } from 'app/services/util.service';
import { ChartType } from 'app/shared/utility.service';

@Component({
    selector: 'summary-benefits-chart',
    styleUrls: ['' + componentStyle],
    templateUrl: `${componentTemplate}`
})

export class SummaryBenefitsChartComponent implements OnInit, ISummaryBenefitsChart {
    public translation: any = {};
    public translationKey: string = 'COLLATERAL.SCENARIOS.RESULTS.SUMMARY_TAB';
    public legend: any;
    public charts: IScenarioChart[];
    dataSeries1: string;
    dataSeries2: string;
    scenarios: Scenario[];
    private _benefitsBeginColor = '#6A0858';
    private _breakevenPointColor = '#D764C1';

    constructor(
        private _scenarioService: ScenarioService,
        private _translateService: TranslateService
    ) { }

    ngOnInit() {
        //TODO - SR - 17/09/2019 - refactor charts into generic component as they all work in a similar way
        this.dataSeries1 = this._translateService.instant(this.translationKey + '.BENEFITS_CHART_DATA_SERIES1');
        this.dataSeries2 = this._translateService.instant(this.translationKey + '.BENEFITS_CHART_DATA_SERIES2');
        this.scenarios = this._scenarioService.scenarios;
        this.setLegend();
        this.generateCharts();
    }

    generateCharts() {
        this.charts = [];

        for (let i = 0; i < this.scenarios.length; i++) {
            if ((this.scenarios[i].scenarioResult && this.scenarios[i].scenarioResult.summaryResult) && (this.scenarios[i].scenarioResult.summaryResult.benefitsBeginMonth > 0 || this.scenarios[i].scenarioResult.summaryResult.breakevenMonth > 0)) {
                this.addChart(this.scenarios[i], true, i);
            } else {
                this.addChart(this.scenarios[i], false, i);
            }
        }
    }

    legendItemClicked(item) {
        item.enabled = !item.enabled;
        this.generateCharts();
    }

    goToScenario(scenarioIndex: number): void {
        this._scenarioService.summaryResultsSelected = false;
        this._scenarioService.clickScenarioTab(scenarioIndex);
    }

    setLegend() {
        this.legend = [
            {
                name: this.dataSeries1,
                enabled: true,
                color: this._benefitsBeginColor
            },
            {
                name: this.dataSeries2,
                enabled: true,
                color: this._breakevenPointColor
            }
        ];
    }

    addChart(scenario: Scenario, containsData: boolean, scenarioIndex: number) {
        this.charts.push({
            scenarioIndex: scenarioIndex,
            isRun: scenario.isRTriggered,
            isValid: scenario.isValid,
            hasData: containsData,
            options: scenario.isRTriggered && scenario.isValid && containsData ? this.setOptions(scenario.scenarioResult.summaryResult) : null
        });
    }

    setOptions(summaryResult: SummaryResult) {

        return {
            title: { text: '' },
            chart: {
                height: UtilService.ChartConfiguration_Height,
                zoomType: UtilService.ChartConfiguration_ZoomType,
                type: ChartType.Column,
                backgroundColor: UtilService.ChartConfiguration_BackgroundColor,
                style: {
                    fontFamily: UtilService.ChartConfiguration_FontFamily,
                    fontSize: UtilService.ChartConfiguration_FontSize,
                    fontWeight: UtilService.ChartConfiguration_FontWeight,
                    color: '#A0A0A0'
                },
                marginBottom: 0,
                marginTop: 0,
            },
            xAxis: {
                categories: [
                    this.dataSeries1,
                    this.dataSeries2
                ],
                title: {
                },
                visible: false,
                lineWidth: .5,

            },

            plotOptions: {
                series: {
                    stacking: 'normal',
                    pointWidth: 50,
                    pointPadding: 0
                }
            },
            series: [
                {
                    data: [summaryResult.benefitsBeginMonth],
                    name: this.dataSeries1,
                    color: this._benefitsBeginColor,
                    borderWidth: 0,
                    stack: this.dataSeries1,
                    visible: this.setVisibleFlag(this.dataSeries1)
                },
                {
                    data: [summaryResult.breakevenMonth],
                    name: this.dataSeries2,
                    color: this._breakevenPointColor,
                    borderWidth: 0,
                    stack: this.dataSeries2,
                    visible: this.setVisibleFlag(this.dataSeries2)

                },
            ],
            yAxis: {
                title: {
                    text: this._translateService.instant(this.translationKey + '.BENEFITS_CHART_Y_AXIS'),
                    style: {
                        fontSize: '14px'
                    }
                },
                lineWidth: 1,
                gridLineWidth: 0,
                labels: {
                    enabled: false
                },
                visible: false,
                min: this.getMinYAxisValue(),
                max: this.getMaxYAxisValue()
            },

            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                followPointer: true,
                formatter: function s() {
                    let s = '<div style="color:white; padding:5px;">';
                    s += `<div style="font-size: 10px" class="row"> <div class="col-sm-12">${this.series.name}</div></div>`;
                    s += `<span style="width: 10px; height:10px; border-radius: 10px; background-color:${this.color}; display: inline-block"></span>
                        <span style="padding-left:5px;">${this.series.name}</span>:
                    <span style="padding-right: 10px">${Formatting.format(this.point.y, 0)}</span>`;
                    return `${s}</div>`;
                }
            },
        };
    }

    setVisibleFlag(name) {
        return this.legend.find(c => c.name === name).enabled;
    }

    private getMaxYAxisValue() {
        let maxValue = Math.max(...this.scenarios.map(function _(o) {
            if (o.scenarioResult && o.scenarioResult.summaryResult) {
                return Math.max(o.scenarioResult.summaryResult.benefitsBeginMonth, o.scenarioResult.summaryResult.breakevenMonth);
            }
            return 0;
        }));
        return maxValue;
    }

    private getMinYAxisValue() {
        let minValue = Math.min(...this.scenarios.map(function _(o) {
            if (o.scenarioResult && o.scenarioResult.summaryResult) {
                return Math.min(o.scenarioResult.summaryResult.benefitsBeginMonth, o.scenarioResult.summaryResult.breakevenMonth);
            }
            return 0;
        }));
        minValue = minValue > 0 ? 0 : minValue;
        return minValue;
    }
}