import { Component, OnInit, AfterViewInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { IrulesDropdown } from '@acl-cq-lib-pages-negotiation/rules/rulesDropdown/IrulesDropdown';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/rules/rulesDropdown/rulesDropdown.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/rules/rulesDropdown/rulesDropdown.component.scss';
import { NegotiationService } from 'app/services/negotiation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-negotiation-rules-dropdown',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class RulesDropdownComponent implements OnInit, AfterViewInit, IComponentTranslation, IrulesDropdown {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.RULES.RULESDROPDOWN';
    showDropdown: boolean = false;
    rules: any;
    selectedRule: any;

    halfWidth: string;
    initialClick: boolean;
    model: any = {};
    overwriteTitle = true;

    @Output()
    ruleChanged = new EventEmitter<string>();
    squashedRules: any;

    @Input()
    set changeRuleSelected(value) {
        if (value) {
            this.changeCurrentRule(value.value);
        }
    }

    constructor(private _negotiationService: NegotiationService, private _translateService: TranslateService) { }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizeCarrierContainer();
    }
    ngOnInit() {
        this.rules = JSON.parse(JSON.stringify(this._translateService.instant(`${this.translationKey}.DATA`)));
        this.squashedRules = this.rules.map(c => c.DATA).flatten();
        this.model.carrierName = this._negotiationService.selectedCarrier.carrierName;
        if (this._negotiationService.selectedRule) {
            this.selectedRule = this.squashedRules.find(c => c.KEY === this._negotiationService.selectedRule);
            this._negotiationService.selectedRule = undefined;
            this.selectRule(this.selectedRule);
        } else {
            this.selectedRule = this.squashedRules[0];
            this.selectRule(this.selectedRule);
        }
        this.setRulesIndex();
    }

    ngAfterViewInit() {

    }

    selectRule(item) {
        this.ruleChanged.emit(item.KEY);
        this.selectedRule = item;
        this.showDropdown = false;
    }
    setRulesIndex() {
        let index = 1;
        this.rules.forEach(c => {
            c.DATA.forEach(d => {
                d.VALUE = `${index++}. ${d.VALUE}`;
            });
        });
    }

    closeOpenDropdown() {
        this.showDropdown = !this.showDropdown;
        if (this.showDropdown) {
            this.initialClick = true;
        }
        this.resizeCarrierContainer();
    }

    closeDropdown() {
        if (this.initialClick) {
            this.initialClick = false;
        } else {
            this.showDropdown = false;
        }
    }

    resizeCarrierContainer() {
        if (this.showDropdown) {
            setTimeout(() => {
                const x = document.getElementById('carrierNameContainer').offsetWidth;
                this.halfWidth = (x * .9 / 2) + 'px';
            }, 10);
        }
    }

    changeCurrentRule(value) {
        const selectedRuleIndex = this.squashedRules.map(c => c.KEY).indexOf(this.selectedRule.KEY);
        if (selectedRuleIndex + value < 0) {
            this.selectedRule = this.squashedRules[this.squashedRules.length - 1];
        } else if (selectedRuleIndex + value > this.squashedRules.length - 1) {
            this.selectedRule = this.squashedRules[0];
        } else {
            this.selectedRule = this.squashedRules[selectedRuleIndex + value];
        }
        this.ruleChanged.emit(this.selectedRule.KEY);
    }
}