import { FileUploadType } from '../../../../models/index';
import { CollateralCoverage } from '../../../../api/generated/CollateralCoverage';
import { ExcelUploadService } from '../../../../services/excel-upload.service';
import { UploadService } from '../../../../services/upload-data.service';
import { RunService } from '@wtw/platform/services';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataUpload, RunViewModel, ScenariosModel } from 'app/api/dtos';
import { TranslateService } from '@ngx-translate/core';
import { UploadErrorType } from '@wtw/platform/models';

import * as componentTemplate from '@acl-cq-lib-pages-datainput/coverage-data/coverage-file-upload/coverageFileUpload.component.html';
import { ICoverageFileUpload } from '@acl-cq-lib-pages-datainput/coverage-data/coverage-file-upload/ICoverageFileUpload';

@Component({
    selector: 'coverage-file-upload',
    templateUrl: `${componentTemplate}`
})

export class CoverageFileUploadComponent implements OnInit, ICoverageFileUpload {
    translationKey = 'COLLATERAL.COVERAGEDATA.FILEUPLOAD.SIDEBAR';
    translation = {};
    runId: number;
    runViewModel: RunViewModel;
    errorMessage: string;
    fileName: any;

    @Input()
    dataUpload: DataUpload;

    @Output()
    removeFile = new EventEmitter<boolean>();

    @Output()
    persist = new EventEmitter<boolean>();

    public acceptedSovFileTypes: Array<string> = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
    ];

    constructor(private _uploadDataService: ExcelUploadService, private _runService: RunService, private _translationService: TranslateService) {
        _runService.activeRun.subscribe(c => {
            this.runId = c.runId;
            this.runViewModel = c.data;
        });
    }

    ngOnInit() { }

    uploadDCF_File(file) {
        this.errorMessage = '';
        const data = new FormData();
        data.append('file', file);
        this._uploadDataService.upload(data, FileUploadType.dcfFile, this.runId).subscribe(c => {
            if (c.uploaded) {
                this.dataUpload.uploaded = true;
                this.dataUpload.collateralCoverage = c.collateralCoverage;
                this.persist.emit(true);
            } else {
                this.fileUploadError({ error: UploadErrorType.invalidFile, fileName: file.name });
            }
        },
            error => {
                this.fileUploadError({ error: UploadErrorType.invalidFile, fileName: file.name });
            }
        );
    }

    closeError() {
        this.errorMessage = '';
    }

    fileUploadError(errorType: { error: UploadErrorType, fileName: string }) {
        this.fileName = errorType.fileName;
        switch (errorType.error) {
            case (UploadErrorType.invalidFileFormat):
                this.errorMessage = `${this._translationService.instant('COLLATERAL.COVERAGEDATA.FILEUPLOAD.FILETYPEERROR')}`;
                break;

            case (UploadErrorType.fileLimitExceeded):
                this.errorMessage = `${this._translationService.instant('COLLATERAL.COVERAGEDATA.FILEUPLOAD.FILELIMITERROR')}`;
                break;

            case (UploadErrorType.invalidFile):
                this.errorMessage = `${this._translationService.instant('COLLATERAL.COVERAGEDATA.FILEUPLOAD.FILEERROR')}`;
                break;
        }
    }
}
