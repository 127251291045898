
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LocalisationComponent } from '@wtw/platform/pages/admin/localisation/localisation.component';
import { AlertService, ModalService } from '@wtw/platform/services';
import { TranslateService } from '@ngx-translate/core';
import { Proxy } from '@wtw/platform/api';
import * as componentStyle from '@acl-cq-lib-pages-scenario/scenarioSharedComponents/tabsExtraMenu/tabsExtraMenu.component.scss';
import { BsModalService } from 'ngx-bootstrap';


@Component({
    selector: 'extended-localisation',
    templateUrl: './localisationExtender.component.html',
    styleUrls: ['../../../../../../node_modules/@wtw/platform/pages/admin/localisation/localisation.component.scss', './localisationExtender.component.scss', '' + componentStyle]
})

export class ExtendedLocalisationComponent extends LocalisationComponent implements OnInit, OnDestroy {
    showDetails1: boolean = false;
    loaded: boolean = false;
    modalAnswer: boolean;

    @Input()
    set keyToBeAdded(value) {
        if (value) {
            this.addKey(value.key);
        }
    }


    @Output()
    languageChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    public movedKeys: {
        [key: string]: {
            [key: string]: string[]
        }
    };

    private _selectedViewingModeLocal: string;
    private _selectedCultureLocal: any;

    constructor(_alert: AlertService,
        private modal: ModalService,
        private translate: TranslateService,
        _localisationProxy: Proxy.LocalisationProxy,
        _languageProxy: Proxy.LanguageProxy,
        private bsModdalService: BsModalService) {
        super(_alert, modal, translate, _localisationProxy, _languageProxy);
    }

    ngOnInit() {
        this._subscriptions.push(this.bsModdalService.onHidden.subscribe(c => {
            if (!this.modalAnswer) {
                this.modalAnswer = true;
                this.selectedViewingMode = this._selectedViewingModeLocal;
                this.selectedCulture = this._selectedCultureLocal;
            }
        }));
    }

    ngOnDestroy() {

    }

    public showMovedKeys() {
        this.showDetails1 = !this.showDetails1;
    }

    public getTranslations() {
        if (this.movedKeys === undefined) {
            this.movedKeys = {};
            this.viewingModes.forEach(c => {
                this.movedKeys[c] = {};
                this.cultures.forEach(d => {
                    this.movedKeys[c][d.name] = [];
                });
            });
        }
        if (this._selectedViewingModeLocal !== undefined && this._selectedCultureLocal !== undefined) {
            this._selectedViewingModeLocal = this.selectedViewingMode;
            this._selectedCultureLocal = this.selectedCulture;
            this.languageChanged.emit(this.movedKeys[this._selectedViewingModeLocal][this._selectedCultureLocal.name]);
        } else if (this._selectedViewingModeLocal !== this.selectedViewingMode || this._selectedCultureLocal.name !== this.selectedCulture.name) {
            this._selectedViewingModeLocal = this.selectedViewingMode;
            this._selectedCultureLocal = this.selectedCulture;
            this.languageChanged.emit(this.movedKeys[this._selectedViewingModeLocal][this._selectedCultureLocal.name]);
            this.movedKeys[this._selectedViewingModeLocal][this._selectedCultureLocal.name].forEach(c => this.addKey(c));
        }
        this.loaded = true;
        super.getTranslations();
    }

    public checkForUnsavedChanges(value) {
        this.modalAnswer = undefined;
        if (this.movedKeys[this._selectedViewingModeLocal][this._selectedCultureLocal.name].length > 0) {
            this.modal.confirm({ title: this.translate.instant('ADMIN.FILES.UNSAVED_CHANGES') }).then((res) => {
                if (res) {
                    this.movedKeys[this._selectedViewingModeLocal][this._selectedCultureLocal.name] = [];
                    this.modalAnswer = true;

                    this.getTranslations();
                } else {
                    this.modalAnswer = true;
                    this.selectedViewingMode = this._selectedViewingModeLocal;
                    this.selectedCulture = this._selectedCultureLocal;
                }
            },
                () => {

                });
        } else {
            this.getTranslations();
        }
    }

    public save(dataToSave: any, isUpload: boolean) {
        this.movedKeys[this._selectedViewingModeLocal][this._selectedCultureLocal] = [];
        super.save(dataToSave, isUpload);
    }

    dropDownItemClicked(item) {
        const searchKey = item.split('.').pop();
        document.getElementById('currentLocalisation').getElementsByClassName('jsoneditor-frame')[0].getElementsByTagName('input')[0].value = searchKey;
        (document.getElementById('currentLocalisation').getElementsByClassName('jsoneditor-frame')[0].getElementsByTagName('input')[0] as any).onkeyup(132);
    }

    private addKey(key) {
        const keySplit = key.split('.');
        let currentObj = this.data;
        keySplit.forEach((d, i) => {
            if (currentObj[d]) {
                currentObj = currentObj[d];
            } else {
                if (i === keySplit.length - 1) {
                    currentObj[d] = currentObj[d] || '';
                } else {
                    currentObj[d] = {};
                    currentObj = currentObj[d];
                }
            }
        });
        this.data = JSON.parse(JSON.stringify(this.data));
        if (this.movedKeys[this.selectedViewingMode][this.selectedCulture.name].find(c => c === key) === undefined) {
            this.movedKeys[this.selectedViewingMode][this.selectedCulture.name].push(key);
        }
    }
}
