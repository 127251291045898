
import { Component, OnInit } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/shared/navigation/navigation.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/shared/navigation/navigation.component.scss';
import { INavigation } from '@acl-cq-lib-pages-negotiation/shared/navigation/INavigation';
import { NegotiationService } from 'app/services/negotiation.service';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';

@Component({
    selector: 'app-negotiation-navigation',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class NavigationComponent extends ReactiveComponent implements OnInit, IComponentTranslation, INavigation {

    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.NAVIGATION';
    public navigationModel: any[];

    constructor(private _negotiationService: NegotiationService) { super(); }

    ngOnInit() {
        this.navigationModel = this._negotiationService.navigationModel;
    }
}

