import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollateralCoverage } from 'app/api/dtos';
import { ModalService } from '@wtw/platform/services';
import { IStandardModalConfig, IButtonConfig } from '@wtw/platform/interfaces';
import { TranslateService } from '@ngx-translate/core';

import * as componentTemplate from '@acl-cq-lib-pages-datainput/coverage-data/coverage-detail-table-card/coverage-detail-table-card.component.html';
import { ICoverageDetailTableCard } from '@acl-cq-lib-pages-datainput/coverage-data/coverage-detail-table-card/ICoverageDetailTableCard';

@Component({
    selector: 'col-coverage-detail-table-card',
    templateUrl: `${componentTemplate}`
})

export class CoverageDetailTableCardComponent implements OnInit, ICoverageDetailTableCard {
    translationKey = 'COLLATERAL.COVERAGEDATA.RISKTYPE';
    translation = {};
    @Input() collateralCoverage: CollateralCoverage[];

    @Output()
    removeCoverage = new EventEmitter<number>();

    constructor(private _modalService: ModalService,
        private _translateService: TranslateService) {

    }

    ngOnInit(): void {

    }

    removeSelectedCoverage(riskTypeId) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('COLLATERAL.COVERAGEDATA.RISKTYPE.REMOVAL.DELETEBUTTON'),
            cssClass: 'btn btn-primary'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('GLOBAL.BTN.CANCEL')
        };

        const coverageShortName = this.collateralCoverage.find(cv => cv.coverageId === riskTypeId).coverageName;

        const confirmConfig = <IStandardModalConfig>{
            title: this._translateService.instant('COLLATERAL.COVERAGEDATA.RISKTYPE.REMOVAL.TITLE'),
            withHeader: true,
            message: this._translateService.instant('COLLATERAL.COVERAGEDATA.RISKTYPE.REMOVAL.MESSAGE1') +
                this._translateService.instant('COLLATERAL.COVERAGEDATA.RISKTYPE.' + coverageShortName) + '? ' +
                this._translateService.instant('COLLATERAL.COVERAGEDATA.RISKTYPE.REMOVAL.MESSAGE2'),
            yesButton: yesConfig,
            noButton: cancelConfig
        };
        this._modalService.confirm(confirmConfig).then(
            (c) => {
                if (c) {
                    this.confirmRemoveSelectedCoverage(riskTypeId);
                }
            }
        );


    }

    confirmRemoveSelectedCoverage(riskTypeId: number) {
        this.removeCoverage.emit(riskTypeId);
    }
}
