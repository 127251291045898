import { FormArray, FormGroup } from '@angular/forms';
import { ScenarioProxy } from '../api/generated/ScenarioController';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import {
    CollateralCostRate,
    RunViewModel,
    ScenariosModel,
    ScenarioStateEnum,
    SecurityType,
    Scenario,
    CarrierDetails,
    CarrierType
} from '../models/index';

import { Injectable } from '@angular/core';
import { RunService, AlertService, ModalService, ApplicationInsightsService } from '@wtw/platform/services';
import { CurrencyInfo, RunModel } from '@wtw/platform/api/dtos';
import { TranslateService } from '@ngx-translate/core';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';
import { ReferenceDataService } from './referenceData.service';

@Injectable()
export class ScenarioService {
    public selectedScenario: any;
    public scenarios: any = [];
    public scenariosModel: ScenariosModel;
    public securityTypeDictionary: { [key: string]: string };
    public loaded: boolean;
    public summaryResultsSelected: boolean = false;
    public isCoveragesMismatch$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private activeRunSubscription: Subscription[];
    private run: RunViewModel;
    private runId: number;
    private currencyInfo: CurrencyInfo;
    private currenctScenarioIndex: number = 0;
    private scenarioChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private runService: RunService, private scenarioProxy: ScenarioProxy, private _alertService: AlertService,
        private _translationService: TranslateService, private modalService: ModalService, private _refDataService: ReferenceDataService,
        private _applicationInsightsService: ApplicationInsightsService) {
    }

    get scenarioChanged(): Observable<boolean> {
        return this.scenarioChanged$.asObservable();
    }

    setCurrentScenarios() {
        this.activeRunSubscription = [this.runService.activeRun.subscribe(c => {
            this._applicationInsightsService.send({
                eventCategory: 'Client - Runs',
                eventAction: 'RunCompleted',
                runId: c.runId
            });
            this.initSubscriptions(c);
        }),
        this.runService.persisted.subscribe(c => {
            this.initSubscriptions(c);
        })
        ];
    }

    initSubscriptions(runModel: RunModel) {
        this.run = runModel.data;
        this.runId = runModel.runId;
        this.currencyInfo = runModel.currencyInfo;
        this.scenariosModel = this.run.scenariosModel;
        this.setInitialState();
    }

    destroyCurrentSubscriptions() {
        this.run = undefined;
        this.runId = undefined;
        this.currencyInfo = undefined;
        this.scenarios = [];
        this.currenctScenarioIndex = 0;
        this.selectedScenario = undefined;
        this.scenariosModel = undefined;
        this.activeRunSubscription.forEach(c => c.unsubscribe());
    }


    setInitialState() {
        if (this.scenariosModel.scenarios && this.scenariosModel.scenarios.length > 0) {
            this.scenarios = JSON.parse(JSON.stringify(this.scenariosModel.scenarios));

            this.scenarios.forEach(c => {
                c.name = c.scenarioName;
                c.tabClass = 'collapsed';
                c.scenarioState = ScenarioStateEnum.unselected;
            });
            this.clickScenarioTab(this.currenctScenarioIndex);
            this.loaded = true;
        } else {
            this.addScenario();
        }
    }

    clickScenarioTab(scenarioIndex: number) {
        if (scenarioIndex === -1) {
            this.scenarios.forEach(c => {
                c.scenarioState = ScenarioStateEnum.unselected;
                c.tabClass = 'collapsed';
            });
            return;
        }
        this.summaryResultsSelected = false;
        let scenario = this.scenarios[scenarioIndex];

        if (scenario.scenarioState === ScenarioStateEnum.unselected) {
            this.scenarios.forEach(c => {
                c.scenarioState = ScenarioStateEnum.unselected;
                c.tabClass = 'collapsed';
            });
            if (!scenario.scenarioResult) {
                scenario.scenarioState = ScenarioStateEnum.selected;
                scenario.tabClass = 'btn-primary';
            } else {
                scenario.scenarioState = ScenarioStateEnum.result;
                scenario.tabClass = 'btn-primary collapsed';
            }
            this.selectedScenario = scenario;
            this.securityTypeDictionary = undefined;

            (this.selectedScenario.carriers as CarrierDetails[]).forEach(carrier => {
                carrier.collateralCostRate.securities.sort((a, b) => { return a.securityType - b.securityType; });
                if (this.securityTypeDictionary === undefined) {
                    this.securityTypeDictionary = {};

                    carrier.collateralCostRate.securities.forEach(c => {
                        this.securityTypeDictionary[c.securityType] = SecurityType[c.securityType].toUpperCase();
                    });
                }
            });

            this.currenctScenarioIndex = scenarioIndex;

        } else if (scenario.scenarioState === ScenarioStateEnum.selected && scenario.scenarioResult) {
            scenario.scenarioState = ScenarioStateEnum.result;
            scenario.tabClass = 'btn-primary collapsed';
        } else if (scenario.scenarioState === ScenarioStateEnum.result) {
            scenario.scenarioState = ScenarioStateEnum.selected;
            scenario.tabClass = 'btn-primary';
        }

        this.scenarioChanged$.next(true);
    }

    selectCurrentScenarioForEdit() {
        if (this.selectedScenario.scenarioState === ScenarioStateEnum.selected) {
            this.selectedScenario.scenarioState = ScenarioStateEnum.edit;
        }
    }

    closeScenarioEdit() {
        if (this.selectedScenario.scenarioState === ScenarioStateEnum.selected) {
            this.selectedScenario.scenarioState = ScenarioStateEnum.edit;
        }

        if (this.selectedScenario.scenarioState === ScenarioStateEnum.edit) {
            this.selectedScenario.scenarioState = ScenarioStateEnum.selected;
        }
    }

    addScenario() {
        if (this.scenarios.length < 5) {
            this.scenarioProxy.createScenario(this.run, this.runId).uiSignal('Adding Scenario').subscribe(c => {
                this.scenariosModel.scenarioUniqueId = c.data.scenarioUniqueId;
                this.scenarios = c.data.scenarios;
                this.scenarios[this.scenarios.length - 1].scenarioName = this._translationService.instant('COLLATERAL.SCENARIOS.SCENARIONAME') + this.scenariosModel.scenarioUniqueId;
                this.currenctScenarioIndex = this.scenarios.length - 1;
                this.scenarios[this.currenctScenarioIndex].isFormValid = false;
                this.scenarios[this.currenctScenarioIndex].showWarnings = false;
                this.save();
                this.scenarioChanged$.next(true);
            });
        }
    }

    removeCurrentScenario() {
        this.scenarioProxy.removeScenario(this.runId, this.selectedScenario.scenarioId).subscribe(c => {
            this.removeScenario();
        });
    }

    removeCurrentScenarioPopUp(translationKey) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this._translationService.instant('GLOBAL.BTN.DELETE'),
            cssClass: 'btn btn-primary btn-scenario-remove'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this._translationService.instant('GLOBAL.BTN.CANCEL')
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this._translationService.instant(translationKey + '.REMOVAL.TITLE') + ' "' + this.selectedScenario.name + '"?',
            withHeader: false,
            message: this._translationService.instant(translationKey + '.REMOVAL.MESSAGE'),
            yesButton: yesConfig,
            noButton: cancelConfig
        };

        this.modalService.confirm(confirmConfig).then(
            (c) => {
                if (c) {
                    this.removeCurrentScenario();
                }
            }
        );
    }

    copyScenario() {
        if (this.scenarios.length < 5) {
            this.scenarioProxy.createScenarioId(this.runId).uiSignal('Coping scenario').subscribe(c => {
                let selectedScenarioString = JSON.stringify(this.selectedScenario);
                let carriersCreated = 0;
                (this.selectedScenario as Scenario).carriers.forEach((d, i) => {
                    this.scenarioProxy.createScenarioCarrierId(this.runId, c.data, d.carrierOrderId).subscribe(
                        e => {
                            const regex = new RegExp(`"scenarioCarrierId":${d.scenarioCarrierId}`, 'gi');
                            const newValue = `"scenarioCarrierId":${e.data}`;
                            selectedScenarioString = selectedScenarioString.replace(regex, newValue);
                            if (++carriersCreated === this.selectedScenario.carriers.length) {
                                this.updateCopyScenario(selectedScenarioString, c.data);
                            }
                        }
                    );
                });

            });
        }
    }

    save(isFormValid?: boolean) {
        if (this.selectedScenario) {
            if (typeof isFormValid !== 'undefined') {
                this.selectedScenario.isValid = isFormValid;
                this.selectedScenario.scenarioResult = undefined;
                this.selectedScenario.isRTriggered = false;
            }
        }

        this.run.scenariosModel.scenarios = this.scenarios;
        this.run.scenariosModel.scenarioUniqueId = this.scenariosModel.scenarioUniqueId;
        this.runService.persist(this.runId, this.run, this.currencyInfo).subscribe(c => {
            if (typeof isFormValid !== 'undefined') { //I'm using this flag, as it's only defined when called from the savebutton on edit
                this._alertService.success(`'${this.selectedScenario.scenarioName}' details have been saved.`);
            }
        });
    }

    processScenario() {
        this.selectedScenario.isRTriggered = true;
        if (this.selectedScenario.isValid) {
            this.clearSelectedForRFlag();
            this.selectedScenario.selectedForR = true;
            this.run.scenariosModel.scenarios = this.scenarios;
            this.runService.executeAlgorithm(this.runId, this.run, `processScenario`,
                this.currencyInfo, this.selectedScenario.scenarioId, false)
                .subscribe(results => {
                    this.run = results.data;
                    if (!this.run.isScenarioAnalysisComplete) {
                        this._applicationInsightsService.send({
                            eventCategory: 'ScenarioAnalysis',
                            eventAction: 'ScenarioAnalysisRun',
                            runId: this.runId
                        });
                        this.run.isScenarioAnalysisComplete = true;
                    }
                });
        } else {
            this.selectedScenario.isSaved = true;
            this.run.scenariosModel.scenarios = this.scenarios;
            this.runService.persist(this.runId, this.run, this.currencyInfo);
        }
    }

    clearSelectedForRFlag() {
        if (this.scenarios.length > 0) {
            this.scenarios.forEach(s => {
                s.selectedForR = false;
            });
        }
    }

    public markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public markFormArrayTouched(formArray: FormArray) {
        (<any>Object).values(formArray.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public setSummaryResultsSelected() {
        this.summaryResultsSelected = true;
        this.currenctScenarioIndex = -1;
        this.clickScenarioTab(this.currenctScenarioIndex);
    }

    public isIncumbentComparison() {
        return this.selectedScenario.carriers.find(c => c.typeOfCarrier === CarrierType.incumbent) !== undefined;
    }

    public riskTypeList() {
        return this._refDataService.referenceData.riskTypes
            .filter(c => this.run.dataUpload.collateralCoverage.find(d => d.coverageId === c.id) !== undefined)
            .sort((a, b) => { return a.displayOrderId - b.displayOrderId; });
    }

    public ivpTranslation(carrierId) {
        const ivp = this.isIncumbentComparison();
        return ivp ? carrierId === 0 ? 'INCUMBENT' : 'PROSPECTIVE' : `PVP`;
    }

    private removeScenario(): void {
        this.scenarios.splice(this.currenctScenarioIndex, 1);
        this.currenctScenarioIndex = 0;
        this.save();
    }

    private updateCopyScenario(scenarioString: string, newScenarioId: number) {
        let auxScenario = JSON.parse(scenarioString);
        auxScenario.scenarioId = newScenarioId;
        auxScenario.isSaved = false;
        auxScenario.scenarioName += ' (copy)';
        auxScenario.scenarioResult = undefined;
        auxScenario.isSelected = false;
        this.scenarios.push(auxScenario);
        this.currenctScenarioIndex = this.scenarios.length - 1;
        this.save();
    }
}

