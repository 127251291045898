import { Component, OnInit } from '@angular/core';
import { Irules } from '@acl-cq-lib-pages-negotiation/rules/Irules';

import * as componentTemplate from '@acl-cq-lib-pages-negotiation/rules/rules.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/rules/rules.component.scss';
import { NegotiationService } from 'app/services/negotiation.service';
import { NegotiationRule, LOBRuleData, RiskTypeEnum } from 'app/models';
@Component({
    selector: 'app-negotiation-rules',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class RulesComponent implements OnInit, Irules {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.RULES';
    public model: any = {};
    rule: LOBRuleData;
    rules: NegotiationRule[];
    riskTypeId: RiskTypeEnum;
    selectedRule: NegotiationRule;
    changeRule: { value: any; };
    ruleId: string;

    constructor(private _negotiationService: NegotiationService) { }

    ngOnInit() {
        this.rules = this._negotiationService.selectedCarrier.carrierAssessmentOutputs[0].negotiationRules;
        this.rule = this.rules[0].lobRuleData.find(c => c.riskType === this._negotiationService.selectedCarrier.currentYearDataStatus.lobDataStatuses[0].riskTypeId);
        this.riskTypeId = this.rule.riskType;
        this.selectedRule = this.rules[0];
        this.ruleId = this.selectedRule.ruleId;
        this.model.carrierName = this._negotiationService.selectedCarrier.carrierName;
    }

    lobChanged(riskType) {
        if (this.selectedRule) {
            this.rule = this.selectedRule.lobRuleData.find(c => c.riskType === riskType);
        }
    }

    selectRule(ruleId) {
        this.selectedRule = this.rules.find(c => c.ruleId === ruleId.toString());
        this.ruleId = ruleId;
        this.lobChanged(this.riskTypeId);
    }

    changeRuleSelected(index) {
        this.changeRule = { value: index };
    }
}
