import { Component, OnInit } from '@angular/core';
import { Scenario, SummaryResult, CoverageName } from '../../../../api/dtos';
import { ScenarioService } from 'app/services/scenario.service';
import { TranslateService } from '@ngx-translate/core';
import { KeyValuePipe } from '@angular/common';
import { ShortNumberPipe } from 'app/shared/pipes/short-number.pipe';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results-summary/summaryTable/summarytable.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-results-summary/scenarioResultsSummaryContainer.component.scss';
import { ISummaryTable } from '@acl-cq-lib-pages-scenario-results-summary/summaryTable/ISummaryTable';

@Component({
    selector: 'summary-table',
    styleUrls: ['' + componentStyle],
    templateUrl: `${componentTemplate}`
})

export class SummaryTableComponent implements OnInit, ISummaryTable {
    translation = {};
    translationKey: string = 'COLLATERAL.SCENARIOS.RESULTS.SUMMARY_TAB';

    model: any;
    scenarios: Scenario[];
    coveragesByScenario: string[][];
    totalInvestmentData: any[] = [];
    totalSavingsData: any[] = [];
    totalGainData: any[] = [];
    benefitsBeginData: any[] = [];
    breakevenPointData: any[] = [];

    constructor(
        private _scenarioService: ScenarioService,
        private _translateService: TranslateService,
        private _keyValuePipe: KeyValuePipe,
    ) { }

    ngOnInit() {
        this.scenarios = this._scenarioService.scenarios;
        this.setCoveragesByScenarioData();
        this.setData();
        this.setModel();
    }

    private setModel() {
        this.model = [
            {
                displayKey: 'TABLE_ROW1_TITLE',
                descriptionKey: 'TABLE_ROW1_DESCRIPTION',
                displayValues: this.coveragesByScenario
            },
            {
                displayKey: 'TABLE_ROW2_TITLE',
                descriptionKey: 'TABLE_ROW2_DESCRIPTION',
                displayValues: this.totalInvestmentData
            },
            {
                displayKey: 'TABLE_ROW3_TITLE',
                descriptionKey: 'TABLE_ROW3_DESCRIPTION',
                displayValues: this.totalSavingsData
            },
            {
                displayKey: 'TABLE_ROW4_TITLE',
                descriptionKey: 'TABLE_ROW4_DESCRIPTION',
                displayValues: this.totalGainData
            },
            {
                displayKey: 'TABLE_ROW5_TITLE',
                descriptionKey: 'TABLE_ROW5_DESCRIPTION',
                displayValues: this.benefitsBeginData
            },
            {
                displayKey: 'TABLE_ROW6_TITLE',
                descriptionKey: 'TABLE_ROW6_DESCRIPTION',
                displayValues: this.breakevenPointData
            }
        ];
    }

    private setCoveragesByScenarioData() {
        let scenarioCoverages = [];
        let auxScenarioCoverages = [];
        let isCoveragesMismatchFound = false;
        this.coveragesByScenario = [];

        this.scenarios.forEach(scenario => {
            let riskTypeIds = Object.keys(scenario.coverageDisplayData);
            riskTypeIds.forEach(id => {
                let coverageName = <CoverageName>this._keyValuePipe.transform(scenario.coverageDisplayData).find(kvp => kvp.key === id).value;
                if (!coverageName.isDisabled) {
                    scenarioCoverages.push(coverageName.riskTypeShortDescription);
                }
            });

            if (scenario.isRTriggered && scenario.isValid) {
                if (auxScenarioCoverages.length === 0) {
                    auxScenarioCoverages = scenarioCoverages;
                } else if (JSON.stringify(auxScenarioCoverages) !== JSON.stringify(scenarioCoverages)) {
                    isCoveragesMismatchFound = true;
                }
            }

            this.coveragesByScenario.push(scenarioCoverages);
            scenarioCoverages = [];
        });

        this._scenarioService.isCoveragesMismatch$.next(isCoveragesMismatchFound);
    }

    private setData() {
        this.scenarios.forEach(scenario => {
            if (scenario.scenarioResult && scenario.scenarioResult.summaryResult) {
                let summaryResult = scenario.scenarioResult.summaryResult;
                this.totalInvestmentData.push(summaryResult.totalInvestment);
                this.totalSavingsData.push(summaryResult.totalSavings);
                this.totalGainData.push(summaryResult.totalGain);
                if (summaryResult.benefitsBeginMonth) {
                    this.benefitsBeginData.push(summaryResult.benefitsBeginMonth);
                } else {
                    this.benefitsBeginData.push('N/A');
                }
                if (summaryResult.breakevenMonth) {
                    this.breakevenPointData.push(summaryResult.breakevenMonth);
                } else {
                    this.breakevenPointData.push('N/A');
                }
            } else {
                this.totalInvestmentData.push('');
                this.totalSavingsData.push('');
                this.totalGainData.push('');
                this.benefitsBeginData.push('');
                this.breakevenPointData.push('');
            }
        });
    }
}