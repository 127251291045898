﻿
import * as Dto                          from '../dtos'; 
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable }                    from '@angular/core';
import { Observable }                    from 'rxjs';
import { ApiResult,BinaryTransferObject} from '@wtw/platform/api';
import { map }                           from  'rxjs/operators';
export { ApiResult };

@Injectable()
export class UploadDataProxy {
    	constructor(private _http: ProxyHttp) { 
        }
        
             
                removeCoverageDataForRun (uploadType: Dto.FileUploadType, runId: number, riskType: Dto.RiskTypeEnum) : Observable<ApiResult<Dto.FileUploadStatus>> {
                return this._http.get(`/backend/UploadData/removeCoverage/${uploadType}/${runId}/${riskType}`,null||{}).pipe(map(ret=>({ data: ret.body, originalResponse: ret})));
	        }
                getCoverageList (runId: number) : Observable<ApiResult<Dto.CollateralCoverage[]>> {
                return this._http.get(`/backend/UploadData/getCoverageList/${runId}`,null||{}).pipe(map(ret=>({ data: ret.body, originalResponse: ret})));
	        }
             
                static upload_url (uploadType: Dto.FileUploadType, runId: number) :{method:string, url:string} {
                return {method: 'post', url:`/platform/UploadData/upload/${uploadType}/${runId}`};
	        }
        
}

	
