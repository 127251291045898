import { Routes } from '@angular/router';
import { NavigationAction, NavigationType } from '@wtw/platform/interfaces';
import { ClientInformationComponent } from './pages/datainput/client-information/client-information.component';
import { CoverageDataComponent } from './pages/datainput/coverage-data/coverage-data.component';
import { UltimateAnalysisComponent } from './pages/ultimate-analysis/ultimate-analysis.component';
import { ScenariosAnalysisComponent } from './pages/scenarios-analysis/scenarios-analysis.component';
import { HasRefDataGuard } from './services/refData.guard';
import { NegotiationReportComponent } from './pages/negotiationReport';
import { NegotiationGuard } from './services/negotiation.guard';

export const RUN_PAGES: Routes = [
    {
        path: 'client-information',
        component: ClientInformationComponent,
        canActivate: [HasRefDataGuard],
        data: {
            'onForwardNavigation': <NavigationAction>{
                type: NavigationType.persist
            }, 'runProgress': true,
            'showRunNavigation': true
        }
    },
    {
        path: 'coverage-data',
        component: CoverageDataComponent,
        canActivate: [HasRefDataGuard],
        data: {
            'onForwardNavigation': <NavigationAction>{
                type: NavigationType.persist
            }, 'runProgress': true,
            'showRunNavigation': true,
            'customNavigation': true
        }
    },
    // {
    //     path: 'coverage-review',
    //     component: CoverageReviewComponent,
    //     data: {
    //         'onForwardNavigation': <NavigationAction>{
    //             type: NavigationType.persistAndExecute
    //             //, functionName: 'testrsql'
    //         }, 'runProgress': true,
    //         'showRunNavigation': false,
    //         'customNavigation': false
    //     }
    // },
    {
        path: 'ultimate-analysis',
        component: UltimateAnalysisComponent,
        canActivate: [HasRefDataGuard],
        data: {
            'runProgress': false,
            'isResultsPage': true,
            'showRunNavigation': true
        }
    },
    {
        path: 'scenarios',
        component: ScenariosAnalysisComponent,
        canActivate: [HasRefDataGuard],
        data: {
            'runProgress': false,
            'isResultsPage': true,
            'showRunNavigation': true
        }
    },
    {
        path: 'negotiation',
        component: NegotiationReportComponent,
        canActivate: [HasRefDataGuard],
        canDeactivate: [NegotiationGuard],
        data: {
            'runProgress': false,
            'isResultsPage': true,
            'showRunNavigation': true,
            'unorderedChildren': true
        }
    }
];
