import { UltimateAnalysisSummaryComponent } from './ultimate-analysis/summary/ultimate-analysis-summary.component';
import * as ScenarioAnalysis from './scenarios-analysis/index';
import * as NegotiationReport from './negotiationReport/index';

import { CoverageFileUploadComponent } from './datainput/coverage-data/coverage-file-upload/coverageFileUpload.component';
import { ClientInformationComponent } from './datainput/client-information/client-information.component';
import { CoverageDataComponent } from './datainput/coverage-data/coverage-data.component';
import { UltimateAnalysisComponent } from './ultimate-analysis/ultimate-analysis.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { TemplateSelectionComponent } from '../shared/components/template-selection/template-selection.component';
import { CoverageDetailTableCardComponent } from './datainput/coverage-data/coverage-detail-table-card/coverage-detail-table-card.component';
import { CqAutocompleteComponent } from './scenarios-analysis/scenarioSharedComponents/autocomplete/cqAutocomplete/cqAutocomplete.component';
import { ShowTranslationComponent } from 'app/pdf/sharedComponents/showTranslation/showTranslation.component';
import { ExtendedLocalisationComponent } from 'app/pdf/sharedComponents/showTranslation/localisationExtender/localisationExtender.component';
import { NavbarExtenderComponent } from 'app/shared/components/navbar-extender/navbar-extender.component';
import { NewFileUploadModalComponent } from './datainput/coverage-data/newFileUploadModal';


export const MainComponents = [ExtendedLocalisationComponent,
    ClientInformationComponent, CoverageDataComponent, NewFileUploadModalComponent,
    CoverageDetailTableCardComponent, LoaderComponent,
    TemplateSelectionComponent, CoverageFileUploadComponent,
    UltimateAnalysisSummaryComponent, UltimateAnalysisComponent,
    ScenarioAnalysis.CollateralCostRateComponent, ScenarioAnalysis.CollateralCostRateSummaryComponent, ScenarioAnalysis.CollateralRampUpComponent,
    ScenarioAnalysis.CollateralRampUpSummaryComponent, ScenarioAnalysis.CommonAssumptionsComponent, ScenarioAnalysis.CoverageAssumptionsSummaryComponent,
    ScenarioAnalysis.ProjectionSummaryComponent, ScenarioAnalysis.ScenarioEditComponent, ScenarioAnalysis.ScenarioProjectionComponent, ScenarioAnalysis.ScenariosAnalysisComponent,
    ScenarioAnalysis.ScenarioSummaryComponent, ScenarioAnalysis.ScenarioSummaryContainerComponent, ScenarioAnalysis.ScenarioTabsComponent, ScenarioAnalysis.DownloadReportComponent,
    ScenarioAnalysis.BreakEvenComponent, ScenarioAnalysis.EstimatedCashFLowComponent, ScenarioAnalysis.EstimatedCollateralComponent,
    ScenarioAnalysis.ScenariosAnalysisResultComponent, ScenarioAnalysis.ScenariosAnalysisResultContentComponent, ScenarioAnalysis.ScenariosAnalysisResultContentTableComponent,
    ScenarioAnalysis.ScenarioTitleComponent, ScenarioAnalysis.ScenarioSummaryFooterComponent, ScenarioAnalysis.CommonAssumptionsSummaryComponent, ScenarioAnalysis.ScenarioSummaryHeader,
    ScenarioAnalysis.AutocompleteComponent, ScenarioAnalysis.CoverageAssumptionsComponent, ScenarioAnalysis.ScenarioEditHeaderComponent, CqAutocompleteComponent,
    ScenarioAnalysis.SummaryButtonComponent, ScenarioAnalysis.ScenarioResultsSummaryContainerComponent, ScenarioAnalysis.SummaryTotalChartComponent,
    ScenarioAnalysis.SummaryBenefitsChartComponent, ScenarioAnalysis.SummaryTableComponent, ScenarioAnalysis.TabsExtraMenuComponent, NavbarExtenderComponent, ShowTranslationComponent,
    NegotiationReport.NegotiationReportComponent, NegotiationReport.InputSummaryComponent, NegotiationReport.CarrierOverViewComponent, NegotiationReport.AggregateComponent,
    NegotiationReport.CarrierListComponent, NegotiationReport.CarrierListTableComponent, NegotiationReport.CarrierListTableCollapsedRowComponent,
    NegotiationReport.ExpandedRowComponent, NegotiationReport.SummaryComponent, NegotiationReport.NavigationComponent,
    NegotiationReport.PageHeaderComponent, NegotiationReport.AggregateUploadComponent, NegotiationReport.RulesComponent, NegotiationReport.RulesDropdownComponent,
    NegotiationReport.RulesLobSelectorComponent, NegotiationReport.RulesTableComponent,
    NegotiationReport.RulesLobSelectorComponent, NegotiationReport.RulesTableComponent, NegotiationReport.ExecutionHistoryComponent,
    NegotiationReport.RulesLobSelectorComponent, NegotiationReport.RulesTableComponent, NegotiationReport.WarningBoxComponent, NegotiationReport.AssessmentChartComponent, NegotiationReport.UploadFileExtenderComponent,
    NegotiationReport.DownloadNegotiationReportComponent
];
