
import { Component, OnInit } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/carrierListTable.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/carrierListTable.component.scss';
import { ICarrierListTable } from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/ICarrierListTable';
import { NegotiationService } from 'app/services/negotiation.service';
import { RiskType, CarrierAssessmentData } from 'app/models';
import { TotalInvestmentChartComponent } from 'app/pdf';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';

@Component({
    selector: 'app-negotiation-carrier-list-table',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CarrierListTableComponent implements OnInit, IComponentTranslation, ICarrierListTable {

    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.CARRIERLISTTABLE';

    carrierList: CarrierAssessmentData[];
    lobSize = 3;
    lobs = [];
    totalRow = {
        carrierUltimateTotals: [],
        total: true
    };
    constructor(private _negotiationService: NegotiationService) { }

    ngOnInit() {
        this.carrierList = this._negotiationService.carrierList;
        this.lobs = Object.keys(this._negotiationService.riskTypes).sort((a, b) => this._negotiationService.riskTypes[a].displayOrderId - this._negotiationService.riskTypes[b].displayOrderId)
            .map(c => this._negotiationService.riskTypes[c].shortDescription);
        this.lobs.push('COLLATERAL');
        this.carrierList.forEach(c => {
            c.carrierUltimateTotals.forEach((d, i) => {
                if (this.totalRow.carrierUltimateTotals.length <= i) {
                    this.totalRow.carrierUltimateTotals.push({ value: d.value });
                } else {
                    if (this.totalRow.carrierUltimateTotals[i] === undefined && d.value) {
                        this.totalRow.carrierUltimateTotals[i] = { value: d.value };
                    } else if (d.value) {
                        this.totalRow.carrierUltimateTotals[i].value += d.value;
                    }
                }
            });
        });
    }

}
