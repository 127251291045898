import { ReferenceDataService } from '../../services/referenceData.service';
import { RunViewModel, NegotiationRule, LOBRuleData, ExecutionLog, TriggerType } from '../../api/dtos';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RiskType } from 'app/api/dtos';
import { RunService, SiteHeaderService, ModalService } from '@wtw/platform/services';
import { SummaryData } from './summary/ultimate-analysis-summary.component';

import * as componentTemplate from '@acl-cq-lib-pages-ultimate/ultimate-analysis.component.html';
import { IUltimateAnalysis } from '@acl-cq-lib-pages-ultimate/IUltimateAnalysis';
import { AlgorithmInputProxy } from 'app/api/generated/AlgorithmInputController';
import { ExecutionLogProxy } from 'app/api/generated/ExecutionLogController';
import { ExecutionLogService } from 'app/services/ExecutionLogService';
import { IStandardModalConfig } from '@wtw/platform/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'col-ultimate-analysis',
    templateUrl: `${componentTemplate}`
})

export class UltimateAnalysisComponent extends ReactiveComponent implements OnInit, OnDestroy, IUltimateAnalysis {
    public riskTypes: RiskType[] = [];
    public summaryData: SummaryData;
    public isSummaryVisible: boolean = false;
    translationKey = 'COLLATERAL.ULTIMATE-ANALYSIS';
    translation = {};
    runId: number;
    currencyInfo: any;
    private run: RunViewModel;

    constructor(private _runService: RunService,
        private _headerService: SiteHeaderService,
        private _referenceDataService: ReferenceDataService,
        private _algorithmInputProxy: AlgorithmInputProxy,
        private _modalService: ModalService,
        private _translate: TranslateService,
        private _executionLogProxy: ExecutionLogProxy,
        private _executionLogService: ExecutionLogService) {
        super();
    }

    ngOnInit(): void {

        this._subscriptions.push(this._runService.activeRun.subscribe(c => {
            this.run = c.data;
            this.runId = c.runId;
            this.currencyInfo = c.currencyInfo;
            let uniqueValues = Array.from(new Set(this.run.collateralYear.map(c => c.riskTypeId)));
            this.riskTypes = this._referenceDataService.referenceData.riskTypes.filter(c => uniqueValues.some(d => d === c.id));
        }));
    }

    showSummary(riskTypeId: number) {
        this.summaryData = this.createSummaryData(riskTypeId);
        this.isSummaryVisible = true;
    }

    closeSummary() {
        this.isSummaryVisible = false;
    }

    next() {
        let running = { processed: this.run.carrierAssessmentData.length, completed: 0, executing: true };
        this.executeTrigger(running);
        this.processUploadDataInputCompleted(running);
    }

    executeTrigger(running) {

        this._runService.executeAlgorithm(this.runId, this.run, 'processUploadData', this.currencyInfo, null, true)
            .subscribe(results => {
                this.run = results.data;
                running.executing = false;
                let data = this._executionLogService.generateExecutionLogs(this.run.carrierAssessmentData);
                this._executionLogProxy.saveExecutionLogs(data).subscribe(c =>
                    this._headerService.navigation.nextClicked.next()
                );
            },
                error => {
                    running.executing = false;
                    this.showRFailedModal();
                });
    }

    processUploadDataInputCompleted(running) {
        const rnd = Math.random() * 100000;
        this._algorithmInputProxy.processUploadDataInputCompleted(this.runId,
            running.completed > this.run.carrierAssessmentData.length - 2).uiSignal({ uiLabel: `Calculating ${running.completed}/${running.processed}`, debugInfo: `Calculating ${running.completed}/${running.processed}${rnd}` }).subscribe(c => {
                running.completed = (c.data as any).Completed;
                if (running.executing) {
                    this.processUploadDataInputCompleted(running);
                }
            });
    }

    back() {
        this._headerService.navigation.previousClicked.next();
    }

    private createSummaryData(riskTypeId: number): SummaryData {
        const data = this.run.collateralYear.find(lobCollateralYearSummary => lobCollateralYearSummary.riskTypeId === riskTypeId);

        return {
            data: [...data.yearlyData, data.collateralYearTotal],
            lob: this.riskTypes.find(riskType => riskType.id === riskTypeId).fullName,
            renewalDate: this.run.clientInformationInput.renewalDate
        };
    }

    private showRFailedModal(): void {
        const confirmSettings: IStandardModalConfig = {
            title: this._translate.instant(`${this.translationKey}.RFAILED.TITLE`),
            withHeader: false,
            message: this._translate.instant(`${this.translationKey}.RFAILED.MSG`),
            yesButton: { display: true, text: this._translate.instant(`${this.translationKey}.RFAILED.CLOSE`) },
            noButton: { display: false, text: '' }
        };
        this._modalService.confirm(confirmSettings);
    }
}