
declare var require: any;

export function highchartsFactory() {
  const hc = require('highcharts');
  const hbc = require('highcharts-histogram-bellcurve');
  const hcdd = require('highcharts/modules/drilldown');
  const hcMore = require('highcharts/highcharts-more');
  hcMore(hc);
  hbc(hc);
  hcdd(hc);
  return hc;
}
