import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CollateralYear } from '../../../api/dtos';

import * as componentTemplate from '@acl-cq-lib-pages-ultimate/summary/ultimate-analysis-summary.component.html';
import { IUltimateAnalysisSummary } from '@acl-cq-lib-pages-ultimate/summary/IUltimateAnalysisSummary';

export interface SummaryData {
    data: CollateralYear[];
    lob: string;
    renewalDate: Date;
}

@Component({
    selector: 'ultimate-analysis-summary',
    templateUrl: `${componentTemplate}`
})

export class UltimateAnalysisSummaryComponent extends ReactiveComponent implements IUltimateAnalysisSummary {
    @Input() summaryData: SummaryData;
    @Output() closeSummary = new EventEmitter();
    translationKey = 'COLLATERAL.ULTIMATE-ANALYSIS.SUMMARY';
    translation = {};

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    showUltimateAnalysis(): void {
        this.closeSummary.emit();
    }
}
