import { Component, Input, OnInit } from '@angular/core';
import {
    ScenariosAnalysisResultTab,
    ScenariosAnalysisResultContent,
    ScenariosAnalysisResultContentTableConfig,
    ScenariosAnalysisResultContentTable
} from 'app/models/index';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ScenarioService } from 'app/services/scenario.service';
import { ScenarioResult } from 'app/api/dtos';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results/scenarios-analysis-result.component.html';

@Component(
    {
        selector: 'scenarios-analysis-result',
        templateUrl: `${componentTemplate}`
    }
)

export class ScenariosAnalysisResultComponent extends ReactiveComponent implements OnInit {
    public get scenarioResult(): ScenarioResult {
        return this._scenarioService.selectedScenario.scenarioResult;
    }

    public get scenarioName(): string {
        return this._scenarioService.selectedScenario.scenarioName;
    }

    public translationKey: string = 'COLLATERAL.SCENARIOS.RESULTS';
    public translation = {};

    tabList: ScenariosAnalysisResultTab[];
    activeTab: ScenariosAnalysisResultTab;

    // TODO - SR - 06/11/2019 - move to localisation once pdf testing is done
    incumbentFieldName: string = 'Total - Incumbent';
    prospectiveFieldName: string = 'Total - Prospective';

    constructor(private _scenarioService: ScenarioService) {
        super();
    }

    ngOnInit(): void {
        this.initialiseTabList();
    }

    setTabActive(tab: ScenariosAnalysisResultTab): void {
        this.tabList.forEach(x => x.isSelected = false);
        tab.isSelected = true;
        this.activeTab = tab;
    }

    get showWarningMessage(): boolean {
        return !this._scenarioService.selectedScenario.isValid && this._scenarioService.selectedScenario.isSaved;
    }

    private initialiseTabList(): void {
        this.activeTab = this.createEstimatedCollateralTab();

        this.tabList = [
            this.activeTab,
            this.createEstimatedCashFlowDataListTab()
        ];

        if (this._scenarioService.isIncumbentComparison()) {
            this.tabList.push(this.createBrakeEvenAnalysisTab());
        }
    }

    private createTab(data: ScenariosAnalysisResultContent, id: string, title: string, isSelected: boolean = false): ScenariosAnalysisResultTab {
        return {
            content: data,
            isSelected: isSelected,
            id: id,
            title: title
        };
    }

    private createEstimatedCollateralTab(): ScenariosAnalysisResultTab {
        return this.createTab({
            id: 'estimatedCollateral',
            title: 'COLLATERAL.SCENARIOS.RESULTS.CONTENT_HEADER_ESTIMATED_COLLATERAL',
            tableData: this.createEstimatedCollateralTable(),
            graphData: undefined
        }, 'estimated-collateral-tab', 'COLLATERAL.SCENARIOS.RESULTS.TAB_ESTIMATED_COLLATERAL', true);
    }

    private createEstimatedCollateralTable(): ScenariosAnalysisResultContentTableConfig {
        // TODO - SR - 06/11/2019 - add to localisation for generic carrier use instead of incumbent vs prospective
        const tableDataMap: any[][] = this._scenarioService.selectedScenario.carriers.map((c, i) => {
            const isFirst = i === 0;
            return [
                { mapToField: 'future', fieldName: 'Future Collateral Required' },
                { mapToField: 'legacy', fieldName: 'Legacy Collateral Required' },
                { mapToField: 'total', isTotal: true, fieldName: this.isIncumbentComparison ? isFirst ? `${this.incumbentFieldName}  ` : `${this.prospectiveFieldName} ` : `${this.prospectiveFieldName} ${i + 1}  `, carrierName: c.name }
            ];
        });

        return {
            selectKey: 'estimatedCollateralResults',
            configList: tableDataMap
        };
    }

    private createEstimatedCashFlowDataListTab(): ScenariosAnalysisResultTab {
        return this.createTab({
            id: 'estimatedCashFlow',
            title: 'COLLATERAL.SCENARIOS.RESULTS.CONTENT_HEADER_ESTIMATED_CASH_FLOW',
            tableData: this.createEstimatedCashFlowDataListTable(),
            graphData: undefined
        }, 'estimated-cash-flow-tab', 'COLLATERAL.SCENARIOS.RESULTS.TAB_ESTIMATED_CASH_FLOW');
    }

    private createEstimatedCashFlowDataListTable(): ScenariosAnalysisResultContentTableConfig {
        // TODO - SR - 06/11/2019 - add to localisation for generic carrier use instead of incumbent vs prospective
        const tableDataMap: any[][] = this._scenarioService.selectedScenario.carriers.map((c, i) => {
            const isFirst = i === 0;
            return [
                { mapToField: 'premExp', fieldName: 'Premium + Expenses' },
                { mapToField: 'collCost', fieldName: 'Collateral Cost' },
                { mapToField: 'total', isTotal: true, fieldName: this.isIncumbentComparison ? isFirst ? `${this.incumbentFieldName}  ` : `${this.prospectiveFieldName} ` : `${this.prospectiveFieldName} ${i + 1}  `, carrierName: c.name }
            ];
        });

        return {
            selectKey: 'estimatedCashFlowYearResults',
            configList: tableDataMap
        };
    }

    private createBrakeEvenAnalysisTab(): ScenariosAnalysisResultTab {
        return this.createTab({
            id: 'brakeEvenAnalysis',
            title: 'COLLATERAL.SCENARIOS.RESULTS.CONTENT_HEADER_BRAKE_EVEN_ANALYSIS',
            tableData: undefined,
            graphData: undefined
        }, 'brake-even-analysis-tab', 'COLLATERAL.SCENARIOS.RESULTS.TAB_BRAKE_EVEN_ANALYSIS');
    }

    public get isIncumbentComparison(): boolean {
        return this._scenarioService.isIncumbentComparison();
    }
}
