import { Component, OnInit, ViewChild } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/inputSummary/inputSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/inputSummary/inputSummary.component.scss';
import { IInputSummary } from '@acl-cq-lib-pages-negotiation/inputSummary/IInputSummary';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { AggregateComponent } from './aggregate/aggregate.component';

@Component({
    selector: 'app-negotiation-input-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class InputSummaryComponent implements OnInit, IComponentTranslation, IInputSummary {
    public translation = {};
    public translationKey = '';
    public lobInputs: any;

    @ViewChild(AggregateComponent) aggregateComponent: AggregateComponent;
    constructor() { }

    ngOnInit() { }
    setlobInputs(lobInputs) {
        this.lobInputs = lobInputs;
    }

    discardChanges() {
        this.lobInputs = undefined;
    }
}
