
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as componentTemplate from '@acl-cq-lib-shared/downloadReport/downloadReport.component.html';
import * as componentStyle from '@acl-cq-lib-shared/downloadReport/downloadReport.component.scss';

@Component({
    selector: 'app-download-report',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class DownloadReportComponent implements OnInit {
    @Input() availableDownloadList: any[];
    @Output() downloadList: EventEmitter<any> = new EventEmitter<any>();
    public customClass = '';
    public translation = {
    };

    public translationKey = 'COLLATERAL.SCENARIOS.DOWNLOADPDFREPORT';
    public collapsed: boolean = false;
    constructor() { }

    ngOnInit() { }

    public downloadReport() {
        this.collapsed = !this.collapsed;
    }

    public clickedOutsideReport() {
        this.collapsed = false;
    }

    public updateCheckItem(item) {
        item.checked = !item.checked;
    }

    public download() {
        if (!this.disabled) {
            this.downloadList.emit(this.availableDownloadList);
        }
        this.collapsed = false;
    }

    public get disabled() {
        return this.availableDownloadList.filter(c => c.checked).length === 0;
    }

    public get disabledButton() {
        return this.availableDownloadList.length === 0;
    }
}
