import { Component, OnInit } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/carrierList/carrierList.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/carrierList/carrierList.component.scss';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { NegotiationService } from 'app/services/negotiation.service';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ProxyProxy } from '@wtw/platform/api/proxies';
import { WindowRef } from '@wtw/toolkit/src/utils/window-ref.service';
import { RunService } from '@wtw/platform/services';
import { CarrierStatus } from 'app/models';

@Component({
    selector: 'app-negotiation-carrier-list',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CarrierListComponent extends ReactiveComponent implements OnInit, IComponentTranslation {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.CARRIERLIST';
    public availableDownloadList;

    private runId: number;
    private clientId: number;

    constructor(private _negotiationService: NegotiationService, private window: WindowRef, private _runService: RunService) {
        super();
    }

    ngOnInit() {
        this._subscriptions.push(this._runService.activeRun.subscribe(c => {
            this.runId = c.info.runId;
            this.clientId = c.info.clientId;
        }));

        this._negotiationService.setCarrierListNavigation();

        this.populateAvailableDownloadList();

        // TODO: <ES 17/03/2020> Verify if this is correct
        this._subscriptions.push(this._negotiationService.rFinishedEvent.subscribe(rRan => {
            this.populateAvailableDownloadList();
        }));
    }

    generateDownloadReportUrl(event) {
        const carrierIdList = event.filter(c => c.checked).map(c => c.id).join(',');
        const pdfUrl = `pdf-negotiation/root?runId=${this.runId}&clientId=${this.clientId}` +
            `&pdfIdentifier=downloadReport&parameter=${carrierIdList}&pdfLanguage=enGB&isLandscape=true&filename=report.pdf`;
        const url = ProxyProxy.binary_url(pdfUrl).url;
        this.window.nativeElement.open(url);
    }

    private populateAvailableDownloadList(): void {
        const carriersWithRRan = this._negotiationService.carrierList.filter(c => c.showResults).map(c => {
            return {
                name: c.carrierName,
                checked: true,
                type: c.carrierStatus === CarrierStatus.goForward ? 'Go-Forward' : 'Legacy', //TODO: ES 16/03/2020 add translation
                id: c.negotiationCarrierId
            };
        });

        this.availableDownloadList = carriersWithRRan.length > 0 ? carriersWithRRan : undefined;
    }
}
