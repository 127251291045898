

import { Pipe, PipeTransform, ChangeDetectorRef, ElementRef, HostListener, Renderer } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ExtendedTranslateService } from 'app/services/extendedTranslateService';

@Pipe({
    name: 'translate'
})

export class ExtendedTranslatePipe extends TranslatePipe implements PipeTransform {

    constructor(private _translate: ExtendedTranslateService, _ref?: ChangeDetectorRef) {
        super(_translate, _ref);
    }

    transform(value: any, args: {}): any {
        return this._translate.instant(value, args);
    }
}
