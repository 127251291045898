import { CurrencyOverrideComponent } from './shared/currency/currencyOveride.component';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import './shared/IObjectExtender';

import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
/************************** File upload ******************************/
import { FileUploadModule } from 'ng2-file-upload';

/************************** Platform tools ******************************/
import { PlatformRootModule } from '@wtw/platform';
import { RUN_PAGES, RUN_LIST_NAVIGATION } from '@wtw/platform/bootstrap/extension-points';

/************************** Application Services *************************/
import { highchartsFactory } from './services/highcharts.factory';
import { BsDatepickerModule } from 'ngx-bootstrap';

/***************************Application Routes **************************/
import * as Routing from './routes';

/************************ Application Main Components ************************/
import { MainComponents } from './pages';
import { SharedComponents } from './shared';

/************************* reducers ********************************** */
/******************** Effects ************************************** */


import * as Config from './config';
import { TruncatePipe } from './pipes/truncate';
import { SearchFilterPipe } from './pipes/SearchFilter';
import { UploadDataProxy } from './api/generated/UploadDataController';

import { ClientCoverageDataProxy } from './api/generated/ClientCoverageDataController';
import { ReferenceDataProxy } from './api/generated/ReferenceDataController';
import { DateFormatPipe } from './pipes/dateFormat';
import { UtilService } from './services/util.service';
import { ReferenceDataService } from './services/referenceData.service';
import { UltimateAnalysisProxy } from './api/generated/UltimateAnalysisController';
import { ClientService, RunService, NavigationService } from '@wtw/platform/services';
import { HasRefDataGuard } from './services/refData.guard';
import { RunHelperService } from './services/runHelper.service';
import { ValidationProxy } from './api/generated/validationController';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedTranslateService } from './services/extendedTranslateService';
import { ExtendedTranslatePipe } from './pipes/extendedTranslate';
import { NavbarExtenderService } from './services/navbar-extender.service';
import { MaxLength } from './pipes/maxLength';
import { CustomNumberFormatDirective } from './api/directives/customNumberFormat';
import { AlgorithmInputProxy } from './api/generated/AlgorithmInputController';
import { NavigationExtenderService } from './services/navigation-extender.service';
import { ExecutionLogProxy } from './api/generated/ExecutionLogController';
import { NegotiationAnalysisProxy } from './api/generated/NegotiationAnalysisController';
import { NegotiationGuard } from './services/negotiation.guard';

export function ReferenceDataFactory(provider: ReferenceDataService) {
  return () => provider.init();
}

export function RunHelperFactory(provider: RunHelperService) {
  return () => provider.init();
}

@NgModule({
  declarations: [...MainComponents, ...SharedComponents, TruncatePipe, SearchFilterPipe, DateFormatPipe, ExtendedTranslatePipe, MaxLength, CustomNumberFormatDirective],
  imports: [
    PlatformRootModule.forRoot([
      { provide: RUN_PAGES, useValue: { routes: Routing.RUN_PAGES } },
      {
        provide: RUN_LIST_NAVIGATION, useValue: {
          runLinks: Config.RUN_LIST_NAVIGATION
        }
      }
    ]),
    ChartModule,
    ReactiveFormsModule,
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    MatAutocompleteModule,
    MatInputModule
  ],
  providers: [{
    provide: HighchartsStatic,
    useFactory: highchartsFactory,
  },
    ClientService, UploadDataProxy, ClientCoverageDataProxy, DateFormatPipe, NavbarExtenderService,
    UtilService, ReferenceDataService, ReferenceDataProxy, UltimateAnalysisProxy, RunHelperService,
    ValidationProxy, ExtendedTranslateService, AlgorithmInputProxy, NavigationExtenderService, ExecutionLogProxy, NegotiationAnalysisProxy,
  {
    provide: APP_INITIALIZER,
    useFactory: RunHelperFactory,
    deps: [RunHelperService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: ReferenceDataFactory,
    deps: [ReferenceDataService],
    multi: true
  }, HasRefDataGuard, NegotiationGuard,
  {
    provide: TranslateService,
    useExisting: ExtendedTranslateService
  },
  {
    provide: NavigationService,
    useExisting: NavigationExtenderService
  }
  ],
})
export class CommonModule { }
