

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'currency',
    templateUrl: 'currencyOveride.component.html'
})

export class CurrencyOverrideComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}