﻿
import * as Dto                          from '../dtos'; 
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable }                    from '@angular/core';
import { Observable }                    from 'rxjs';
import { ApiResult,BinaryTransferObject} from '@wtw/platform/api';
import { map }                           from  'rxjs/operators';
export { ApiResult };

@Injectable()
export class ReferenceDataProxy {
    	constructor(private _http: ProxyHttp) { 
        }
        
             
                getReferenceData () : Observable<ApiResult<Dto.ReferenceData>> {
                return this._http.get(`/backend/referenceData/all`,null||{}).pipe(map(ret=>({ data: ret.body, originalResponse: ret})));
	        }
                getIndustries () : Observable<ApiResult<Dto.IndustryGroup[]>> {
                return this._http.get(`/backend/referenceData/industries`,null||{}).pipe(map(ret=>({ data: ret.body, originalResponse: ret})));
	        }
                getCreditRatings () : Observable<ApiResult<Dto.SpCreditRating[]>> {
                return this._http.get(`/backend/referenceData/spCreditRatings`,null||{}).pipe(map(ret=>({ data: ret.body, originalResponse: ret})));
	        }
             
        
}

	
