﻿
import * as Dto from '../dtos';
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
export { ApiResult };

@Injectable()
export class UltimateAnalysisProxy {
    constructor(private _http: ProxyHttp) {
    }


    getData(runId: number): Observable<ApiResult<Dto.UltimateAnalysis[]>> {
        return this._http.get(`/backend/ultimateAnalysis/getData?runId=${runId}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }
    getCollateralYearData(runId: number): Observable<ApiResult<Dto.LobCollateralYearSummary[]>> {
        return this._http.get(`/backend/ultimateAnalysis/getCollateralYear?runId=${runId}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }
    prePopulateCarrierAssessmentData(run: Dto.RunViewModel): Observable<ApiResult<Dto.RunViewModel>> {
        return this._http.post(`/backend/ultimateAnalysis/prePopulateCarrierAssessmentData`, run || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }


}


