export enum ScenarioStateEnum {

    unselected,

    selected,

    edit,

    result,

    resultsSummary
}

