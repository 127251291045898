

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { MenuItem } from 'app/models/menuItem';
import * as componentTemplate from '@acl-cq-lib-pages-scenario-shared/tabsExtraMenu/tabsExtraMenu.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-shared/tabsExtraMenu/tabsExtraMenu.component.scss';

@Component({
    selector: 'app-tabs-extra-menu',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class TabsExtraMenuComponent implements OnInit {

    @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
    @Input() items: MenuItem[];
    public showDetails = false;


    constructor() { }

    ngOnInit() { }

    clicked(key: string) {
        this.showDetails = false;
        this.itemClicked.emit(key);
    }

    openMenu() {
        this.showDetails = !this.showDetails;
    }

    clear() {
        this.showDetails = false;
    }
}

