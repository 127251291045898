import { ScenarioStateEnum } from '../../models/index';
import { ScenarioService } from '../../services/scenario.service';
import { ScenarioProxy } from '../../api/generated/ScenarioController';
import { Component, OnDestroy } from '@angular/core';
import { ModalService, RunService, AlertService } from '@wtw/platform/services';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { TranslateService } from '@ngx-translate/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario/scenarios-analysis.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { IScenariosAnalysis } from '@acl-cq-lib-pages-scenario/IScenariosAnalysis';

@Component({
    selector: 'col-scenarios-analysis',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenariosAnalysisComponent extends ReactiveComponent implements OnDestroy, IScenariosAnalysis {

    public get ScenarioStateEnum(): typeof ScenarioStateEnum {
        return ScenarioStateEnum;
    }

    public showAlert: boolean = false;

    public get scenarioState(): ScenarioStateEnum {
        if (this.scenarioService.summaryResultsSelected) {
            return ScenarioStateEnum.resultsSummary;
        } else if (this.scenarioService.selectedScenario) {
            return this.scenarioService.selectedScenario.scenarioState;
        }
    }

    public get loaded(): boolean {
        return this.scenarioService.loaded;
    }

    private timer;

    constructor(private runService: RunService,
        private scenarioService: ScenarioService, private scenarioProxy: ScenarioProxy,
        private translateService: TranslateService, private modalService: ModalService, private alertService: AlertService
    ) {
        super();
    }

    ngOnInit(): void {
        this.scenarioService.setCurrentScenarios();

        this._subscriptions.push(this.alertService.getAlert().subscribe(alert => {
            if (alert && this.scenarioState !== ScenarioStateEnum.edit) {
                clearTimeout(this.timer);
                this.showAlert = true;
                this.timer = setTimeout(() => {
                    clearTimeout(this.timer);
                    this.showAlert = false;

                    // this timeout waits for the css slide animation to finish before calling alertService.clear();
                    this.timer = setTimeout(() => {
                        clearTimeout(this.timer);
                    }, 500);

                }, 5000);
            }

        }));
    }

    ngOnDestroy() {
        this.scenarioService.destroyCurrentSubscriptions();
    }
}
