import { Component, OnInit, Input } from '@angular/core';
import { IWarningBox } from '@acl-cq-lib-pages-negotiation/shared/warningBox/IWarningBox';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { NegotiationService } from 'app/services/negotiation.service';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/shared/warningBox/warningBox.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/shared/warningBox/warningBox.component.scss';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';

@Component({
    selector: 'app-negotiation-warning',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class WarningBoxComponent extends ReactiveComponent implements OnInit, IComponentTranslation, IWarningBox {

    @Input()
    set refresh(value) {
        this.setShowAllert();
    }

    translation = {};
    translationKey: any = 'COLLATERAL.NEGOTIATION.WARNINGBOX';
    showAlert: boolean;

    constructor(private _negotiationService: NegotiationService) {
        super();
    }

    ngOnInit() {
        this.setShowAllert();
        this._subscriptions.push(this._negotiationService.rFinishedEvent.subscribe(c => {
            if (c) {
                this.closeAlert();
            }
        }));
    }

    public setShowAllert() {
        this.showAlert = this._negotiationService.selectedCarrier.isRTriggered &&
            (this._negotiationService.hasRVersionChanged() || !this._negotiationService.selectedCarrier.showResults);
    }

    public runCalculation(): void {
        this._negotiationService.runCalculation('RFAILED.MESSAGE');
    }

    public closeAlert(): void {
        this.showAlert = false;
    }
}

