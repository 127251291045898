import { AfterViewInit } from '@angular/core/public_api';
import { Component, OnInit } from '@angular/core';
import { FormAwareNavigation, ReactiveFormSumission } from '@wtw/platform/interfaces';
import { FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { PercentValidator } from '../../../shared/validators/percent.validator';
import { RunService, ClientService, ModalService } from '@wtw/platform/services';
import { IndustryGroup, SpCreditRating, RunViewModel, ClientInformationInput, ReferenceData, Industry } from '../../../api/dtos';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RunModel } from '@wtw/platform/api/dtos';
import { Dto } from '@wtw/platform/api';
import * as moment from 'moment';
import { WeightingValidator } from '../../../shared/validators/weighting.validator';
import { DuplicatedIndustryValidator } from '../../../shared/validators/duplicated-industry.validator';
import { ReferenceDataService } from '../../../services/referenceData.service';
import { ChangeTracking as CT } from '@wtw/toolkit';
import * as BackendDto from '../../../api/dtos';
import { Subscription } from 'rxjs';

import * as componentTemplate from '@acl-cq-lib-pages-datainput/client-information/client-information.component.html';
import { IClientInformation } from '@acl-cq-lib-pages-datainput/client-information/IClientInformation';

@Component({
    selector: 'col-client-information',
    templateUrl: `${componentTemplate}`
})

export class ClientInformationComponent extends ReactiveComponent implements FormAwareNavigation, OnInit, ReactiveFormSumission, AfterViewInit, IClientInformation {
    translationKey = 'COLLATERAL.DATAINPUT.CLIENT_INFORMATION_PAGE';
    translation = {};
    parent?: FormAwareNavigation;
    rootForm: FormGroup = this.fb.group({
        'selectedIndustries': this.fb.array([], DuplicatedIndustryValidator.duplicated),
        'spCreditRatingId': null,
        'captiveInvolved': null,
        'renewalDate': ['', Validators.required]
    }, { validator: WeightingValidator.TotalPercent });
    referenceData: ReferenceData;
    industryList: IndustryGroup[];
    creditRatings: SpCreditRating[];
    captivesInvolved: CaptiveInvolved[] = [
        { boolValue: false, name: 'GLOBAL.NO', isDefault: true },
        { boolValue: true, name: 'GLOBAL.YES', isDefault: false }];
    currencyInfo: Dto.CurrencyInfo;
    runInformation: Dto.RunInfo;
    refDataSubscription: Subscription;
    activeRunSubscription: Subscription;
    currentSpCreditRating: SpCreditRating;
    defaultCreditRating: SpCreditRating;
    defaultCaptiveInvolved: CaptiveInvolved;
    currentRenewalDate: string = '';
    clientDetailsSubcription: Subscription;
    clientDetails: Dto.ClientDetails;
    currentClient: Dto.ClientData;


    @CT.TrackChanges() runViewModel: BackendDto.RunViewModel;
    @CT.ChangeTracker() changeTracker: CT.Changes;

    get industryArray(): FormArray {
        return this.rootForm.get('selectedIndustries') as FormArray;
    }

    constructor(private _run: RunService, private fb: FormBuilder, private referenceDataService: ReferenceDataService) {
        super();
    }

    ngOnInit(): void {
        this._subscriptions.push(this._run.activeRun
            .subscribe((r: RunModel) => {
                this.mapActiveRun(r);
                this.referenceData = this.referenceDataService.referenceData;
                this.initRefDataDefaults();
                this.SetRefData();
                this.initForm();
            }));
    }

    ngAfterViewInit() {
        this.changeTracker.commit();
    }

    mapActiveRun(run: RunModel) {
        this.currencyInfo = run.currencyInfo;
        this.runInformation = run.info;
        this.runViewModel = run.data;
    }

    createIndustryForm(industry) {
        return this.fb.group({
            id: [industry.id || '', Validators.required],
            weighting: [industry.weighting, [Validators.required, PercentValidator.range(1, 100)]]
        });
    }

    addNewIndustry(industry: Industry) {
        this.industryArray.push(this.createIndustryForm(industry));
    }

    //TODO:remove this industry parameter
    removeIndustry({ index }) {
        this.industryArray.removeAt(index);
    }

    prepareRunViewModel() {
        const aux = this.runViewModel.clientInformationInput.renewalDate;
        this.runViewModel.clientInformationInput = Object.assign({}, this.rootForm.value);
        this.runViewModel.clientInformationInput.renewalDate = aux;
    }

    setDate(value) {
        this.runViewModel.clientInformationInput.renewalDate = moment(value).format('DD MMM YYYY') as any;
    }


    private initRefDataDefaults() {
        this.creditRatings = this.referenceData.spCreditRatings;
        this.defaultCreditRating = this.creditRatings.find(cr => cr.isDefault);
        this.defaultCaptiveInvolved = this.captivesInvolved.find(c => c.isDefault);
        this.industryList = this.referenceData.industryGroups;
    }

    private SetRefData() {
        this.setSpCreditRating();
        this.setRenewalDate();
        this.setCaptivesInvolved();
    }

    private initForm() {
        if (!this.runViewModel.clientInformationInput) {
            this.runViewModel.clientInformationInput = <ClientInformationInput>{};
        }

        if (this.runViewModel.clientInformationInput.selectedIndustries && this.runViewModel.clientInformationInput.selectedIndustries.length) {
            this.clearIndustryForm();
            this.runViewModel.clientInformationInput.selectedIndustries.forEach(i => this.addNewIndustry(i));
        } else {
            this.createIndustryForm({});
        }
    }

    private clearIndustryForm() {
        while (this.industryArray.length !== 0) {
            this.industryArray.removeAt(0);
        }
    }

    private setSpCreditRating() {
        this.currentSpCreditRating = this.runViewModel.clientInformationInput.spCreditRatingId ?
            this.creditRatings.find(cr => cr.id === this.runViewModel.clientInformationInput.spCreditRatingId) :
            this.creditRatings.find(cr => cr.creditRating === this.defaultCreditRating.creditRating);

        this.runViewModel.clientInformationInput.spCreditRatingId = this.currentSpCreditRating.id;
    }

    private setRenewalDate() {
        this.currentRenewalDate = this.runViewModel.clientInformationInput.renewalDate ?
            moment(this.runViewModel.clientInformationInput.renewalDate).format('DD MMM YYYY') : '' as any;
    }

    private setCaptivesInvolved() {
        this.runViewModel.clientInformationInput
            .captiveInvolved = this.runViewModel.clientInformationInput.captiveInvolved ?
                this.runViewModel.clientInformationInput.captiveInvolved :
                this.captivesInvolved.find(c => c.isDefault).boolValue;
    }
}

export interface CaptiveInvolved {
    boolValue: boolean;
    name: string;
    isDefault: boolean;
}
