import { Component, OnInit, ViewChild } from '@angular/core';
import { NegotiationService } from 'app/services/negotiation.service';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/negotiationReport.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/negotiationReport.component.scss';
import { INegotiationReport } from '@acl-cq-lib-pages-negotiation/INegotiationReport';
import { InputSummaryComponent } from './inputSummary/inputSummary.component';
import { ModalService } from '@wtw/platform/services';

@Component({
    selector: 'app-negotiation-report',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle],
})

export class NegotiationReportComponent implements OnInit, INegotiationReport {

    @ViewChild(InputSummaryComponent) inputComponent: InputSummaryComponent;
    constructor(private _negotiationService: NegotiationService, private _modal: ModalService) { }

    canDeactivate() {
        if (this.inputComponent && this.inputComponent.aggregateComponent) {
            const hasUnsavedData = this.inputComponent.aggregateComponent.checkChanges();
            if (hasUnsavedData) {
                const confirmSettings = this.inputComponent.aggregateComponent.getConfirmSettings();
                const promise = this._modal.confirm(confirmSettings);
                return promise.then(x => x === false);
            }
        }
        return true;
    }

    ngOnInit() {
        this._negotiationService.init();
    }

    public get pageSelected() {
        return this._negotiationService.pageSelected;
    }
}
