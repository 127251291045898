
import { Component, OnInit, Input } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/collapsedRow/collapsedRow.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/collapsedRow/collapsedRow.component.scss';
import { ICarrierListTableCollapsedRow } from '@acl-cq-lib-pages-negotiation/carrierList/carrierListTable/collapsedRow/ICarrierListTableCollapsedRow';
import { NegotiationService } from 'app/services/negotiation.service';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';

@Component({
    selector: 'app-negotiation-carrier-list-table-collapsed-row',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CarrierListTableCollapsedRowComponent implements OnInit, IComponentTranslation, ICarrierListTableCollapsedRow {

    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.CARRIERLISTTABLECOLLAPSEDROW';

    @Input()
    row: any;

    constructor(private _negotiationService: NegotiationService) { }

    ngOnInit() {
    }
    public moreDetails() {
        this._negotiationService.collapseAllCarrierList();
        this.row.collapsed = false;
    }
}

