import { CarrierDetails, Security, CollateralRampUp, CarrierCoverageData, Projection, PaidLossCreditData, Scenario, Coverage, ExposureGrowth, PaidLossCreditType } from '../../../models/index';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ReferenceDataService } from '../../../services/referenceData.service';
import { Component, OnInit } from '@angular/core';
import { ScenarioService } from '../../../services/scenario.service';
import { TranslateService } from '@ngx-translate/core';
import { IStandardModalConfig, IButtonConfig } from '@wtw/platform/interfaces';
import { ModalService, AlertService } from '@wtw/platform/services';
import { CollateralRampUpValidator } from 'app/shared/validators/collateralRampUp.validator';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';
import { IScenarioEdit } from '@acl-cq-lib-pages-scenario-edit/IScenarioEdit';

@Component({
    selector: 'app-scenario-edit',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle],
    providers: [AlertService]
})

export class ScenarioEditComponent implements OnInit, IScenarioEdit {
    public parentForm: FormGroup;
    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT';
    public translation: any = {};
    public isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    private projectionPeriod = this._scenarioService.selectedScenario.projectionPeriod;

    private get riskTypes() {
        return this._refeData.referenceData.riskTypes;
    }

    private get currentScenario(): Scenario {
        return this._scenarioService.selectedScenario;
    }

    public get scenarioName() {
        return this._scenarioService.selectedScenario.scenarioName;
    }

    public get showError() {
        return (this._scenarioService.selectedScenario.isSaved || this._scenarioService.selectedScenario.isRTriggered) && !this._scenarioService.selectedScenario.isValid;
    }

    constructor(private _scenarioService: ScenarioService, private _refeData: ReferenceDataService,
        private _translateService: TranslateService, private _fb: FormBuilder, private _modalService: ModalService,
        private _alertService: AlertService) {
    }

    ngOnInit() {
        this.parentForm = this._fb.group({
            carriers: this.createCarriersArray(),
            commonAssumptions: this._fb.group({
                discountRate: this.currentScenario.commonAssumptions.discountRate,
                incumbentLegacyPLCType: this.currentScenario.commonAssumptions.incumbentLegacyPLCType,
                incumbentLegacyPLC: this.currentScenario.commonAssumptions.incumbentLegacyPLC,
                walkawayCollateral: this.currentScenario.commonAssumptions.walkawayCollateral,
            }),
            coverageAssumptions: this.createCoverageAssumptionsArray(),
            projectionPeriod: this.currentScenario.projectionPeriod,
            comparisonType: this._carrierType,
            coverageDisplayData: this.createCoverageDisplayDataArray(),
        });

        if (this.isIEOrEdge) {
            window['console']['log'] = _ => { };
        }

    }


    ngAfterViewInit() {
        Promise.resolve(null).then(c => {
            this._alertService.warn(this._translateService.instant(this.translationKey + '.WARNINGMESSAGE'));
        });
    }

    public save() {
        this.saveScenarioForm();
        this._scenarioService.selectedScenario.isSaved = true;
        this._scenarioService.save(this.parentForm.valid);
    }


    public cancel() {
        if (!this.parentForm.pristine) {
            this.cancelChangesToCurrentScenario();
        } else {
            this._scenarioService.closeScenarioEdit();
        }
    }

    private get _carrierType(): string {
        const carriers = this._scenarioService.selectedScenario.carriers;
        const isIncumbent = typeof carriers.find(carriers => carriers.typeOfCarrier === 0) !== 'undefined';
        return isIncumbent ? '0' : '1';
    }

    private createCoverageDisplayDataArray(): FormArray {
        const formArray: FormArray = this._fb.array(
            this._scenarioService.riskTypeList().map(c => {
                return this._fb.group({
                    riskTypeId: c.id,
                    isDisabled: !this._scenarioService.selectedScenario.coverageDisplayData[c.id].isDisabled
                });
            })
        );

        return formArray;
    }

    private createCarriersArray(): FormArray {
        const carriersFormArray: FormArray = this._fb.array([]);
        let carriers = this.currentScenario.carriers as CarrierDetails[];

        carriers.forEach(carrier => {
            carriersFormArray.push(this._fb.group({
                carrierOrderId: carrier.carrierOrderId,
                scenarioCarrierId: carrier.scenarioCarrierId,
                name: carrier.name,
                typeOfCarrier: carrier.typeOfCarrier,
                plcType: carrier.plcType,
                collateralCostRate: this.createCollateralCostRate(carrier),
                collateralRampUps: this.createRampUps(carrier.collateralRampUps),
                carrierCoverageData: this.createCarrierCoverageData(carrier.carrierCoverageData),
                projections: this.createProjections(carrier.projections)
            }));
        });

        return carriersFormArray;
    }

    private createCoverageAssumptionsArray(): FormArray {
        const coverageAssumptionsFormArray: FormArray = this._fb.array([]);
        const coverages = this._scenarioService.riskTypeList().map(c => this.currentScenario.coverageAssumptions.find(d => d.riskTypeId === c.id));

        coverages.forEach(coverage => {
            coverageAssumptionsFormArray.push(this._fb.group({
                riskTypeId: coverage.riskTypeId,
                deductible: [coverage.deductible, [Validators.required, Validators.min(0)]],
                limit: [coverage.limit, [Validators.required, Validators.min(0)]],
                legacyUltimateEstimate: [coverage.legacyUltimateEstimate, [Validators.required, Validators.min(0)]],
                riskTypeExposureGrowths: this.createExposureGrowths(coverage.riskTypeExposureGrowths)
            }));
        });

        return coverageAssumptionsFormArray;
    }

    private createExposureGrowths(exposureGrowths: ExposureGrowth[]): FormArray {
        const expousreGrowthsFormArray: FormArray = this._fb.array([]);

        exposureGrowths.forEach(exposureGrowth => {
            expousreGrowthsFormArray.push(this._fb.group({
                year: exposureGrowth.year,
                riskTypeId: exposureGrowth.riskTypeId,
                value: [exposureGrowth.value, [Validators.required, Validators.min(0), Validators.max(100)]]
            }));
        });

        return expousreGrowthsFormArray;
    }

    private createCollateralCostRate(carrier: CarrierDetails): FormGroup {
        const collateralCostRateFormGroup: FormGroup = this._fb.group([]);

        collateralCostRateFormGroup.addControl('weightTotal', this._fb.control(carrier.collateralCostRate.weightTotal, [Validators.min(100), Validators.max(100)]));
        collateralCostRateFormGroup.addControl('costAverage', this._fb.control(carrier.collateralCostRate.costAverage, [Validators.min(0)]));
        collateralCostRateFormGroup.addControl('securities', this.createSecurities(carrier.collateralCostRate.securities));

        return collateralCostRateFormGroup;
    }

    private createSecurities(securities: Security[]): FormArray {
        const securitiesFormArray: FormArray = this._fb.array([]);

        securities.forEach(security => {
            securitiesFormArray.push(this._fb.group({
                securityType: security.securityType,
                weight: [security.weight, [Validators.required, Validators.min(0), Validators.max(100)]],
                cost: [security.cost, [Validators.required, Validators.min(0), Validators.max(100)]]
            }));
        });

        return securitiesFormArray;
    }

    private createRampUps(rampUps: CollateralRampUp[]): FormArray {
        const rampUpsFormArray: FormArray = this._fb.array([]);

        rampUps.forEach(rampUp => {
            rampUpsFormArray.push(this._fb.group({
                day: [rampUp.day, [Validators.required, Validators.min(1),
                Validators.max(this.projectionPeriod * 365),
                CollateralRampUpValidator(rampUpsFormArray.length, rampUpsFormArray)]],
                collateralRequired: [rampUp.collateralRequired, [Validators.required, Validators.min(0)]]
            }));
        });

        return rampUpsFormArray;
    }

    private createCarrierCoverageData(coverageData: CarrierCoverageData[]): FormArray {
        const coverageDataFormArray: FormArray = this._fb.array([]);

        const coverageDataList = this._scenarioService.riskTypeList().map(c => coverageData.find(d => d.riskTypeId === c.id));

        coverageDataList.forEach(coverage => {
            coverageDataFormArray.push(this._fb.group({
                scenarioCarrierId: coverage.scenarioCarrierId,
                riskTypeId: coverage.riskTypeId,
                premium: [coverage.premium, [Validators.required, Validators.min(0)]],
                selectedUltLoss: [coverage.selectedUltLoss, [Validators.required, Validators.min(0)]],
                costRateTrend: [coverage.costRateTrend, [Validators.required, Validators.min(0)]]
            }));
        });

        return coverageDataFormArray;
    }

    private createProjections(projections: Projection[]): FormArray {
        const projectionsFormArray: FormArray = this._fb.array([]);

        projections.forEach(projection => {
            projectionsFormArray.push(this._fb.group({
                year: projection.year,
                plcData: this.createPlcData(projection.plcData)
            }));
        });

        return projectionsFormArray;
    }

    private createPlcData(plcData: PaidLossCreditData): FormGroup {
        const plcDataFormGroup: FormGroup = this._fb.group([]);

        plcDataFormGroup.addControl('monetaryValue', this._fb.control(plcData.monetaryValue, [Validators.required, Validators.min(0)]));
        plcDataFormGroup.addControl('monthsValue', this._fb.control(plcData.monthsValue, [Validators.required, Validators.min(0), Validators.max(12 * this.projectionPeriod)]));
        plcDataFormGroup.addControl('percentageValue', this._fb.control(plcData.percentageValue, [Validators.required, Validators.min(0), Validators.max(100)]));

        return plcDataFormGroup;
    }

    private saveScenarioForm() {
        this._scenarioService.selectedScenario.carriers = (this.parentForm.get('carriers') as FormArray).getRawValue();
        this._scenarioService.selectedScenario.commonAssumptions = (this.parentForm.get('commonAssumptions') as FormGroup).getRawValue();
        this._scenarioService.selectedScenario.coverageAssumptions = (this.parentForm.get('coverageAssumptions') as FormArray).getRawValue();
        (this.parentForm.get('coverageDisplayData') as any).controls.forEach(c => {
            this._scenarioService.selectedScenario.coverageDisplayData[c.controls.riskTypeId.value].isDisabled = !c.controls.isDisabled.value;
        });

        this._scenarioService.selectedScenario.projectionPeriod = this.parentForm.get('projectionPeriod').value;
    }

    private cancelChangesToCurrentScenario() {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('GLOBAL.NO'),
            cssClass: 'btn btn-primary'
        };

        const noConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('GLOBAL.YES')
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this._translateService.instant(this.translationKey + '.CANCEL.TITLE'),
            withHeader: false,
            message: this._translateService.instant(this.translationKey + '.CANCEL.MESSAGE'),
            yesButton: yesConfig,
            noButton: noConfig
        };
        this._modalService.confirm(confirmConfig).then(
            (c) => {
                if (!c) {
                    this._scenarioService.closeScenarioEdit();
                }
            }
        );
    }
}
