import { Component, OnInit } from '@angular/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/scenarioEditHeader/scenarioEditHeader.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';

@Component({
    selector: 'app-scenario-edit-header',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioEditHeaderComponent implements OnInit {
    public translation: any = {};
    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT';
    constructor() { }

    ngOnInit() {
    }

}
