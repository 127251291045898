import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PercentValidator {
    static range(min: number, max: number) : ValidatorFn {
        return (c: AbstractControl): {[key: string]: boolean} | null => {
            if (c.value && (isNaN(c.value) || c.value < min || c.value > max) || !this.isInteger(c.value)) {
                return { 'range' : true };
            }
            return null;
        };
    }

    private static isInteger(value): boolean {
        return value && parseFloat(value) === parseInt(value.toString(), 10);
    }
}
