
import * as Dto from '../dtos';
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
export { ApiResult };

@Injectable()
export class ExecutionLogProxy {
    constructor(private _http: ProxyHttp) {
    }


    saveExecutionLogs(logs: Dto.ExecutionLog[]): Observable<ApiResult<void>> {
        return this._http.post(`/backend/executionLog/bulkSave`, logs || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }


}


