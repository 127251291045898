import { TranslateService, TranslateStore, TranslateLoader, TranslateCompiler, TranslateParser, MissingTranslationHandler } from '@ngx-translate/core';
import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { ShowTranslationComponent } from 'app/pdf/sharedComponents/showTranslation/showTranslation.component';

@Injectable()
export class ExtendedTranslateService extends TranslateService {
    localisationEnabled: boolean = true;
    lastUpdated: number;
    missingTranslation: string[] = [];
    missingTranslationKeys: string[] = [];

    private _translate: { [key: string]: any } = {};
    private objectCreated: boolean = false;
    constructor(store: TranslateStore, currentLoader: TranslateLoader, compiler: TranslateCompiler, parser: TranslateParser, missingTranslationHandler: MissingTranslationHandler,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
        super(store, currentLoader, compiler, parser, missingTranslationHandler, false, false);
    }

    instant(key: string | Array<string>, interpolateParams?: Object): string | any {
        if (!this.objectCreated) {
            this.objectCreated = true;
            this.appendComponentToBody(ShowTranslationComponent);
        }
        let translate = super.instant(key, interpolateParams);
        if (typeof key === 'string') {
            if (key === translate) {
                if (key === key.toUpperCase()) {
                    this.pushValue(this.missingTranslation, key);
                } else {
                    this.pushValue(this.missingTranslationKeys, key);
                }
            } else {
                const keySplit = key.split('.');
                let currentObj = this._translate;
                keySplit.forEach((d, i) => {
                    if (currentObj[d]) {
                        currentObj = currentObj[d];
                    } else {
                        if (i === keySplit.length - 1) {
                            currentObj[d] = translate;
                            this.lastUpdated = Date.now();
                        } else {
                            currentObj[d] = {};
                            currentObj = currentObj[d];
                        }
                    }
                });
            }
        }
        if (!this.localisationEnabled) {
            translate = 'DISABLED.KEY';
        }
        return translate;
    }
    pushValue(array: string[], key: string): any {
        if (array.find(c => c === key) === undefined) {
            array.push(key);
            array.sort((a, b) => (a > b ? 1 : -1));
        }
    }

    public get translation() {
        return this._translate;
    }

    public clearTranslation() {
        this._translate = {};
    }

    public disableEnableLocalisation(value) {
        this.localisationEnabled = value;
    }

    appendComponentToBody(component: any) {
        if (!this.objectCreated) {
            this.objectCreated = true;
            this.appendComponentToBody(ShowTranslationComponent);
        }
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);

        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        // Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.body.appendChild(domElem);
    }


}
