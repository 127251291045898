﻿
import * as Dto from '../dtos';
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
export { ApiResult };

@Injectable()
export class ScenarioProxy {
    constructor(private _http: ProxyHttp) {
    }


    createScenarioId(runId: number): Observable<ApiResult<number>> {
        return this._http.post(`/backend/scenario/createScenarioId/${runId}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }
    createScenarioCarrierId(runId: number, scenarioId: number, carrierOrderId: number): Observable<ApiResult<number>> {
        return this._http.post(`/backend/scenario/createScenarioCarrierId/${runId}/${scenarioId}/${carrierOrderId}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }
    createScenario(runViewModel: Dto.RunViewModel, runId: number): Observable<ApiResult<Dto.ScenariosModel>> {
        return this._http.post(`/backend/scenario/create/${runId}`, runViewModel || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }
    getScenarioResult(runId: number, scenarioId: number): Observable<ApiResult<Dto.ScenarioResult>> {
        return this._http.get(`/backend/scenario/scenarioResult/${runId}/${scenarioId}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }
    removeScenario(runId: number, scenarioId: number): Observable<ApiResult<boolean>> {
        return this._http.get(`/backend/scenario/removeScenario/${runId}/${scenarioId}`, null || {}).pipe(map(ret => ({ data: ret.body, originalResponse: ret })));
    }


}


