import { NumberFormatDirective } from '@wtw/toolkit/src/directives/number-format.directive';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { forwardRef, ExistingProvider, Directive, Input, Renderer, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forEach } from '@angular/router/src/utils/collection';

const CUSTOM_VALUE_ACCESSOR: ExistingProvider = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomNumberFormatDirective), multi: true };

@Directive({
    selector: 'input[customNumberFormat]',
    host: { '(blur)': 'blurred($event.target.value)' },
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class CustomNumberFormatDirective extends NumberFormatDirective implements OnInit, ControlValueAccessor {
    @Input() customNumberFormat: string;
    @Input('type') inputType: string;
    constructor(private renderer: Renderer, private elementRef: ElementRef, private translate: TranslateService) {
        super(renderer, elementRef, translate);
    }

    ngOnInit() {
        const aux = this.customNumberFormat.split(';');
        if (aux.find(c => c.indexOf('number:') === 0)) {
            this.format = aux.find(c => c.indexOf('number:') === 0);
        }
        super.ngOnInit();
    }

    onChange = (_: any) => { };
    onTouched = () => { };
    blurred(value: any) {
        this.onChange(value);
        this.onTouched();
    }

    registerOnChange(fn: (_: number) => void): void {
        this.onChange = (value) => {
            fn(value === '' ? null : this.parseToNumber(value));
        };
    }

    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

    formatToString(value): string {
        const aux = this.customNumberFormat.split(';').filter(c => c.indexOf('number:') !== 0);
        for (let i = 0; i < aux.length; i++) {
            let regex = new RegExp(`^${aux[i]}$`);
            if (regex.test(value)) {
                return value as any;
            }
        }
        try {
            return super.formatToString(value);
        } catch (e) {
            return '';
        }
    }

    parseToNumber(value: string): number {
        const aux = this.customNumberFormat.split(';').filter(c => c.indexOf('number:') !== 0);
        for (let i = 0; i < aux.length; i++) {
            let regex = new RegExp(`^${aux[i]}$`);
            if (regex.test(value)) {
                return value as any;
            }
        }
        try {
            return super.parseToNumber(value);
        } catch (e) {
            this.writeValue(undefined);
            return undefined;
        }
    }
}