import { Component, AfterViewInit, Renderer2, OnInit } from '@angular/core';
import { SiteHeaderService, RunService, NavigationService } from '@wtw/platform/services';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { Router, NavigationEnd } from '@angular/router';
@Component({
    selector: 'dom-manipulator',
    templateUrl: './navbar-extender.component.html'
})
/* This component is to be appended to the body so it can detect
 the Angular Lifecycle so when the view is loaded is can manipulate
 the DOM.
 */
export class NavbarExtenderComponent implements AfterViewInit, OnInit {

    constructor(private _siteHeader: SiteHeaderService, private _renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
    }

    public manipulateNavBar() {
        this._siteHeader.pageList.forEach(page => {
            const navItem = document.querySelector(`[ng-reflect-router-link$='${page.identifier}']`);

            if (navItem) {
                if (page.showRunNavigation) {
                    this._renderer.removeAttribute(navItem, 'style');
                } else {
                    this._renderer.setAttribute(navItem, 'style', 'display:none');
                }
            }
        });
        return true;
    }

}