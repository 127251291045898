import { FormGroup, FormArray } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ScenarioService } from '../../../../services/scenario.service';
import { ReferenceDataService } from 'app/services/referenceData.service';
import { LossCreditType, PaidLossCreditType, CoverageName, CarrierType } from 'app/api/dtos';
import * as Dto from '../../../../api/dtos';
import { Validators } from '@angular/forms';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/projection/ScenarioProjection.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';
import { IScenarioProjectionEdit } from '@acl-cq-lib-pages-scenario-edit/projection/IScenarioProjectionEdit';

@Component({
    selector: 'app-scenario-projection',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioProjectionComponent extends ReactiveComponent implements IScenarioProjectionEdit {

    @Input() form: FormGroup;

    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT.SCENARIO_PROJECTION';
    public translation: any = {};

    public riskTypes: any[];

    public get projectionPeriod(): number {
        return this.form.get('projectionPeriod').value;
    }
    public get CreditType(): typeof PaidLossCreditType {
        return PaidLossCreditType;
    }
    public get isIncumbentComparison(): boolean {
        return +this.form.get('comparisonType').value === CarrierType.incumbent;
    }

    public get projectionPeriodList(): number[] {
        return this._refData.referenceData.scenarioPeriodList.map(o => o.noOfyears);
    }
    public get lossCreditTypesList(): LossCreditType[] {
        return this._refData.referenceData.lossCreditTypes;
    }

    public get carriers(): FormArray {
        return this.form.get('carriers') as FormArray;
    }

    public get coverageAssumptions(): FormArray {
        return this.form.get('coverageAssumptions') as FormArray;
    }

    public get displayPercentage() {
        const coverageLength = this.riskTypeList.filter(c => this.scenarioCoverageDisplayData[c.id]).length;
        if (coverageLength === 1) {
            return 65 / coverageLength / 2;
        } else {
            return 80 / coverageLength / 2;
        }
    }

    public get projectionsList(): number[] {
        return this._projectionsList;
    }

    public get displayCoveragePercentage() {
        return (85 - this.displayPercentage * 2) / this.riskTypeList.filter(c => this.scenarioCoverageDisplayData[c.id]).length;
    }

    private _projectionsList: number[] = [];

    constructor(private _scenarioService: ScenarioService, private _refData: ReferenceDataService) {
        super();
    }

    ngOnInit(): void {
        this.createProjectionsList();

        if (!this.riskTypes) {
            this.createRiskTypes();
        }

        if (this._scenarioService.selectedScenario.isSaved || this._scenarioService.selectedScenario.isRTriggered) {
            this._scenarioService.markFormGroupTouched(this.form);
        }

        this._subscriptions.push(this.form.get('projectionPeriod').valueChanges.subscribe(c => {
            this.createProjectionsList();
            this.updateValidators(c);
        }));

        if (this._scenarioService.selectedScenario.isSaved || this._scenarioService.selectedScenario.isRTriggered) {
            // this._scenarioService.markFormGroupTouched(this.form);
            // this._scenarioService.markFormArrayTouched(this.carriers);
        }
    }

    public getPLCSymbol(plcType: number): string {
        return this._refData.referenceData.lossCreditTypes.find(r => +r.id === +plcType).abbreviation;
    }

    public isCoverageDisabled(coverageId: number): boolean {
        return !(this.form.get('coverageDisplayData') as any).controls[coverageId].get('isDisabled').value;
    }
    public get riskTypeList(): Dto.RiskType[] {
        return this._refData.referenceData.riskTypes.sort((a, b) => { return a.displayOrderId - b.displayOrderId; });
    }

    public get scenarioCoverageDisplayData(): { [key: number]: CoverageName; } {
        return this._scenarioService.selectedScenario.coverageDisplayData;
    }

    private createProjectionsList(): void {
        const selectedProjectionPeriod = this.form.get('projectionPeriod').value;
        this._projectionsList = [];

        for (let i = 0; i < selectedProjectionPeriod; i++) {
            this._projectionsList.push((this.carriers.controls[0].get('projections') as FormArray).controls[i].value.year);
        }
    }

    private createRiskTypes(): void {
        const formArr = this.form.get('coverageDisplayData') as FormArray;

        this.riskTypes = formArr.controls.map(c => {
            const aux = c.value;
            aux.isDisabled = !aux.isDisabled;
            return aux;
        });
    }

    private updateValidators(c): void {
        this.carriers.controls.forEach((carrierControl) => {
            (carrierControl.get('projections') as FormArray).controls.forEach(projectionControl => {
                const plcDataFormGroup = projectionControl.get('plcData') as FormGroup;
                plcDataFormGroup.controls.monthsValue.setValidators([Validators.required, Validators.min(0), Validators.max(12 * +c)]);
                plcDataFormGroup.controls.monthsValue.updateValueAndValidity();
            });
        });
    }
}
