import { FormBuilder, FormGroup } from '@angular/forms';
import { ScenarioService } from '../../../../services/scenario.service';
import { ScenarioProxy } from '../../../../api/generated/ScenarioController';
import { ReferenceDataService } from '../../../../services/referenceData.service';
import { Component, OnInit } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RunService } from '@wtw/platform/services';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/scenarioSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';

@Component({
    selector: 'app-scenario-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioSummaryComponent extends ReactiveComponent implements OnInit {
    testForm: FormGroup;

    constructor(private _scenarioService: ScenarioService, private _runService: RunService, private _scenarioProxy: ScenarioProxy,
        private _referenceDataService: ReferenceDataService, private _numberFormat: DecimalPipe, private _translation: TranslateService, private _fb: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.testForm = this._fb.group({
            name: [{ value: '', disabled: true }]
        });
    }

    toggle() {
        let control = this.testForm.get('name');
        control.disabled ? control.enable() : control.disable();
    }

}
