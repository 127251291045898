import { Component, OnInit } from '@angular/core';
import { ScenarioService } from 'app/services/scenario.service';
import { ModalService } from '@wtw/platform/services';
import { TranslateService } from '@ngx-translate/core';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/scenarioSummaryFooter/scenarioSummaryFooter.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSummaryFooter/scenarioSummaryFooter.component.scss';
import { IScenarioSummaryFooter } from '@acl-cq-lib-pages-scenario-summary/scenarioSummaryFooter/IScenarioSummaryFooter';

@Component({
    selector: 'app-scenario-summary-footer',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioSummaryFooterComponent implements OnInit, IScenarioSummaryFooter {

    public translationKey: string = 'COLLATERAL.SCENARIOS';
    public translation: any = {
        APPLYCHANGES: 'Run Scenario',
        COPYSCENARIO: 'Copy Scenario',
        REMOVESCENARIO: 'Remove Scenario',
    };

    public get scenariosLength() {
        if (this.scenarioService && this.scenarioService.scenarios && this.scenarioService.scenarios.length)
            return this.scenarioService.scenarios.length;
        else {
            return 0;
        }
    }

    private get currentScenario() {
        return this.scenarioService.selectedScenario;
    }

    constructor(private scenarioService: ScenarioService, private modalService: ModalService,
        private translateService: TranslateService) { }

    ngOnInit() { }

    createTrigger() {
        if (!this.scenarioService.selectedScenario.isValid) {
            this.showRunTriggerErrorModal();
        }

        this.scenarioService.processScenario();
    }

    removeCurrentScenario() {
        this.scenarioService.removeCurrentScenarioPopUp(this.translationKey);
    }

    copyCurrentScenario() {
        this.scenarioService.copyScenario();
    }

    private showRunTriggerErrorModal() {
        const okConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant('GLOBAL.OK'),
            cssClass: 'btn btn-primary'
        };


        const confirmConfig = <IStandardModalConfig>{
            title: this.translateService.instant(this.translationKey + '.TRIGGERERROR.TITLE1') + ' ' + this.currentScenario.name + ' '
                + this.translateService.instant(this.translationKey + '.TRIGGERERROR.TITLE2'),
            withHeader: false,
            message: this.translateService.instant(this.translationKey + '.TRIGGERERROR.MESSAGE'),
            yesButton: okConfig,
            noButton: { display: false }
        };

        this.modalService.confirm(confirmConfig);
    }
}