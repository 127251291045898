
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UploadFileComponent } from '@wtw/platform/components';
import { UploadErrorType } from '@wtw/platform/models/upload-error-type';

@Component({
    selector: 'app-upload-file-extender',
    templateUrl: '../../../../../../node_modules/@wtw/platform/components/upload-file/upload-file.component.html'
})

export class UploadFileExtenderComponent extends UploadFileComponent implements OnInit {
    @Output()
    errorType = new EventEmitter<any>();

    constructor() { super(); }

    ngOnInit() {
        this.watchDropFiles();
    }

    watchDropFiles() {
        this.uploader.onAfterAddingFile = (fileItem) => {
            if (this.uploader.queue.length <= this.maxFiles) {
                if (this.acceptedFileTypes.indexOf(fileItem.file.type) !== -1) {
                    this.uploadInProgress = true;
                    this.upload.emit(fileItem._file);
                } else {
                    this.errorType.emit({ error: UploadErrorType.invalidFileFormat, fileName: fileItem._file.name });
                }
            } else {
                this.errorType.emit({ error: UploadErrorType.fileLimitExceeded, fileName: fileItem._file.name });
            }
            setTimeout(() => { this.uploader.queue.length = 0; }, 500);
            this.fileInput.nativeElement.value = '';
        };
    }
}

