
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/inputSummary/carrierOverView/carrierOverView.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/inputSummary/carrierOverView/carrierOverView.component.scss';
import { ICarrierOverView } from '@acl-cq-lib-pages-negotiation/inputSummary/carrierOverView/ICarrierOverView';
import { NegotiationService } from 'app/services/negotiation.service';
import { CarrierComparisonType } from 'app/models/CarrierComparisonType';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { RunViewModel, CarrierStatus } from 'app/api/generated/RunViewModel';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-input-summary-carrier-over-view',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CarrierOverViewComponent extends ReactiveComponent implements OnInit, IComponentTranslation, ICarrierOverView {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.CARRIEROVERVIEW';

    public tableSections = [
        {
            key: 'ultimateValues',
            data: [{
                key: 'total',
                translation: 'TOTALULTIMATE',
                class: 'bold-text'
            },
            {
                key: 'completeYear',
                translation: 'COMPLETEDYEARS',
                class: 'sub-heading'
            },
            {
                key: 'inForceYear',
                translation: 'INFORCEYEARS',
                class: 'sub-heading'
            },
            {
                key: 'lossForecast',
                translation: 'LOSSFORECAST',
                class: 'sub-heading'
            },
            ]
        },
        {
            key: 'paidValues',
            data: [{
                key: 'total',
                translation: 'TOTALPAID',
                class: 'bold-text'
            },
            {
                key: 'actualPaidAtRenewal',
                translation: 'ACTUALPAIDATRENEWAL',
                class: 'sub-heading'
            },
            {
                key: 'expectedAdditionalPaid',
                translation: 'EXPECTEDADDITIONALPAID',
                class: 'sub-heading'
            }]
        },
        {
            key: 'adjustmentValues',
            data: [{
                key: 'total',
                translation: 'TOTALADJUSTMENTS',
                class: 'bold-text'
            },
            {
                key: 'paidLossCredit',
                translation: 'PAIDLOSSCREDIT',
                class: 'sub-heading'
            },
            {
                key: 'otherCollateralAdjustments',
                translation: 'OTHERCOLLATERALADJUSTMENTS',
                class: 'sub-heading'
            }]
        }
    ];
    public carrierSummmary: any;
    public model: any = {};
    public refresh = {};
    public isAssessmentChartModalVisible = false;
    currentRenewalDate: any;
    currentCollateralHeldValue: number = 0;
    readonly: boolean;
    form: FormGroup;

    @Output()
    lobInputs: EventEmitter<any> = new EventEmitter();
    previousRenewlDate: any;
    disabledDates: any = [];
    minDate: any;
    showFirst: boolean = true;
    showSecond: boolean = false;

    constructor(private _negotiationService: NegotiationService, private _fb: FormBuilder, private _translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this._negotiationService.showChart = false;
        this.initCarrierOverview();
        this._subscriptions.push(this._negotiationService.rFinishedEvent.subscribe(c => {
            this.initCarrierOverview();
        })
        );
    }

    public get CarrierComparisonType() {
        return CarrierComparisonType;
    }

    public get carrierName(): any {
        return this._negotiationService.selectedCarrier.carrierName;
    }

    public get showResults() {
        return this._negotiationService.selectedCarrier.showResults;
    }

    public selectAggregate(carrierComparisonType) {
        this.lobInputs.emit(this._negotiationService.selectedCarrier.carrierAssessmentInputs.find(c => c.carrierComparisonType === carrierComparisonType));
    }

    public updateCollateralHeldValue() {
        if (isNaN(this.form.value.collateralHeld) || (this.form.value.collateralHeld === null && this._negotiationService.selectedCarrier.collateralHeld !== null)) {
            this._negotiationService.selectedCarrier.collateralHeld = null;
            this.currentCollateralHeldValue = null;
            this._negotiationService.save();
        } else if (this._negotiationService.selectedCarrier.collateralHeld !== +this.form.value.collateralHeld) {
            this._negotiationService.selectedCarrier.collateralHeld = +this.form.value.collateralHeld;
            this.currentCollateralHeldValue = +this.form.value.collateralHeld;
            this._negotiationService.save();
        }
    }

    public setDate(value) {
        if (value !== this.previousRenewlDate && value && value instanceof Date && !isNaN(value as any)) {
            if (Math.abs(moment(this.previousRenewlDate).diff(moment(value), 'days')) < 30 || moment(value).diff(moment(this._negotiationService.selectedCarrier.evalDate)) < 0) {
                this.currentRenewalDate = this.previousRenewlDate;
                this.swapDatePickers();
            } else {
                this.previousRenewlDate = this.currentRenewalDate;
                this._negotiationService.selectedCarrier.negotiationPolicyRenewalDate = moment(value).format('YYYY-MM-DD') as any;
                this._negotiationService.selectedCarrier.showResults = false;
                this._negotiationService.clearCarrierResults();
                this.setDisabledDate();
                this.swapDatePickers();
                this._negotiationService.save();
                this.refresh = {};
            }
        } else if (value instanceof Date && isNaN(value as any)) {
            this.currentRenewalDate = this.previousRenewlDate;
            this.swapDatePickers();
        }
    }

    downloadData() {
        let downloadData = {};
        const rules = JSON.parse(JSON.stringify(this._translateService.instant(`COLLATERAL.NEGOTIATION.RULES.RULESDROPDOWN`)));
        let index = 1;
        const
            squasheRules = rules.DATA.map(c =>
                c.DATA.map(d => {
                    d.id = index;
                    d.displayName = `${rules.RULEDISPLAY} ${index++}`;
                    return d;
                })).flatten();
        downloadData['Rule Message'] = [];
        squasheRules.forEach(sr => {
            let c = this._negotiationService.selectedCarrier.carrierAssessmentOutputs[0].negotiationRules.find(d => +d.ruleId === +sr.KEY);
            c.lobRuleData.forEach(dd => {
                let d = JSON.parse(JSON.stringify(dd));
                if (d && d.ruleData && d.header) {
                    let data = [];
                    d.header.push('flag');
                    d.header.forEach(e => {
                        d.ruleData[e] = [d.ruleData[e]].flatten();
                    });
                    d.ruleData[d.header[0]].forEach((e, i) => {
                        let item = {};
                        d.header.forEach(f => {
                            item[this._translateService.instant(`COLLATERAL.NEGOTIATION.RULES.RULESTABLE.${f}`)] = d.ruleData[f][i];
                        });
                        data.push(item);
                    });
                    downloadData[`${sr.id}_${this._negotiationService.riskTypes[d.riskType].shortDescription}`] = data;
                }
                downloadData['Rule Message'].push(
                    {
                        'Rule': `${sr.id}`,
                        'LOB': `${this._negotiationService.riskTypes[d.riskType].shortDescription}`,
                        'Message': this._translateService.instant(`COLLATERAL.NEGOTIATION.RULES.RULESTABLE.${c.ruleId}.${d.localisationKey}`, d.localisationOverride)
                    }
                );
            });
        });
        this._negotiationService.downloadRulesExtract(downloadData);
    }

    public toggleAssessmentModal(event): void {
        this.isAssessmentChartModalVisible = !this.isAssessmentChartModalVisible;
        event.stopPropagation();
    }

    public get showSummary() {
        return !this._negotiationService.showChart;
    }


    private setDisabledDate(): any {
        this.disabledDates = [];
        for (let i = -30; i <= 30; i++) {
            const date = moment(this._negotiationService.selectedCarrier.negotiationPolicyRenewalDate);
            date.add(i, 'days');
            this.disabledDates.push(date);
        }
    }

    private swapDatePickers() {
        this.showFirst = !this.showFirst;
        this.showSecond = !this.showSecond;
    }

    private traverse(x) {
        let sum = 0;
        if (this.isArray(x)) {
            sum += this.traverseArray(x);
        } else if ((typeof x === 'object') && (x !== null)) {
            sum += this.traverseObject(x);
        } else {
            return x;
        }
        return sum;
    }

    private traverseArray(arr) {
        let sum = 0;
        arr.forEach(function _(x) {
            sum += this.traverse(x);
        });
        return sum;
    }

    private traverseObject(obj) {
        let sum = 0;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                sum += this.traverse(obj[key]);
            }
        }
        return sum;
    }

    private isArray(o) {
        return Object.prototype.toString.call(o) === '[object Array]';
    }

    private isObject(obj) {
        return (typeof obj === 'object') && (obj !== null);
    }

    private initCarrierOverview(): void {
        this.carrierSummmary = {};
        this.model.carrierName = this._negotiationService.selectedCarrier.carrierName;
        const aux = this._negotiationService.selectedCarrier.carrierAssessmentInputs;
        aux.forEach((c, i) => {
            this.carrierSummmary[c.carrierComparisonType] = c.summaryInputs;
        });
        this.currentRenewalDate = moment(this._negotiationService.selectedCarrier.negotiationPolicyRenewalDate).toDate();
        this.previousRenewlDate = this.currentRenewalDate;
        this.currentCollateralHeldValue = this._negotiationService.selectedCarrier.collateralHeld;
        this.form = Object.toFormGroup({ collateralHeld: this._negotiationService.selectedCarrier.collateralHeld, renewalDate: this.currentRenewalDate }, this._fb);
        this.readonly = this._negotiationService.selectedCarrier.carrierStatus === CarrierStatus.goForward;
        this._subscriptions.push(this._negotiationService.navigateAwayTriggered.subscribe(c => {
            this._negotiationService.actionExecute(c.item, c.obj);
        }
        ));

        this.setDisabledDate();
        this.minDate = moment(this._negotiationService.selectedCarrier.evalDate).toDate();
    }
}
