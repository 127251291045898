/**
    The service file will keep the coverage data file functionality
*/
import { Injectable }                             from '@angular/core';
import { ApiResult }                               from '@wtw/platform/api';
import { Observable }                             from 'rxjs';
import { ClientCoverageDataProxy }                from '../api/generated/ClientCoverageDataController';
import { ClientCoverageDataViewModel }            from '../api/dtos';

@Injectable()
export class CoverageDataService {
    constructor(private _api: ClientCoverageDataProxy) {
    }
        getCoverageData(): Observable<ApiResult<ClientCoverageDataViewModel>> {
              return this._api.coverageData();
        }//end of getCoverageData
}//end of class
