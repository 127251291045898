

import { Component, OnInit } from '@angular/core';
import { IComponentTranslation } from 'collateralq-components/src/lib/models/IComponentTranslation';
import { IExecutionHistory } from '@acl-cq-lib-pages-negotiation/inputSummary/executionHistory/IExecutionHistory';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/inputSummary/executionHistory/executionHistory.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/inputSummary/executionHistory/executionHistory.component.scss';
import { NegotiationService } from 'app/services/negotiation.service';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RiskType } from 'app/models';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-negotiation-execution-history',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ExecutionHistoryComponent extends ReactiveComponent implements OnInit, IExecutionHistory, IComponentTranslation {
    executionHilightTable: any = [];
    selectedIteration: number;
    translation: any = {};
    translationKey: any = 'COLLATERAL.NEGOTIATION.EXECUTIONHISTORY';
    riskTypes: { [key: string]: RiskType; };
    constructor(private _negotiationService: NegotiationService, private _translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this.initExecutionHistory();
        this._negotiationService.rFinishedEvent.subscribe(c => {
            this.initExecutionHistory();
        });
    }

    openClose(execution) {
        const currentExecutionValue = execution.open;
        this.executionHilightTable.forEach(c => { c.open = false; });
        execution.open = !currentExecutionValue;
    }

    initExecutionHistory() {
        this.riskTypes = this._negotiationService.riskTypes;
        let executionData = [];
        const rules = JSON.parse(JSON.stringify(this._translateService.instant(`COLLATERAL.NEGOTIATION.RULES.RULESDROPDOWN`)));
        let index = 1;
        const squasheRules = rules.DATA.map(c =>
            c.DATA.map(d => {
                d.id = index;
                d.displayName = `${rules.RULEDISPLAY} ${index++}`;
                return d;
            })).flatten();
        this._negotiationService.selectedCarrier.carrierAssessmentOutputs.forEach((c, i) => {
            c.negotiationRules.forEach(d => {
                const aux = d.lobRuleData.filter(e => e.executionHighlight);
                if (aux.length) {
                    let displayName = `${d.ruleId} (not mapped)`;
                    const ruleExists = squasheRules.find(e => e.KEY === +d.ruleId);
                    if (ruleExists) {
                        displayName = ruleExists.displayName;
                    }
                    executionData.push(
                        {
                            ruleId: d.ruleId,
                            displayOrderId: ruleExists.id,
                            ruleNo: displayName, rows: aux.map(e => {
                                return {
                                    riskType: e.riskType,
                                    ruleInfo: this._translateService.instant(`COLLATERAL.NEGOTIATION.RULES.RULESTABLE.${d.ruleId}.${e.localisationKey}`, e.localisationOverride),
                                    executionDate: c.executionDate
                                };
                            })
                        });
                }
            });

            if (executionData.length) {
                this.executionHilightTable.push({
                    iteration: this._negotiationService.selectedCarrier.carrierAssessmentOutputs.length - i,
                    executionData: JSON.parse(JSON.stringify(executionData.sort((a, b) => a.displayOrderId - b.displayOrderId)))
                });
                executionData = [];
            }
        });
        if (this.executionHilightTable.length) {
            this.executionHilightTable[0].open = true;
        }
    }

    selectRule(rule) {
        this._negotiationService.selectedRule = +rule.ruleId;
        this._negotiationService.actionExecute('RULES', this._negotiationService.selectedCarrier);
    }
}
