



    
    export interface RunViewModel  {
        
		runId: number;
		clientName: string;
		clientId: number;
		clientDetails: any;
		clientInformationInput: ClientInformationInput;
		ultimateAnalysis: UltimateAnalysis[];
		clientLDFs: ClientLDF[];
		collateralYear: LobCollateralYearSummary[];
		dataUpload: DataUpload;
		scenariosModel: ScenariosModel;
		carrierAssessmentData: CarrierAssessmentData[];
		isScenarioAnalysisComplete: boolean;
		isNegotiationAssessmentComplete: boolean;
    }

    

    

    
    export interface AssessmentLOBInput  {
        
		year: number;
		annualLOBInputDetails: AssessmentLOBInputDetails[];
		aggregateLOBInputDetails: AssessmentLOBInputDetails;
    }
    
    
    
    export interface AssessmentLOBInputDetails  {
        
		policyEffectiveDate: string;
		retention?: number;
		exposure?: number;
		limitedIncurredLoss?: number;
		limitedPaidLoss?: number;
		limitedCaseReserve?: number;
		openClaims?: number;
		nonZeroCount?: number;
		ibnr?: number;
		unpaidLiability?: number;
		ultimate?: number;
    }
    
    
    
    export interface AssessmentLOBInputSummary  {
        
		riskType: RiskTypeEnum;
		yearLOBInputs: AssessmentLOBInput[];
    }
    
    
    
    export interface BreakevenYearResult  {
        
		year?: number;
		breakeven?: number;
    }
    
    
    
    export interface Carrier  {
        
		id: number;
		legalName: string;
		labelName: string;
		shortCode: string;
		isDefault: boolean;
    }
    
    
    
    export interface CarrierAssessmentData  {
        
		negotiationCarrierId: number;
		carrierOrderId: number;
		carrierStatus: CarrierStatus;
		carrierName: string;
		showResults: boolean;
		isRTriggered: boolean;
		negotiationPolicyRenewalDate: string;
		evalDate: string;
		collateralHeld?: number;
		carrierUltimateTotals: CarrierTotalValues[];
		currentYearDataStatus: YearDataStatus;
		previousYearDataStatus: YearDataStatus;
		carrierAssessmentInputs: CarrierAssessmentInput[];
		carrierAssessmentOutputs: CarrierAssessmentOutput[];
		ultimateAnalysisIds: number[];
    }
    
    
    
    export interface CarrierAssessmentInput  {
        
		carrierComparisonType: CarrierComparisonType;
		summaryInputs: CarrierAssessmentSummaryInput;
		lobInputs: AssessmentLOBInputSummary[];
		summaryUserInputs: SummaryUserInputs;
    }
    
    
    
    export interface CarrierAssessmentOutput  {
        
		negotiationRules: NegotiationRule[];
		executionDate: string;
    }
    
    
    
    export interface CarrierAssessmentSummaryInput  {
        
		ultimateValues: SummaryUltimateValues;
		paidValues: SummaryPaidValues;
		adjustmentValues: SummaryAdjustmentValues;
		total?: number;
    }
    
    
    
    
    
    export enum CarrierComparisonType{
    
    wtwEvaluation = 0,
    
    wtwRenewal = 1,
    
    carrierRenewal = 2,
    
    }
    
    
    export interface CarrierCostRate  {
        
		weightTotal: number;
		costAverage: number;
    }
    
    
    
    export interface CarrierCoverageData  {
        
		scenarioCarrierId: number;
		riskTypeId: number;
		premium?: number;
		selectedUltLoss?: number;
		costRateTrend: number;
    }
    
    
    
    export interface CarrierDetails  {
        
		carrierOrderId: number;
		scenarioCarrierId: number;
		name: string;
		typeOfCarrier: CarrierType;
		plcType: PaidLossCreditType;
		collateralCostRate: CollateralCostRate;
		collateralRampUps: CollateralRampUp[];
		carrierCoverageData: CarrierCoverageData[];
		projections: Projection[];
    }
    
    
    
    
    
    export enum CarrierStatus{
    
    goForward = 0,
    
    legacy = 1,
    
    }
    
    
    export interface CarrierTotalValues  {
        
		displayOrderId: number;
		value?: number;
    }
    
    
    
    
    
    export enum CarrierType{
    
    incumbent = 0,
    
    prospective = 1,
    
    }
    
    
    export interface ClientCoverage  {
        
		clientCoverageData: ClientCoverageData[];
		errors: Error[];
    }
    
    
    
    export interface ClientCoverageData  {
        
		id: number;
		dataType: DataType;
		updatedOn: Date;
		dataSource: DataSource;
		source: Source;
		evaluationDate: Date;
		totalClaims: number;
		totalPaid: number;
		totalincurred: number;
		mostRecentPolicyYear: number;
		exposureTotal: number;
		retentionTotal: number;
		isSelected: boolean;
		isDisplay: boolean;
		graphDetail: ClientCoverageDataGraphDetail[];
    }
    
    
    
    export interface ClientCoverageDataGraphDetail  {
        
		paid: number;
		reserve: number;
		year: number;
		count: number;
    }
    
    
    
    export interface ClientCoverageDataViewModel  {
        
		clientCoverageData: ClientCoverageData[];
		errors: Error[];
    }
    
    
    
    export interface ClientInformationInput  {
        
		selectedIndustries: Industry[];
		spCreditRatingId: number;
		captiveInvolved: boolean;
		renewalDate?: Date;
    }
    
    
    
    export interface ClientLDF  {
        
		id: number;
		runId: number;
		lob: string;
		developmentType: DevelopmentType;
		limit: number;
		age: number;
		factor: number;
    }
    
    
    
    export interface CollateralCostRate  {
        
		securities: Security[];
		weightTotal: number;
		costAverage: number;
    }
    
    
    
    export interface CollateralCoverage  {
        
		coverageName: string;
		coverageId: number;
    }
    
    
    
    export interface CollateralRampUp  {
        
		day: number;
		collateralRequired: number;
    }
    
    
    
    export interface CollateralRampUpType  {
        
		id: number;
		rampUpType: string;
		value: number;
		isDefault: boolean;
		initialDay: number;
    }
    
    
    
    export interface CollateralYear  {
        
		id: number;
		runId: number;
		lob: string;
		policyStart: string;
		retention?: number;
		exposure?: number;
		netIncurredLimited?: number;
		netPaidLimited?: number;
		netCaseLimited?: number;
		ibnrAtEval?: number;
		unpaidAtEval?: number;
		ultEval?: number;
		unpaidRenewal?: number;
		selectedUlt?: number;
		unpaidPerOpen?: number;
		riskTypeId: number;
    }
    
    
    
    export interface CommonAssumptions  {
        
		discountRate: number;
		incumbentLegacyPLCType: PaidLossCreditType;
		incumbentLegacyPLC: number;
		walkawayCollateral: number;
    }
    
    
    
    export interface CostRateItem  {
        
		weight: number;
		cost?: number;
    }
    
    
    
    export interface Coverage  {
        
		riskTypeId: number;
		deductible?: number;
		limit?: number;
		legacyUltimateEstimate?: number;
		riskTypeExposureGrowths: ExposureGrowth[];
    }
    
    
    
    export interface CoverageData  {
        
		riskTypeId: number;
		riskTypeName: string;
    }
    
    
    
    export interface CoverageName  {
        
		riskTypeName: string;
		riskTypeFullName: string;
		riskTypeShortDescription: string;
		isDisabled: boolean;
    }
    
    
    
    export interface DataSource  {
        
		name: string;
    }
    
    
    
    export interface DataType  {
        
		name: string;
		description: string;
    }
    
    
    
    export interface DataTypeUploaded  {
        
		dataType: string;
		sheetName: string;
		name: string;
		id: number;
		addressRanges: string[];
    }
    
    
    
    export interface DataUpload  {
        
		uploaded: boolean;
		collateralCoverage: CollateralCoverage[];
		ErrorMessage : string;
    }
    
    
    
    
    
    export enum DevelopmentType{
    
    paid = 0,
    
    incurred = 1,
    
    }
    
    
    export interface Error  {
        
		code: string;
		description: string;
    }
    
    
    
    export interface EstimatedCashFlowYearResult  {
        
		scenarioCarrierId: number;
		year?: number;
		premExp?: number;
		collCost?: number;
		total?: number;
    }
    
    
    
    export interface EstimatedCollateralYearResult  {
        
		scenarioCarrierId: number;
		year: number;
		legacy?: number;
		future?: number;
		total?: number;
    }
    
    
    
    export interface ExcelColumnMetaData  {
        
		name: string;
		type: string;
		required: boolean;
		displayOrder: number;
    }
    
    
    
    export interface ExcelDataValidation  {
        
		id: number;
		dataType: string;
		metaDataName: string;
		metaDataType: string;
		isRequired: boolean;
		displayOrder: number;
		sourceTable: string;
		sourceColumn: string;
		isReferenceData: boolean;
    }
    
    
    
    export interface ExecutionLog  {
        
		negotiationCarrierId: number;
		interationNumber: number;
		ruleId: string;
		riskTypeId: number;
		ruleMessage: string;
		triggerName: TriggerType;
    }
    
    
    
    export interface ExposureGroup  {
        
		name: string;
		subDivExposures: SubDivExposure[];
		total: number;
    }
    
    
    
    export interface ExposureGrowth  {
        
		year: number;
		riskTypeId: number;
		value: number;
    }
    
    
    
    export interface FileResponse  {
        
		dataTypes: DataTypeUploaded[];
		fileName: string;
		errors: string[];
    }
    
    
    
    export interface FileUpload  {
        
		applicationGuid: string;
		file: number[];
		fileName: string;
		modelType: number;
		clientId: number;
		runId: number;
    }
    
    
    
    export interface FileUploadStatus  {
        
		success: boolean;
		errorMessage: string;
    }
    
    
    
    
    
    export enum FileUploadType{
    
    dcfFile = 1,
    
    }
    
    
    export interface Industry  {
        
		id: number;
		name: string;
		weighting: number;
    }
    export interface IndustryGroupCombine  {
        
		groupName: string;
		name: string;
		id: number;
    }
    
    
    
    export interface IndustryGroup  {
        
		name: string;
		industries: Industry[];
    }
    
    
    
    export interface KeyValue  {
        
		key: any;
		value: any;
    }
    
    
    
    export interface LobCollateralYearSummary  {
        
		riskTypeId: number;
		yearlyData: CollateralYear[];
		collateralYearTotal: CollateralYear;
    }
    
    
    
    export interface LOBDataStatus  {
        
		riskTypeId: number;
		hasData: boolean;
    }
    
    
    
    export interface LOBRuleData  {
        
		riskType: RiskTypeEnum;
		localisationKey: string;
		localisationOverride: { [key: string]: any[]; };
		ruleData: { [key: string]: any[]; };
		executionHighlight: boolean;
		header: string[];
    }
    
    
    
    export interface LossCreditType  {
        
		id: number;
		pdLossCredType: PaidLossCreditType;
		pdLossCredValue: number;
		abbreviation: string;
		abbreviationName: string;
		isDefault: boolean;
    }
    
    
    
    export interface NegotiationCarrierRData  {
        
		ultimateAnalysisEntries: UltimateAnalysis[];
		clientLDF: ClientLDF;
		collateralYear: CollateralYear;
    }
    
    
    
    export interface NegotiationRule  {
        
		ruleId: string;
		lobRuleData: LOBRuleData[];
    }
    
    
    
    export interface OdsSearchOptions  {
        
		odsDataSetId: number;
		applicationName: string;
		partyId: number;
		applicationGuid: string;
		dataType: number;
		clientId: number;
    }
    
    
    
    export interface OdsSearchResult  {
        
		odsId: number;
		applicationGuid: string;
		clientId: number;
		applicationName: string;
		dataType: number;
		fileName: string;
		uploadedBy: string;
		partyId: number;
		dateCreated: Date;
    }
    
    
    
    export interface OdsSearchResults  {
        
		result: OdsSearchResult[];
		errors: string;
    }
    
    
    
    export interface OdsUpload  {
        
		coverage: string;
		clientId: number;
		dataTypeId: number;
		odsUploadSetId: number;
    }
    
    
    
    export interface OdsUploadInput  {
        
		applicationGuid: string;
		file: string;
		fileName: string;
		modelType: number;
		clientId: number;
    }
    
    
    
    export interface OdsUploadResult  {
        
		uploadSetId: number;
		errors: string;
		dataTypeId: number;
    }
    
    
    
    export interface PaidLossCreditData  {
        
		percentageValue: number;
		monthsValue: number;
		monetaryValue: number;
    }
    
    
    
    
    
    export enum PaidLossCreditType{
    
    percent = 1,
    
    dollars = 2,
    
    months = 3,
    
    }
    
    
    export interface PremiumResult  {
        
		scenarioCarrierId: number;
		value: number;
    }
    
    
    
    export interface ProcessDataCompleted  {
        
		completed: number;
    }
    
    
    
    export interface Projection  {
        
		year: number;
		plcData: PaidLossCreditData;
    }
    
    
    
    export interface ProjectionData  {
        
		projectionPeriod: number;
		projectionsByCarrier: { [key: number]: Projection[]; };
    }
    
    
    
    export interface ReferenceData  {
        
		industryGroups: IndustryGroup[];
		spCreditRatings: SpCreditRating[];
		riskTypes: RiskType[];
		carriers: Carrier[];
		lossCreditTypes: LossCreditType[];
		collateralRampUpTypes: CollateralRampUpType[];
		scenarioPeriodList: ScenarioPeriod[];
		rVersionReleaseDate: string;
    }
    
    
    
    export interface RiskType  {
        
		id: number;
		riskTypeName: string;
		fullName: string;
		shortDescription: string;
		sectorIndustryGroupId: number;
		selectionIndustryGroupId: number;
		exposureDimensions: string;
		exposureProfileTypes: string;
		geographyReferenceGroup: string;
		hazardGroupReferenceGroup: string;
		displayOrderId: number;
    }
    
    
    
    
    
    export enum RiskTypeEnum{
    
    client = 0,
    
    wc = 1,
    
    al = 3,
    
    gl = 2,
    
    }
    
    
    export interface Scenario  {
        
		scenarioId: number;
		scenarioName: string;
		carriers: CarrierDetails[];
		coverageDisplayData: { [key: number]: CoverageName; };
		selectedForR: boolean;
		commonAssumptions: CommonAssumptions;
		coverageAssumptions: Coverage[];
		projectionPeriod: number;
		scenarioResult: ScenarioResult;
		isValid: boolean;
		isRTriggered: boolean;
		isSaved: boolean;
		isSelected: boolean;
    }
    
    
    
    export interface ScenarioDefaults  {
        
		projectionPeriod: number;
		exposureGrowth: number;
		policyStart: Date;
		discountRate: number;
		plcType: PaidLossCreditType;
		plcValue: number;
		carrierDefault: string;
		costRateTrend: number;
		costRate: number;
		numberOfCarriers: number;
		walkawayCollateral: number;
    }
    
    
    
    export interface ScenarioPeriod  {
        
		noOfyears: number;
		isDefault: boolean;
    }
    
    
    
    export interface ScenarioResult  {
        
		estimatedCollateralResults: EstimatedCollateralYearResult[];
		estimatedCashFlowYearResults: EstimatedCashFlowYearResult[];
		breakevenYearResults: BreakevenYearResult[];
		premiumResults: PremiumResult[];
		summaryResult: SummaryResult;
    }
    
    
    
    export interface ScenariosModel  {
        
		scenarioUniqueId: number;
		scenarios: Scenario[];
    }
    
    
    
    export interface Security  {
        
		securityType: SecurityType;
		weight: number;
		cost?: number;
    }
    
    
    
    
    
    export enum SecurityType{
    
    loc = 1,
    
    surety = 2,
    
    cash = 3,
    
    trust = 4,
    
    buyDown = 5,
    
    }
    
    
    export interface SelectedDataType  {
        
		dataType: number;
		dataTypeName: string;
		sheetName: string;
		addressRanges: string[];
		id: number;
    }
    
    
    
    export interface SelectedFile  {
        
		fileName: string;
		dataTypes: SelectedDataType[];
		clientId: number;
		coverage: string;
    }
    
    
    
    export interface Source  {
        
		name: string;
    }
    
    
    
    export interface SpCreditRating  {
        
		id: number;
		creditRating: string;
		isDefault: boolean;
    }
    
    
    
    export interface State  {
        
		id: number;
		name: string;
    }
    
    
    
    export interface SubDivExposure  {
        
		name: string;
		exposure: number;
		percent: number;
		subDivExposures: SubDivExposure[];
    }
    
    
    
    export interface SummaryAdjustmentValues  {
        
		paidLossCredit: number;
		otherCollateralAdjustments: number;
		total: number;
    }
    
    
    
    export interface SummaryPaidValues  {
        
		actualPaidAtRenewal: number;
		expectedAdditionalPaid: number;
		total: number;
    }
    
    
    
    export interface SummaryResult  {
        
		totalInvestment?: number;
		benefitsBeginMonth?: number;
		breakevenMonth?: number;
		addCollateral?: number;
		totalSavings?: number;
		totalGain?: number;
    }
    
    
    
    export interface SummaryUltimateValues  {
        
		completeYear: number;
		inForceYear: number;
		lossForecast?: number;
		total?: number;
    }
    
    
    
    export interface SummaryUserInputs  {
        
		lossForecast: number;
		paidLossCredit: number;
		otherCollateralAdjustments: number;
    }
    
    
    
    export interface TokenResult  {
        
		accessToken: string;
    }
    
    
    
    
    
    export enum TriggerType{
    
    processScenario = 0,
    
    processUploadData = 1,
    
    processCarrierAssessment = 2,
    
    }
    
    
    export interface UltimateAnalysis  {
        
		id: number;
		runId: number;
		lob: string;
		riskTypeId: number;
		evalDate: string;
		policyStart: string;
		policyEnd: string;
		exposure?: number;
		retention?: number;
		carrier: string;
		netPaidLimited?: number;
		netIncurredLimited?: number;
		nonZeroCount?: number;
		openCount?: number;
		ageEval?: number;
		paidLdfEval?: number;
		incLdfEval?: number;
		aprioriDetrended?: number;
		paidUltEval?: number;
		incUltEval?: number;
		incBFUltEval?: number;
		selectedUlt?: number;
		ultEval?: number;
		ageRenewal?: number;
		paidLdfRenewal?: number;
		incLdfRenewal?: number;
		deltaPaid?: number;
		deltaInc?: number;
		unpaidRenewal?: number;
		ibnrRenewal?: number;
		caseRenewal?: number;
		incurredRenewal?: number;
		ilfFactors?: number;
    }
    
    
    
    export interface UltimateAnalysisRInput  {
        
		negotiationCarrierId: number;
		ultimateAnalysisData: UltimateAnalysis[];
    }
    
    
    
    export interface YearDataStatus  {
        
		year: number;
		lobDataStatuses: LOBDataStatus[];
    }
    
    


