import { Component } from '@angular/core';
import { CommonAssumptions, PaidLossCreditType } from '../../../../../models/index';
import { ScenarioService } from 'app/services/scenario.service';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/commonAssumptionsSummary/commonAssumptionsSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { ICommonAssumptionsSummary } from '@acl-cq-lib-pages-scenario-summary/commonAssumptionsSummary/ICommonAssumptionsSummary';

@Component({
    selector: 'app-common-assumptions-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CommonAssumptionsSummaryComponent implements ICommonAssumptionsSummary {
    public translationKey: string = 'COLLATERAL.SCENARIOS.SUMMARY.COMMON_ASSUMPTIONS';
    public translation = {};

    constructor(private _scenarioService: ScenarioService) { }

    public get CreditType(): typeof PaidLossCreditType {
        return PaidLossCreditType;
    }

    public get model(): CommonAssumptions {
        return this._scenarioService.selectedScenario.commonAssumptions;
    }

    public get isIncumbentComparison(): boolean {
        return this._scenarioService.isIncumbentComparison();
    }

    public get carriers(): any[] {
        return this._scenarioService.selectedScenario.carriers;
    }
}