import { Coverage, CoverageName, CommonAssumptions, CarrierDetails } from '../../../../../models/index';
import { ScenarioService } from '../../../../../services/scenario.service';
import * as Dto from '../../../../../api/dtos';
import { Component, OnInit } from '@angular/core';
import { ReferenceDataService } from 'app/services/referenceData.service';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/coverageAssumptionsSummary/coverageAssumptionsSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { ICoverageAssumptionsSummary } from '@acl-cq-lib-pages-scenario-summary/coverageAssumptionsSummary/ICoverageAssumptionsSummary';

@Component({
    selector: 'app-coverage-assumptions-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CoverageAssumptionsSummaryComponent implements OnInit, ICoverageAssumptionsSummary {
    public translationKey: string = 'COLLATERAL.SCENARIOS.SUMMARY.COVERAGE_ASSUMPTIONS';
    public translation = {};

    public get coverageAssumptions(): Coverage[] {
        return this.scenarioService.selectedScenario.coverageAssumptions.filter(c => !this.coverageDisplayData[c.riskTypeId].isDisabled);
    }

    public get coverageDisplayData(): { [key: number]: CoverageName } {
        return this.scenarioService.selectedScenario.coverageDisplayData;
    }

    public get carriers(): CarrierDetails[] {
        return this.scenarioService.selectedScenario.carriers;
    }

    public get coverageData(): any {
        const coverageData = [
            'premium',
            'selectedUltLoss',
            'costRateTrend'
        ];
        const aux = coverageData.map(c => {
            return {
                label: c,
                data: ([...this.coverageAssumptions.map(d =>
                    this.carriers.map(e => e.carrierCoverageData.find(f => f.riskTypeId === d.riskTypeId)[c])
                )] as any).flat()
            };
        });
        return aux;
    }

    constructor(private scenarioService: ScenarioService, private _refDataService: ReferenceDataService) {

    }

    ngOnInit() { }

    public get isIncumbentComparison() {
        return this.scenarioService.isIncumbentComparison();
    }

    public get displayPercentage() {
        return this.displayPercentageHeader / 2;
    }

    public get displayPercentageHeader() {
        const coverageLength = this.coverageAssumptions.filter(c => this.coverageDisplayData[c.riskTypeId] && !this.coverageDisplayData[c.riskTypeId].isDisabled).length;
        if (coverageLength === 1) {
            return 65 / coverageLength;
        } else {
            return 80 / coverageLength;
        }
    }
}
