import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/inputSummary/aggregate/aggregate.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/inputSummary/aggregate/aggregate.component.scss';
import { IAggregate } from '@acl-cq-lib-pages-negotiation/inputSummary/aggregate/IAggregate';

import { CarrierComparisonType } from 'app/models/CarrierComparisonType';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { NegotiationService } from 'app/services/negotiation.service';
import { BsModalService } from 'ngx-bootstrap';
import { AggregateUploadComponent } from '../../shared/aggregateUpload/aggregateUpload.component';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ModalService, AlertService } from '@wtw/platform/services';
import { AssessmentLOBInputSummary, CarrierAssessmentData, RunViewModel, RiskType, CarrierStatus } from 'app/api/dtos';
import { IStandardModalConfig } from '@wtw/platform/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { NegotiationAnalysisProxy } from 'app/api/generated/NegotiationAnalysisController';

@Component({
    selector: 'app-negotiation-aggregate',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class AggregateComponent extends ReactiveComponent implements OnInit, IComponentTranslation, IAggregate {
    translation = {};
    translationKey = 'COLLATERAL.NEGOTIATION.CARRIEROVERVIEW.LOBDATA';
    headerTranslationKey = 'COLLATERAL.NEGOTIATION.CARRIEROVERVIEW.LOBDATA';
    @Input()
    lobInputs: any;
    @Output()
    discardChanges: EventEmitter<boolean> = new EventEmitter();
    isWTW: boolean = false;
    carrierName: string;
    carrierType: CarrierComparisonType;
    form: FormGroup;
    yearLOBInputs: any;
    headerTranslation: {};
    tabList: any[];

    selectedYear: any;
    totals: any[];
    objectProps = [
        { key: 'policyEffectiveDate', format: 'effectiveDate', disabled: [], pattern: '[[0-9]{4}-[0-9]{4}]*|[[0-9]{4}-[0-9]{2}-[0-9]{2}]*' },
        { key: 'retention', format: 'retention', disabled: [] },
        { key: 'exposure', format: 'number', disabled: [] },
        { key: 'limitedIncurredLoss', format: 'number', disabled: [] },
        { key: 'limitedPaidLoss', format: 'number', disabled: [] },
        { key: 'limitedCaseReserve', format: 'number', disabled: [] },
        { key: 'openClaims', format: 'number', disabled: [] },
        { key: 'nonZeroCount', format: 'number', disabled: [] = [] },
        { key: 'ibnr', format: 'number', disabled: [] },
        { key: 'unpaidLiability', format: 'number', disabled: [] },
        { key: 'ultimate', format: 'number', disabled: [] },
    ];
    tableHeders = [
        'POLICYEFFECTIVEDATE',
        'RETENTION',
        'EXPOSURE',
        'LIMITEDINCURREDLOSS',
        'LIMITEDPAIDLOSS',
        'LIMITEDCASERESERVE',
        'OPENCLAIMS',
        'NONZEROCOUNT',
        'IBNR',
        'UNPAIDLIABILITY',
        'ULTIMATE'
    ];

    customValidators = {
        retention: [Validators.min(0)],
        exposure: [Validators.min(0)],
        limitedIncurredLoss: [Validators.min(0)],
        limitedPaidLoss: [Validators.min(0)],
        limitedCaseReserve: [Validators.min(0)],
        openClaims: [Validators.min(0)],
        ibnr: [Validators.min(0)],
        nonZeroCount: [Validators.min(0)],
        unpaidLiability: [Validators.min(0)],
        ultimate: [Validators.min(0)],
        lossForecast: [Validators.min(0)],
        paidLossCredit: [Validators.min(0)],
        otherCollateralAdjustments: [Validators.min(0)]
    };
    navigationObject: any;
    riskTypes: { [key: string]: RiskType; };
    summaryUserInputsForm: FormGroup;
    isWTWRenewal: boolean;
    constructor(private _negotiationService: NegotiationService, private fb: FormBuilder, private _bsModalService: BsModalService, private _modalService: ModalService
        , private _translate: TranslateService, private _alertService: AlertService, private _negotiationAnalysisProxy: NegotiationAnalysisProxy) {
        super();
    }

    ngOnInit() {
        this.riskTypes = this._negotiationService.riskTypes;
        this.carrierType = this.lobInputs.carrierComparisonType;
        this.isWTWRenewal = (this.carrierType === CarrierComparisonType.wtwRenewal) && (this._negotiationService.selectedCarrier.carrierStatus === CarrierStatus.goForward);
        this.headerTranslation = {
            TITLE: this.translation['COMPARISON' + this.carrierType],
            DOWNLOAD: 'Download Data'
        };
        this.headerTranslationKey = this.headerTranslationKey + 'COMPARISON' + this.carrierType;
        this.form = Object.toFormGroup(this.lobInputs.lobInputs, this.fb, this.customValidators);
        this.summaryUserInputsForm = Object.toFormGroup(this.lobInputs.summaryUserInputs, this.fb, this.customValidators);
        this.yearLOBInputs = this.lobInputs.lobInputs;
        this.lessDetails();
        this.tabList = this.lobInputs.lobInputs[0].yearLOBInputs.map((c, i) => {
            return {
                title: c.year,
                id: 'lobYear_' + c.year,
            };
        });
        if (this.tabList.length === 1) {
            this.selectedYear = this.tabList[this.tabList.length - 1].title;
            this.tabList = [];
        } else {
            this.tabList.sort((a, b) => a.title - b.title);
            this.tabList[this.tabList.length - 1].isSelected = true;
            this.selectedYear = this.tabList[this.tabList.length - 1].title;
        }
        if (this.carrierType === CarrierComparisonType.carrierRenewal) {
            this.isWTW = false;
            this.carrierName = this._negotiationService.selectedCarrier.carrierName;
        } else {
            this.isWTW = true;
        }
        this._subscriptions.push(this.form.valueChanges.subscribe(c => {
            this.formValueChanged();
        }));
        this._subscriptions.push(this._negotiationService.navigateAwayTriggered.subscribe(c => {
            this.navigationObject = c;
            this.backToSummary();
        }
        ));
        this._subscriptions.push(this._negotiationService.rFinishedEvent.subscribe(c => {
            this.backToSummary();
        }));
        this.formValueChanged();
    }

    formValueChanged() {
        if (this.form.valid) {
            this._alertService.clear();
        }
        const lobInputs = (this.form as any).controls.map(c => c.controls.yearLOBInputs.controls.find(c => c.value.year === this.selectedYear));
        lobInputs.forEach((c, i) => {
            this.objectProps.forEach(d => {
                if (d.format === 'number') {
                    this.setLOBValue(c, d, i);
                } else {
                    this.groupLOBValue(c, d, i);
                }
            });
        });
        this.totals = this.calculateTotals();
    }

    setLOBValue(lobInputs, propObj, index) {
        const propName = propObj.key;
        const value = lobInputs.value.annualLOBInputDetails.reduce((a, b) => a + (+b[propName]), 0);
        if (value !== 0 || this.isWTW) {
            lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue(value, { emitEvent: false });
            propObj.disabled[index] = true;
        } else {
            if (propObj.disabled[index] === true) {
                lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue(value, { emitEvent: false });
            }
            propObj.disabled[index] = false;
        }
    }

    groupLOBValue(lobInputs, propObj, index) {
        const propName = propObj.key;

        if (propObj.format === 'effectiveDate') {
            const aux = lobInputs.value.annualLOBInputDetails.filter(c =>
                Object.keys(c).filter(d => (c[d] || c[d] === 0) && d !== propName).length > 0).map(c => c.policyEffectiveDate);
            const x = aux.map(c => +c.split('-')[0]);
            if (x.length > 0) {
                lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue((x.length > 1 ? Math.min(...x) : aux[0]) + (x.length > 1 ? ' - ' + Math.max(...x) : ''), { emitEvent: false });
                propObj.disabled[index] = true;
            } else {
                if (propObj.disabled[index] === true) {
                    lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue('', { emitEvent: false });
                }
                propObj.disabled[index] = false;
            }
        } else if (propObj.format === 'retention') {
            const x = lobInputs.value.annualLOBInputDetails.map(c => c[propName]).filter(c => c);
            if (x.every(v => v === x[0]) && x.length) {
                propObj.disabled[index] = true;
                lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue(Math.max(...x), { emitEvent: false });
            } else if (x.length) {
                propObj.disabled[index] = true;
                lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue('', { emitEvent: false });
                lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue('Various', { emitEvent: false });
            } else {
                if (propObj.disabled[index] === true) {
                    lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue('', { emitEvent: false });
                    lobInputs.controls.aggregateLOBInputDetails.controls[propName].setValue('', { emitEvent: false });
                }
                propObj.disabled[index] = false;
            }
        }
    }

    calculateTotals(): any[] {
        const selectedAnnual = (this.form as any).controls.map(d => d.value.yearLOBInputs.find(c => c.year === this.selectedYear).aggregateLOBInputDetails);
        return [
            '',
            undefined,
            undefined,
            selectedAnnual.reduce((a, b) => a + (+b.limitedIncurredLoss), 0),
            selectedAnnual.reduce((a, b) => a + (+b.limitedPaidLoss), 0),
            selectedAnnual.reduce((a, b) => a + (+b.limitedCaseReserve), 0),
            selectedAnnual.reduce((a, b) => a + (+b.openClaims), 0),
            selectedAnnual.reduce((a, b) => a + (+b.nonZeroCount), 0),
            selectedAnnual.reduce((a, b) => a + (+b.ibnr), 0),
            selectedAnnual.reduce((a, b) => a + (+b.unpaidLiability), 0),
            selectedAnnual.reduce((a, b) => a + (+b.ultimate), 0),
        ];
    }

    public get CarrierComparisonType() {
        return CarrierComparisonType;
    }


    public setTabActive(item) {
        this.tabList.forEach(c => { c.isSelected = false; });
        item.isSelected = true;
        this.selectedYear = item.title;
        this.objectProps.forEach(prop => {
            prop.disabled.forEach((c, i) => { prop.disabled[i] = false; });
        });
        this.formValueChanged();
    }

    lessDetails() {
        this.yearLOBInputs.forEach(c => c.collapsed = true);
    }

    moreDetails(riskType) {
        this.lessDetails();
        riskType.collapsed = false;
    }

    checkChanges() {
        const summaryUserInputValues = this.summaryUserInputsForm.getRawValue();
        summaryUserInputValues.lossForecast = summaryUserInputValues.lossForecast || 0;
        summaryUserInputValues.paidLossCredit = summaryUserInputValues.paidLossCredit || 0;
        summaryUserInputValues.otherCollateralAdjustments = summaryUserInputValues.otherCollateralAdjustments || 0;
        const summaryInputsChanged = JSON.stringify(this.lobInputs.summaryUserInputs) !== JSON.stringify(summaryUserInputValues);
        if (this.isWTW) {
            return summaryInputsChanged;
        } else {
            const aux = this.form.getRawValue();
            this.lobInputs.lobInputs.forEach((c, i) => {
                aux[i].collapsed = c.collapsed;
            });
            return (JSON.stringify(this.lobInputs.lobInputs) !== JSON.stringify(aux) || summaryInputsChanged);
        }
    }

    getConfirmSettings() {
        const messageOverwrite = this._translate.instant(`${this.translationKey}.DISCARD.TITLECARRIER` + this.carrierType);
        const confirmSettings: IStandardModalConfig = {
            title: this._translate.instant(`${this.translationKey}.DISCARD.TITLE`, { overwrite: messageOverwrite }),
            withHeader: false,
            message: this._translate.instant(`${this.translationKey}.DISCARD.MESSAGE`),
            yesButton: { display: true, text: this._translate.instant('GLOBAL.NO') },
            noButton: { display: true, text: this._translate.instant('GLOBAL.YES') }
        };
        return confirmSettings;
    }

    backToSummary() {
        const summaryUserInputValues = this.summaryUserInputsForm.getRawValue();
        summaryUserInputValues.lossForecast = summaryUserInputValues.lossForecast || 0;
        summaryUserInputValues.paidLossCredit = summaryUserInputValues.paidLossCredit || 0;
        summaryUserInputValues.otherCollateralAdjustments = summaryUserInputValues.otherCollateralAdjustments || 0;
        const summaryInputsChanged = JSON.stringify(this.lobInputs.summaryUserInputs) !== JSON.stringify(summaryUserInputValues);
        if (!summaryInputsChanged && this.isWTW) {
            if (this.navigationObject) {
                this._negotiationService.actionExecute(this.navigationObject.item, this.navigationObject.obj);
                return;
            }
            this.discardChanges.emit();
            return;
        }
        const aux = this.form.getRawValue();
        this.lobInputs.lobInputs.forEach((c, i) => {
            aux[i].collapsed = c.collapsed;
        });
        if (JSON.stringify(this.lobInputs.lobInputs) !== JSON.stringify(aux) || summaryInputsChanged) {
            const messageOverwrite = this._translate.instant(`${this.translationKey}.DISCARD.TITLECARRIER` + this.carrierType);
            const confirmSettings: IStandardModalConfig = {
                title: this._translate.instant(`${this.translationKey}.DISCARD.TITLE`, { overwrite: messageOverwrite }),
                withHeader: false,
                message: this._translate.instant(`${this.translationKey}.DISCARD.MESSAGE`),
                yesButton: { display: true, text: this._translate.instant('GLOBAL.NO') },
                noButton: { display: true, text: this._translate.instant('GLOBAL.YES') }
            };
            this._modalService.confirm(confirmSettings).then(c => {
                if (c === false) {
                    if (this.navigationObject) {
                        this._negotiationService.actionExecute(this.navigationObject.item, this.navigationObject.obj);
                        return;
                    }
                    this.discardChanges.emit();
                }
            });
        } else {
            if (this.navigationObject) {
                this._negotiationService.actionExecute(this.navigationObject.item, this.navigationObject.obj);
                return;
            }
            this.discardChanges.emit();
        }
    }

    saveChanges(showResults?: boolean) {
        this._negotiationService.selectedCarrier.showResults = showResults;
        if (!showResults) {
            this._negotiationService.clearCarrierResults();
        }
        this.saveChangedData();
    }

    saveChangedData() {
        this.lobInputs.lobInputs = this.form.getRawValue();
        this.lobInputs.summaryUserInputs = this.summaryUserInputsForm.getRawValue();
        this.lobInputs.summaryInputs.ultimateValues.lossForecast = this.lobInputs.summaryUserInputs.lossForecast = this.lobInputs.summaryUserInputs.lossForecast || 0;
        this.lobInputs.summaryInputs.adjustmentValues.paidLossCredit = this.lobInputs.summaryUserInputs.paidLossCredit = this.lobInputs.summaryUserInputs.paidLossCredit || 0;
        this.lobInputs.summaryInputs.adjustmentValues.otherCollateralAdjustments = this.lobInputs.summaryUserInputs.otherCollateralAdjustments = this.lobInputs.summaryUserInputs.otherCollateralAdjustments || 0;
        this._negotiationAnalysisProxy.updateSummaryTotals(this.lobInputs.summaryInputs).subscribe(c => {
            this.lobInputs.summaryInputs = c.data;
            this.continueSaveChangedData();
        });
    }

    continueSaveChangedData() {
        const carrierData = this._negotiationService.selectedCarrier as CarrierAssessmentData;
        carrierData.currentYearDataStatus.lobDataStatuses.forEach(c => {
            (this.lobInputs.lobInputs as AssessmentLOBInputSummary[]).forEach(d => {
                if (d.riskType === c.riskTypeId) {
                    d.yearLOBInputs.forEach(e => {
                        if (e.year === carrierData.currentYearDataStatus.year) {
                            c.hasData = this.objectProps.some(f => e.aggregateLOBInputDetails[f.key]);
                        }
                    });
                }
            });
        });
        carrierData.previousYearDataStatus.lobDataStatuses.forEach(c => {
            (this.lobInputs.lobInputs as AssessmentLOBInputSummary[]).forEach(d => {
                if (d.riskType === c.riskTypeId) {
                    d.yearLOBInputs.forEach(e => {
                        if (e.year === carrierData.previousYearDataStatus.year) {
                            c.hasData = this.objectProps.some(f => e.aggregateLOBInputDetails[f.key]);
                        }
                    });
                }
            });
        });
        this._negotiationService.save();
    }

    runCarrier() {
        if (!this.form.valid) {
            Object.markFormGroupTouched(this.form);
            this._alertService.warn(this._translate.instant(`${this.translationKey}.RNEGATIVEVALUES`));
            this.saveChanges();
            return;
        }
        this.saveChangedData();
        this._negotiationService.runCalculation('RFAILED.MESSAGECARRIER');
    }

    cancel() {
        this.discardChanges.emit();
    }

    uploadModel() {
        const ref = this._bsModalService.show(AggregateUploadComponent, { class: 'col-sm-9 modal--without-header' });
        ref.content.close.subscribe(c => ref.hide());
        ref.content.dataUpload = { uploaded: false };
        ref.content.year = this.selectedYear;
        ref.content.downloadData.subscribe(c => {
            this.downloadData();
        });
        ref.content.persist.subscribe(c => {
            try {
                let groupByLob = {};
                if (c._ultAnalysis_annual) {
                    groupByLob = c._ultAnalysis_annual.groupBy('lob');
                    Object.keys(groupByLob).forEach(d => {
                        const riskTypeId = this._negotiationService.getRiskTypeIdByLob(d).id;
                        (this.lobInputs.lobInputs as AssessmentLOBInputSummary[]).forEach(e => {
                            if (e.riskType === riskTypeId) {
                                e.yearLOBInputs.forEach(yearLobs => {
                                    if (yearLobs.year === this.selectedYear) {
                                        this.objectProps.forEach(prop => {
                                            yearLobs.annualLOBInputDetails.forEach(h => {
                                                if (prop.key !== 'policyEffectiveDate') {
                                                    h[prop.key] = null;
                                                }
                                            });
                                        });
                                        yearLobs.annualLOBInputDetails.forEach(h => {
                                            groupByLob[d].forEach(g => {
                                                if (g.policyEffectiveDate === h.policyEffectiveDate) {
                                                    h.retention = +g.retention || 0;
                                                    h.exposure = +g.exposure || 0;
                                                    h.limitedIncurredLoss = (+g.limitedIncurredLoss || 0);
                                                    h.limitedPaidLoss = (+g.limitedPaidLoss || 0);
                                                    h.limitedCaseReserve = (+g.limitedCaseReserve || 0);
                                                    h.openClaims = (+g.openClaims || 0);
                                                    h.nonZeroCount = (+g.nonZeroCount || 0);
                                                    h.ibnr = (+g.ibnr || 0);
                                                    h.unpaidLiability = +g.unpaidLiability || 0;
                                                    h.ultimate = +g.ultimate || 0;
                                                }
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    });
                }
                if (c._ultAnalysis_aggregate) {
                    const updatedLobs = Object.keys(groupByLob);
                    groupByLob = c._ultAnalysis_aggregate.groupBy('lob');
                    Object.keys(groupByLob).forEach(d => {
                        if (updatedLobs.indexOf(d) !== -1) {
                            return;
                        }
                        const riskTypeId = this._negotiationService.getRiskTypeIdByLob(d).id;
                        (this.lobInputs.lobInputs as AssessmentLOBInputSummary[]).forEach(e => {
                            if (e.riskType === riskTypeId) {
                                e.yearLOBInputs.forEach(yearLobs => {
                                    if (yearLobs.year === this.selectedYear) {
                                        this.objectProps.forEach(prop => {
                                            prop.disabled.forEach((c, i) => { prop.disabled[i] = false; });
                                            yearLobs.annualLOBInputDetails.forEach(h => {
                                                if (prop.key !== 'policyEffectiveDate') {
                                                    h[prop.key] = null;
                                                }
                                            });
                                        });
                                        const h = yearLobs.aggregateLOBInputDetails;
                                        groupByLob[d].forEach(g => {
                                            h.policyEffectiveDate = g.policyEffectiveDate;
                                            h.retention = +g.retention || 0;
                                            h.exposure = +g.exposure || 0;
                                            h.limitedIncurredLoss = (+g.limitedIncurredLoss || 0);
                                            h.limitedPaidLoss = (+g.limitedPaidLoss || 0);
                                            h.limitedCaseReserve = (+g.limitedCaseReserve || 0);
                                            h.openClaims = (+g.openClaims || 0);
                                            h.nonZeroCount = (+g.nonZeroCount || 0);
                                            h.ibnr = (+g.ibnr || 0);
                                            h.unpaidLiability = +g.unpaidLiability || 0;
                                            h.ultimate = +g.ultimate || 0;
                                        });
                                    }
                                });
                            }
                        });
                    });
                }
                this.form = Object.toFormGroup(this.lobInputs.lobInputs, this.fb, this.customValidators);
                this._subscriptions.push(this.form.valueChanges.subscribe(c => {
                    this.formValueChanged();
                }));
                this.formValueChanged();
                this.saveChanges();
                ref.hide();
            } catch (e) {
                ref.content.errorMessage = 'Please upload a valid file. The allowed file type is .xlsx';
            }
        });
    }
    downloadData(event?): any {
        const lobInputs = this.form.getRawValue();
        this._negotiationService.downloadData(lobInputs, this.selectedYear, this._negotiationService.selectedCarrier.carrierName, this._negotiationService.selectedCarrier.carrierStatus);
    }


}
