
import { Component, OnInit } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/summary/summary.component.html';

@Component({
    selector: 'app-negotiation-summary',
    templateUrl: `${componentTemplate}`
})

export class SummaryComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
