import { AbstractControl, ValidatorFn, FormArray } from '@angular/forms';

export function CollateralRampUpValidator(index: number, formArray: FormArray): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control && formArray && formArray.length > 1) {
      if (formArray.controls[index + 1]) {
        if (+control.value >= +formArray.controls[index + 1].value.day) {
          (formArray.controls[index + 1] as any).controls.day.markAsTouched();
          addErrors({ 'index': true }, (formArray.controls[index + 1] as any).controls.day);
        } else {
          removeErrors(['index'], (formArray.controls[index + 1] as any).controls.day);
        }
      }
      if (formArray.controls[index - 1] && +formArray.controls[index - 1].value.day >= +control.value) {
        return { 'index': true };
      }
    }
    return null;
  };
}

export function removeErrors(keys: string[], control: AbstractControl) {
  if (!control || !keys || keys.length === 0) {
    return;
  }

  const remainingErrors = keys.reduce((errors, key) => {
    delete errors[key];
    return errors;
  }, { ...control.errors });

  control.setErrors(remainingErrors);

  if (Object.keys(control.errors || {}).length === 0) {
    control.setErrors(null);
  }
}


export function addErrors(errors: { [key: string]: any }, control: AbstractControl) {
  if (!control || !errors) {
    return;
  }

  control.setErrors({ ...control.errors, ...errors });
}
