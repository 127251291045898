import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, takeUntil, tap, take, defaultIfEmpty } from 'rxjs/operators';
import { ReferenceDataService } from './referenceData.service';
@Injectable()
export class HasRefDataGuard implements CanActivate {
    constructor(private _refDataService: ReferenceDataService, private _router: Router) { }
    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return this._refDataService.isLoaded
            .pipe(take(1))
            .pipe(defaultIfEmpty(false));
    }
}
