import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import * as Highcharts from 'Highcharts';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/inputSummary/carrierOverView/assessmentChart/assessmentChart.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/inputSummary/carrierOverView/assessmentChart/assessmentChart.component.scss';
import { IAssessmentChart } from '@acl-cq-lib-pages-negotiation/inputSummary/carrierOverView/assessmentChart/IAssessmentChart';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { NegotiationService } from 'app/services/negotiation.service';
import { CarrierComparisonType } from 'app/models';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { Formatting } from 'app/shared/formatting';

@Component({
    selector: 'app-assessment-chart',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class AssessmentChartComponent extends ReactiveComponent implements OnInit, IAssessmentChart, IComponentTranslation {
    @Input() display: boolean;
    @Output() displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public translationKey = 'COLLATERAL.NEGOTIATION.CARRIEROVERVIEW.ASSESSMENTCHART';
    public translation = {};
    public wtwChartOptions: Highcharts.Options;
    public carrierChartOptions: Highcharts.Options;
    public chartWidth: string;
    public carrierChartData: any;
    public wtwChartData: any;
    public model = { carrierName: '' };


    private _maxChartValues: number = 0;
    private _minChartValues: number = 0;
    constructor(private _negotiationService: NegotiationService, private _translationService: TranslateService) {
        super();
        this._subscriptions.push(this._negotiationService.rFinishedEvent.subscribe(c => {
            this.generateChartWithDelay();
        })
        );

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.generateChartWithDelay();
    }

    ngOnInit() {
    }

    public closeAssessmentChart(): void {
        this.displayChange.emit(false);
    }

    public clear(): void {
        this.displayChange.emit(false);
    }

    public closeOpenAssessmentChart(): void {
        this.display = !this.display;
        this._negotiationService.showChart = this.display;
        if (this.display) {
            this.generateChartWithDelay();
        }
    }
    public setChartWidth() {
        this.chartWidth = (window.outerWidth - 76) + 'px';
    }

    public generateChartWithDelay() {
        this.setChartWidth();
        setTimeout(() => {
            this.generateChart();
        }, 50);
    }

    private generateChart() {
        this._maxChartValues = 0;
        this._minChartValues = 0;
        this.model.carrierName = this._negotiationService.selectedCarrier.carrierName;
        this.calculateMaxAndMinBetweenCharts();
        this.wtwChartOptions = this.createChartOptions(this.wtwChartData, this._translationService.instant(this.translationKey + '.WTWTITLE'));
        this.carrierChartOptions = this.createChartOptions(this.carrierChartData, this._translationService.instant(this.translationKey + '.CARRIERTITLE', this.model));
    }

    private getCalculateWtwChartData() {
        const wtwRenewalSummaryInputs = this._negotiationService.selectedCarrier
            .carrierAssessmentInputs.filter(c => c.carrierComparisonType === CarrierComparisonType.wtwRenewal)[0].summaryInputs;

        const wtwUnpaidAtEvaluation = wtwRenewalSummaryInputs.ultimateValues.completeYear +
            wtwRenewalSummaryInputs.ultimateValues.inForceYear - wtwRenewalSummaryInputs.paidValues.actualPaidAtRenewal;

        const impliedCarrierRiskLoad = 0;

        const expectedAdditionalPaidAtRenewal = -wtwRenewalSummaryInputs.paidValues.expectedAdditionalPaid;

        const upcomingLossForecast = wtwRenewalSummaryInputs.ultimateValues.lossForecast;

        const paidLossCredit = -wtwRenewalSummaryInputs.adjustmentValues.paidLossCredit;

        const otherCollateralAdjustment = -wtwRenewalSummaryInputs.adjustmentValues.otherCollateralAdjustments;

        return {
            wtwUnpaidAtEvaluation,
            impliedCarrierRiskLoad,
            expectedAdditionalPaidAtRenewal,
            upcomingLossForecast,
            paidLossCredit,
            otherCollateralAdjustment
        };
    }

    private getCalculateCarrierChartData() {
        const wtwRenewalSummaryInputs = this._negotiationService.selectedCarrier
            .carrierAssessmentInputs.filter(c => c.carrierComparisonType === CarrierComparisonType.wtwRenewal)[0].summaryInputs;

        const carrierRenewalSummaryInputs = this._negotiationService.selectedCarrier
            .carrierAssessmentInputs.filter(c => c.carrierComparisonType === CarrierComparisonType.carrierRenewal)[0].summaryInputs;

        const wtwUnpaidAtEvaluation = wtwRenewalSummaryInputs.ultimateValues.completeYear +
            wtwRenewalSummaryInputs.ultimateValues.inForceYear - wtwRenewalSummaryInputs.paidValues.actualPaidAtRenewal;

        const impliedCarrierRiskLoad =
            (carrierRenewalSummaryInputs.ultimateValues.completeYear - wtwRenewalSummaryInputs.ultimateValues.completeYear)
            + (carrierRenewalSummaryInputs.ultimateValues.inForceYear - wtwRenewalSummaryInputs.ultimateValues.inForceYear);

        const expectedAdditionalPaidAtRenewal
            = -carrierRenewalSummaryInputs.paidValues.expectedAdditionalPaid;

        const upcomingLossForecast = carrierRenewalSummaryInputs.ultimateValues.lossForecast;

        const paidLossCredit = -carrierRenewalSummaryInputs.adjustmentValues.paidLossCredit;

        const otherCollateralAdjustment = -carrierRenewalSummaryInputs.adjustmentValues.otherCollateralAdjustments;

        return {
            wtwUnpaidAtEvaluation,
            impliedCarrierRiskLoad,
            expectedAdditionalPaidAtRenewal,
            upcomingLossForecast,
            paidLossCredit,
            otherCollateralAdjustment
        };
    }

    private calculateMaxAndMinBetweenCharts(): void {
        this.wtwChartData = this.getCalculateWtwChartData();
        this.carrierChartData = this._negotiationService.selectedCarrier.showResults ? this.getCalculateCarrierChartData() : undefined;
        const reduce = (pre: number, curr: number) => {
            const sum = pre + curr;
            this._maxChartValues = sum > this._maxChartValues ? sum : this._maxChartValues;
            this._minChartValues = sum < this._minChartValues ? sum : this._minChartValues;
            return sum;
        };
        Object.values(this.wtwChartData).reduce((pre: number, curr: number) => reduce(pre, curr), 0);
        if (this.carrierChartData) {
            Object.values(this.carrierChartData).reduce((pre: number, curr: number) => reduce(pre, curr), 0);
        }
        this._maxChartValues *= 1.25;
        this._minChartValues *= 1.25;
    }

    private createChartOptions(chartValues, title): any {
        if (!chartValues) {
            return;
        }
        return {
            chart: {
                type: 'waterfall'
            },
            title: {
                text: `<b>${title}</b>`,
                align: 'left',
                margin: 50

            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                },
                lineWidth: 1,
                max: this._maxChartValues,
                min: this._minChartValues,
                labels: {
                    formatter: function(): string {
                        return Formatting.formatReduce(this.value, 0);
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                followPointer: true,
                formatter: function c() {
                    return `
                    <div style="color:white; padding:5px;">
                        <span style="padding-left:5px;">${this.point.name}</span>:
                    <span style="padding-right: 10px">$${Formatting.format(this.point.y, 0)} USD</span>
                    </div>
                    `;
                }
            },
            series: [{ data: this.createChartSeriesData(chartValues) }]
        };
    }

    private createChartSeriesData(chartValues): any {

        if (!chartValues) {
            return [];
        }

        const dataLabelsConfig: Highcharts.DataLabels = {
            enabled: true, inside: false, formatter: function(): string {
                return Formatting.formatReduce(this.y, 2);
            }, style: {
                fontSize: '12px',
                fontWeight: 'normal'
            } as Highcharts.CSSObject
        };

        const increaseColor = '#c111a0';
        const decreaseColor = '#00c389';
        const estimatedColor = '#6a0858';

        return [
            {
                name: this._translationService.instant(this.translationKey + '.WTWUPAIDATEVALUATION'),
                y: chartValues.wtwUnpaidAtEvaluation,
                color: estimatedColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.IMPLIEDCARRIERRISKLOAD'),
                y: chartValues.impliedCarrierRiskLoad,
                color: chartValues.impliedCarrierRiskLoad > 0 ? increaseColor : decreaseColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.EXPECTEDADNLPAIDATRENEWAL'),
                y: chartValues.expectedAdditionalPaidAtRenewal,
                color: chartValues.expectedAdditionalPaidAtRenewal > 0 ? increaseColor : decreaseColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.UNPAIDATRENEWAL'),
                isIntermediateSum: true,
                color: estimatedColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.UPCOMINGPYLOSSFORECAST'),
                y: chartValues.upcomingLossForecast,
                color: chartValues.upcomingLossForecast > 0 ? increaseColor : decreaseColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.PAIDLOSSCREDIT'),
                y: chartValues.paidLossCredit,
                color: chartValues.paidLossCredit > 0 ? increaseColor : decreaseColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.OTHERCOLLATERALADJUSTMENT'),
                y: chartValues.otherCollateralAdjustment,
                color: chartValues.otherCollateralAdjustment > 0 ? increaseColor : decreaseColor,
                dataLabels: dataLabelsConfig
            },
            {
                name: this._translationService.instant(this.translationKey + '.ADJUSTEDCOLLATERALREQUIREMENT'),
                isSum: true,
                color: estimatedColor,
                dataLabels: dataLabelsConfig
            }
        ];
    }

}
