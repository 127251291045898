
import { Component, OnInit } from '@angular/core';
import { NegotiationService } from 'app/services/negotiation.service';
import * as componentTemplate from '@acl-cq-lib-pages-datainput/coverage-data/newFileUploadModal.html';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
    selector: 'col-coverage-data-new-file-modal',
    templateUrl: `${componentTemplate}`
})

export class NewFileUploadModalComponent implements OnInit {
    translation: any = {};
    translationKey = 'COLLATERAL.COVERAGEDATA.NEWFILEUPLOAD';
    public data: any;
    public onClose: Subject<boolean>;
    public active = true;

    constructor(public _bsModalRef: BsModalRef, private _negotiationService: NegotiationService) {
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public close() {
        this.hide(null);
    }

    public yes() {
        this.hide(true);
    }

    public no() {
        this.hide(false);
    }

    public cancel() {
        this.hide(null);
    }

    public show() {
        this.active = true;
    }

    public get showCancel() {
        return this.data && this.data.cancelButton && this.data.cancelButton.display;
    }
    public get showYes() {
        return this.data && this.data.yesButton && this.data.yesButton.display;
    }
    public get showNo() {
        return this.data && this.data.noButton && this.data.noButton.display;
    }
    public get withHeader() {
        return this.data && this.data.withHeader;
    }

    public get getYesBtnClass() {
        if (this.data && this.data.yesButton && this.data.yesButton.cssClass) {
            return this.data.yesButton.cssClass;
        } else {
            return 'btn-primary';
        }
    }

    public downloadAllData() {
        this._negotiationService.downloadAllData();
        this.hide(true);
    }

    private hide(nextVal?: boolean) {
        this.active = false;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }


}
