import { ScenarioService } from '../../../../../services/scenario.service';
import { Component, Input } from '@angular/core';
import { ScenariosAnalysisResultContentTableConfig } from 'app/models/index';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results/scenarios-analysis-result-content/scenarios-analysis-result-content-table/scenarios-analysis-result-content-table.component.html';

@Component({
    selector: 'scenarios-analysis-result-content-table',
    templateUrl: `${componentTemplate}`
})

export class ScenariosAnalysisResultContentTableComponent {
    @Input() resultsData: ScenariosAnalysisResultContentTableConfig;
    rawDataKeys: string[];

    constructor(private _scenarioService: ScenarioService) {
    }

    ngOnInit() { }

    public get rawData(): any {
        let obj = this._scenarioService.selectedScenario.scenarioResult[this.resultsData.selectKey];
        this.rawDataKeys = this._scenarioService.selectedScenario.carriers.sort((a, b) => a.carrierOrderId - b.carrierOrderId).map(a => a.scenarioCarrierId);
        const groupByCarrier = obj.groupBy('scenarioCarrierId');
        //this.rawDataKeys = Object.keys(groupByCarrier);
        return groupByCarrier;
    }
}