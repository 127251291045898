import { AbstractControl } from '@angular/forms';
import { Industry } from '../../api/dtos';

export class DuplicatedIndustryValidator {
    static duplicated(c: AbstractControl): {[key: string]: boolean} | null {
        const duplicated = DuplicatedIndustryValidator.getDuplicated(c.value);
        if (duplicated.length > 0) {
            return {'duplicated' : true};
        }
        return null;
    }

    static getDuplicated(industries: Industry[]) {
        const duplicated: Industry[] = [];
        const sorted = industries.filter(i => !isNaN(i.id)).sort((a: Industry, b: Industry) => {
           return a.id - b.id;
        });
        for (let i = 0; i < sorted.length - 1; i++) {
            if (sorted[i + 1].id === sorted[i].id) {
                duplicated.push(sorted[i]);
            }
        }
        return duplicated;
    }
}
