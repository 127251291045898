
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';
import { IrulesTable } from '@acl-cq-lib-pages-negotiation/rules/rulesTable/IrulesTable';

import * as componentTemplate from '@acl-cq-lib-pages-negotiation/rules/rulesTable/rulesTable.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/rules/rulesTable/rulesTable.component.scss';
import { Formatting } from 'app/shared/formatting';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-negotiation-rules-table',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class RulesTableComponent implements OnInit, IComponentTranslation, IrulesTable {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.RULES.RULESTABLE';
    rule: any;
    noDataForRule: boolean;
    @Input()
    set selectedRule(value) {
        this.rule = value;
        this.processRuleData();
        if (this.rule && this.rule.ruleData && Object.keys(this.rule.ruleData).length) {
            this.customWidth = (100 - 100 / 12) / (this.rule.header.length - 1) + '%';
        }
    }
    @Input()
    ruleId: string;

    @Output()
    changeRuleSelected = new EventEmitter<number>();

    customWidth: string;
    ruleData: any;

    fieldsWith2DecimalPlaces = [
        'diff_perc_yoy',
        'diff_perc_wtwvcarr_rate',
        'diff_perc_pccarr_exposure',
        'diff_perc_pccarr_rate',
        'diff_perc_wtwvcarr',
        'diff_perc_wtwvcarr_ldf',
        'diff_perc_pccarr_ult',
        'diff_perc_wtwvcarr_exposure',
        'diff_perc_amt_expvact_emg',
    ];

    fieldsWith3DecimalPlaces = [
        'wtw_inc_LDF',
        'wtw_impl_eval_paid_LDF',
        'carrier_current_impl_eval_paid_LDF',
        'wtw_impl_eval_inc_LDF',
        'carrier_current_impl_eval_inc_LDF',
        'diff_amt_wtwvcarr_ldf',
        'wtw_paid_LDF',
    ];

    fieldsWith4DecimalPlaces = [
        'wtw_lossrate',
        'diff_amt_yoy',
        'wtw_freqrate',
        'carrier_current_lossrate',
        'carrier_current_freqrate',
        'carrier_prior_lossrate',
        'carrier_prior_freqrate',
        'diff_amt_wtwvcarr_rate',
        'diff_amt_pccarr_rate',
    ];

    constructor(private decimalPipe: DecimalPipe) {
    }

    ngOnInit() {
    }

    shouldHighlight(index) {
        return this.rule.ruleData.highlight.indexOf(index) !== -1;
    }
    processRuleData() {
        this.ruleData = {};
        if (this.rule && this.rule.ruleData && Object.keys(this.rule.ruleData).length) {
            this.ruleData.header = this.rule.header;
            const aux = this.rule.header.map(c => {
            let aux = [this.rule.ruleData[c]].flatten();
                return aux.map(d => {
                    if (!isNaN(d) && (d !== null)) {

                        if (this.fieldsWith2DecimalPlaces.find(d => d === c))
                                return this.decimalPipe.transform(d, '1.2-2', 'en-US');
                        else if (this.fieldsWith3DecimalPlaces.find(d => d === c))
                                return this.decimalPipe.transform(d, '1.3-3', 'en-US');
                        else if (this.fieldsWith4DecimalPlaces.find(d => d === c))
                                return this.decimalPipe.transform(d, '1.4-4', 'en-US');
                        else
                            return Formatting.format(d, 0);
                    } else {
                           return Formatting.format(d, 0);
                        }
                });
            });
            if (aux.length) {
                this.ruleData.data = aux[0].map((col, i) => aux.map(row => row[i]));
            }
            this.ruleData.highlight = this.rule.ruleData.flag;
            this.noDataForRule = false;
        } else {
            this.noDataForRule = true;
        }
    }

    changeRule(index) {
        this.changeRuleSelected.emit(index);
    }

    updateValuesTo2DecimalPlaces() {
        let roundingHeaders = this.fieldsWith2DecimalPlaces.filter(h => this.ruleData.header.indexOf(h) !== -1);
        let normalHeaders = this.ruleData.header.filter(nh => roundingHeaders.indexOf(nh) === -1);
        if (roundingHeaders.length > 0) {
            this.formatHeaders(roundingHeaders, 2);
        }
        if (normalHeaders.length > 0) {
            this.formatHeaders(normalHeaders, 0);
        }
    }

    formatHeaders(headers: string[], decimalPlaces: number) {
        headers.forEach(fh => {
            let updatedArray = [];
            this.ruleData.data[fh].forEach(value => {
                if (!isNaN(value)) {
                    updatedArray.push(Formatting.format(value, decimalPlaces));
                }
            });

            if (updatedArray.length > 0) {
                this.ruleData.data[fh] = updatedArray;
            }
        });
    }
}
