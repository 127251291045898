import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { IrulesLOBselector } from '@acl-cq-lib-pages-negotiation/rules/rulesLOBselector/IrulesLOBselector';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';

import * as componentTemplate from '@acl-cq-lib-pages-negotiation/rules/rulesLOBselector/rulesLOBselector.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/rules/rulesLOBselector/rulesLOBselector.component.scss';
import { NegotiationService } from 'app/services/negotiation.service';
import { RiskTypeEnum } from 'app/models';

@Component({
    selector: 'app-negotiation-rules-lobselector',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class RulesLobSelectorComponent implements OnInit, IComponentTranslation, IrulesLOBselector {
    public translation = {};
    public translationKey = 'COLLATERAL.NEGOTIATION.RULES.RULESLOBSELECTOR';


    @Output()
    lobChanged = new EventEmitter<RiskTypeEnum>();

    lobList: { riskType: RiskTypeEnum, selected: boolean, disabled: boolean }[];

    constructor(private _negotiationService: NegotiationService) { }

    ngOnInit() {
        const lobs = this._negotiationService.selectedCarrier.currentYearDataStatus.lobDataStatuses.map(c => c.riskTypeId);
        this.lobList = Object.toArray(this._negotiationService.riskTypes, 'id').sort((a, b) => a.displayOrderId - b.displayOrderId).map(c => {
            return {
                riskType: +c.id,
                selected: false,
                disabled: lobs.indexOf(+c.id) === -1
            };
        });
        this.lobList.find(c => !c.disabled).selected = true;
    }


    selectLob(item: any) {
        if (!item.disabled) {
            this.lobList.forEach(c => c.selected = false);
            item.selected = true;
            this.lobChanged.emit(item.riskType);
        }
    }
}
