import { OnDestroy } from '@angular/core/src/core';
import { Formatting } from '../../../../../shared/formatting';
import { AfterViewInit } from '@angular/core/public_api';
import { ChartType, UtilityService } from '../../../../../shared/utility.service';
import { ScenarioResult } from '../../../../../api/dtos';
import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { ScenarioService } from 'app/services/scenario.service';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results/scenarios-analysis-result-content/breakEven/breakEven.component.html';
import { BreakEvenLegend } from 'app/models/BreakEvenLegend';

@Component({
    selector: 'app-break-even-chart',
    templateUrl: `${componentTemplate}`
})

export class BreakEvenComponent implements OnInit, AfterViewInit, OnDestroy {
    //{ year: number; size: number; graph: number; zValue: number; cumulative: number; midPos: number; startPos: number; endPos: number; cross1: number; cross2: number; color: string; }[];
    firstYellow: number;
    lastMagenta: number;
    firstMagenta: number;
    capitalInvestment: string;
    graphWidth: number;
    graphMatrix: any;
    scenarioResults: ScenarioResult;
    plotDataBase: any[] = [];
    plotDataCurrentScenario: any[] = [];
    plotCategories: any[] = [];
    options: any;
    legend: any[] = [];
    cross1: number;
    cross2: number;
    subscriptions: any;

    translationKey: string = 'COLLATERAL.SCENARIOS.RESULTS.BREAKEVEN';
    public translation = {};
    legendItems: any[];
    model: any;

    constructor(private _scenarioService: ScenarioService, private _utilService: UtilityService) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initMap();
    }

    ngOnInit(): void {
        //filter the array (get unique year value)   - sort it and than pupulate the categories
        this.subscriptions = this._scenarioService.scenarioChanged.subscribe(isTabChanged => {
            if (isTabChanged) {
                this.scenarioResults = this._scenarioService.selectedScenario.scenarioResult;
                if (this.scenarioResults) {
                    this.initMap();
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initMap() {
        this.capitalInvestment = Formatting.format(this.scenarioResults.summaryResult.totalInvestment, 0);
        this.plotCategories = this.scenarioResults.estimatedCollateralResults.map(c => c.year);
        let cumulative = 0;
        let startPos = 5;
        let endPos = 0;
        let midPos = 0;
        let graph = 0;
        let graphSum = 0;
        this.graphWidth = window.innerWidth * .666667 - 30 - 48 - 16;
        const maxValue = 103;
        const minValue = 3;
        this.graphMatrix = this.scenarioResults.breakevenYearResults.map((c, i) => {

            return {
                year: c.year,
                size: c.breakeven,
                graph: graph = Math.sqrt(Math.abs(c.breakeven)),
                zValue: graphSum += graph,
                cumulative: cumulative += c.breakeven
            };
        });

        this.graphMatrix.forEach((c, i) => {


            c.zValue = c.graph / graphSum * 100;
            c.midPos = endPos + c.zValue / 2;
            c.endPos = endPos = c.midPos + c.zValue / 2;
            c.startPos = c.midPos - c.zValue / 2;
        });
        const zValueArray = this.graphMatrix.map(c => c.zValue);
        const minSize = Math.min(...zValueArray);
        const maxSize = Math.max(...zValueArray);
        this.graphMatrix.forEach((c, i) => {
            c.pixelNorm = this.calculatePixels(c.zValue, minSize, maxSize, maxValue, minValue) / (this.graphWidth / (maxValue - minValue));
            c.pixelStart = c.midPos - this.calculatePixels(c.zValue, minSize, maxSize, maxValue, minValue) / (this.graphWidth / (maxValue - minValue));
            c.pixelEnd = c.midPos + this.calculatePixels(c.zValue, minSize, maxSize, maxValue, minValue) / (this.graphWidth / (maxValue - minValue));
        });

        this.graphMatrix.forEach((c, i) => {
            c.color = c.size < 0 ? '#C111A0' : c.size >= 0 && c.cumulative < 0 ? '#FFB81C' : '#6065AE';
            const nextElement = i < this.graphMatrix.length - 1 ?
                this.graphMatrix[i + 1] : null;
            if (nextElement === null) {
                return;
            }
            c.cross1 = c.size < 0 && nextElement.size > 0 ? this.cross1 = (c.endPos + nextElement.startPos) / 2 : 0;
            c.cross2 = c.cumulative < 0 && c.cumulative + nextElement.size > 0 ? this.cross2 = (c.endPos + nextElement.startPos) / 2 : 0;
        });

        this.firstMagenta = Math.min(...this.graphMatrix.filter(c => c.color === '#C111A0').map(c => c.year));
        this.lastMagenta = Math.max(...this.graphMatrix.filter(c => c.color === '#C111A0').map(c => c.year));
        this.firstYellow = Math.min(...this.graphMatrix.filter(c => c.color === '#FFB81C').map(c => c.year));
        this.setOptions(this.graphWidth / 600);
        this.initLegend();
        setTimeout(_ => {
            this.setXAxisLabels();
        }, 100);
    }


    stackClicked(item) {
        item.enabled = !item.enabled;
        item.data.forEach(c => {
            c.enabled = item.enabled;
        });
        this.setOptions(this.graphWidth / 600);
    }

    legendItemClicked(stack, item) {
        item.enabled = !item.enabled;
        stack.enabled = stack.data.some(c => c.enabled);
        this.setOptions(this.graphWidth / 600);
    }

    calculatePixels(size, minSize, maxSize, maxValue, minValue) {
        return Math.ceil(((size - minSize) / (maxSize - minSize) * (maxValue - minValue) / 2 + minValue / 2) * 2) / 2;
    }

    setOptions(multiplier) {
        this.options = {
            chart: {
                type: 'bubble',
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: '',
            xAxis: {

                tickInterval: 0.2,
                plotLines: [{
                    color: '#585858',
                    dashStyle: 'dot',
                    width: 2,
                    value: this.cross1,
                    label: {
                        rotation: 0,
                        y: 1,
                        style: {
                            fontStyle: 'italic', color: 'black',
                        },
                        text: 'Breakeven 68 Months' // TODO: Add Localization
                    },
                    zIndex: 3
                },
                {
                    color: '#585858',
                    dashStyle: 'solid',
                    width: 3,
                    value: this.cross2,
                    zIndex: 1
                }]

            },

            yAxis: {
                visible: false
            },

            tooltip: {
                useHTML: true,
                headerFormat: '<div style="color:white; padding:10px;">',
                pointFormat: '{point.year}: <span style="padding-right: 10px">${point.d}</span>',
                footerFormat: '</div>',
                followPointer: true
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '<span style="font-size:14px">{point.name}</span>',
                        fontSize: '14px'
                    },
                    style: {
                        fontSize: '14px',
                    },
                    fillOpacity: 1,

                },
                bubble: {
                    minSize: 3 * multiplier,
                    maxSize: 100 * multiplier,
                    sizeBy: 'width',
                }

            },

            series: [{
                data: this.graphMatrix.map(c => {
                    return {
                        x: c.midPos,
                        y: 0,
                        z: c.zValue,
                        name: this.formatReduce(Math.ceil(Math.abs(c.size))),
                        d: Formatting.format(Math.ceil(Math.abs(c.size)), 0),
                        year: c.year,
                        color: c.color,
                        fontSize: '14px'
                    };
                }),
                marker: {
                    fillOpacity: 1
                }
            }]
        };
    }

    setVisibleFlag(stack, name) {
        return this.legend.find(c => c.name === stack).data.find(c => c.name === name).enabled;
    }

    ngAfterViewInit() {
        this.setXAxisLabels();
    }
    private initLegend() {
        this.legendItems = [];
        this.model = {};
        const results = this.scenarioResults.summaryResult;
        if (results.totalInvestment) {
            this.legendItems.push(<BreakEvenLegend>{
                color: '#C111A0',
                translation: results.benefitsBeginMonth ? 'LEGEND_INVESTMENT1' : 'LEGEND_INVESTMENT2',
                scssClass: ''
            });
            this.model.totalInvestment = Formatting.format(this.scenarioResults.summaryResult.totalInvestment, 0);
        }
        if (results.benefitsBeginMonth) {
            this.legendItems.push(<BreakEvenLegend>{
                color: '',
                translation: 'LEGEND_BENEFITS_BEGIN',
                scssClass: 'dottedLine'
            });
            this.legendItems.push(<BreakEvenLegend>{
                color: '',
                translation: 'LEGEND_TOTAL_BENEFITS',
                scssClass: 'indigoYellow'
            });
            this.model.benefitsBeginMonth = results.benefitsBeginMonth;
            this.model.totalBenefits = Formatting.format(this.scenarioResults.summaryResult.totalSavings - this.scenarioResults.summaryResult.totalGain, 0);
        }
        if (results.breakevenMonth) {
            this.legendItems.push(<BreakEvenLegend>{
                color: '',
                translation: 'LEGEND_BREAKEVEN',
                scssClass: 'solidLine'
            });
            this.model.breakevenMonth = results.breakevenMonth;
        }
        if (results.totalGain > 0) {
            this.legendItems.push(<BreakEvenLegend>{
                color: '#6065AE',
                translation: results.totalInvestment ? 'LEGEND_COMPARISON1' : 'LEGEND_COMPARISON2',
                scssClass: ''
            });
            this.model.totalGain = Formatting.format(this.scenarioResults.summaryResult.totalGain, 0);
            this.model.totalSavings = Formatting.format(this.scenarioResults.summaryResult.totalSavings, 0);
        }
    }

    private setXAxisLabels() {
        Promise.resolve(null).then(c => {
            const yearMap = {};
            const l = document.getElementsByClassName('highcharts-axis-labels highcharts-xaxis-labels');
            if (l && l[0] && l[0].children) {
                const labels = l[0].children;
                const ticks = document.getElementsByClassName('highcharts-tick');
                let currentMatrixIndex = 0;
                for (let i = 0; i < labels.length; i++) {
                    labels[i].innerHTML = null;
                    ticks[i].setAttribute('stroke-width', '0');
                }
                Promise.resolve(null).then(d => {
                    if (labels.length > 0) {
                        this.graphMatrix.forEach((c, i) => {
                            labels[i].innerHTML = c.year;
                            labels[i].setAttribute('x', '' + (this.graphWidth * c.midPos / 100 + 10));
                            labels[i].setAttribute('transform', '');
                        });
                    }

                });
            }

        });
    }

    private formatReduce(value: number): string {
        return Formatting.formatReduce(value, 0);
    }
}

