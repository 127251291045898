
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as componentTemplate from '@acl-cq-lib-pages-negotiation/shared/pageHeader/pageHeader.component.html';
import * as componentStyle from '@acl-cq-lib-pages-negotiation/shared/pageHeader/pageHeader.component.scss';
import { IPageHeader } from '@acl-cq-lib-pages-negotiation/shared/pageHeader/IPageHeader';
import { IComponentTranslation } from '@acl-cq-lib/models/IComponentTranslation';

@Component({
    selector: 'app-negotiation-pageHeader',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class PageHeaderComponent implements OnInit, IComponentTranslation, IPageHeader {

    @Input()
    translation: {};
    @Input()
    translationKey: {};
    @Input()
    download: boolean;
    @Input()
    model: boolean;
    @Output()
    button1Clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input()
    availableDownloadList: any[];
    @Output() downloadList: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    clickButton1() {
        this.button1Clicked.emit(true);
    }

    generateDownloadReportUrl(event) {
        this.downloadList.emit(event);
    }
}
