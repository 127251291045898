import { FormGroup } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Carrier } from 'app/models';
import { ReferenceDataService } from 'app/services/referenceData.service';
import { TranslateService } from '@ngx-translate/core';

type AutoCompleteDataSource = (term: string) => Observable<{ data: any[] }>;

import * as componentTemplate from '@acl-cq-lib-pages-scenario-shared/autocomplete/autocomplete.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-shared/autocomplete/autocomplete.component.scss';
import { IAutocomplete } from '@acl-cq-lib-pages-scenario-shared/autocomplete/IAutocomplete';

@Component({
  selector: 'app-autocomplete',
  templateUrl: `${componentTemplate}`,
  styleUrls: ['' + componentStyle]
})

export class AutocompleteComponent implements OnInit {
  // showAutocomplete: any; //TODO: Implemented by the interface, but not used. Maybe remove?
  //generatedId: any; //TODO: Implemented by the interface, but not used. Maybe remove?

  // @Output() onSelected: EventEmitter<string> = new EventEmitter();

  @Input()
  fcName: string;

  @Input()
  f: FormGroup;

  noCarrierFound: any = this.getInitialNoCarrierFound();
  findCarrier: AutoCompleteDataSource = this.getInitialCarrierDataSource();
  carrierName: string = '';
  generatedId = '#000000'.replace(/0/g, _ => { return (~~(Math.random() * 16)).toString(16); });
  private _autoCompleteEnabled = false;

  constructor(private _referenceData: ReferenceDataService, private _translateService: TranslateService) { }

  ngOnInit() {
    this.carrierName = this.f.get(this.fcName).value || 'COLLATERAL.SCENARIOS.SUMMARY.SCENARIOPARAMETERS.SEARCH_CARRIER';
  }

  onCarrierSelected(value: any): void {
    if (value && value.id && value.id !== -999999) {
      this.carrierName = value.labelName || 'COLLATERAL.SCENARIOS.SUMMARY.SCENARIOPARAMETERS.SEARCH_CARRIER';
      // this.onSelected.emit(this.carrierName);
      this.f.get(this.fcName).setValue(this.carrierName);
    }
    if (value.length === 0 || value.id) {
      this._autoCompleteEnabled = false;
    }
  }

  public get showAutocomplete() {
    return this._autoCompleteEnabled;
  }

  public enableAutoComplete() {
    this._autoCompleteEnabled = true;
    document.getElementById(this.generatedId).getElementsByTagName('input')[0].focus();
  }

  public clear() {
    if (this.showAutocomplete) {
      this._autoCompleteEnabled = false;
    }
  }


  private getInitialCarrierDataSource(): AutoCompleteDataSource {
    return (request: string) => {
      const results = this._referenceData.referenceData.carriers.filter(c => c.labelName.toLowerCase().indexOf(request.toLowerCase()) >= 0);
      return of({
        data: results.length ? results : this.noCarrierFound
      });
    };
  }

  private getInitialNoCarrierFound(): Carrier[] {
    return [<Carrier>{
      id: -999999,
      labelName: this._translateService.instant('COLLATERAL.SCENARIOS.SUMMARY.SCENARIOPARAMETERS.NO_CARRIER_FOUND')
    }];
  }

}
