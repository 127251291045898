import { Injectable, EmbeddedViewRef, ComponentFactoryResolver, ApplicationRef, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { NavbarExtenderComponent } from 'app/shared/components/navbar-extender/navbar-extender.component';
import { CanNavigateProvider, CanNavigateRequest, CanNavigateResponse } from '@wtw/platform/interfaces';
import { SiteHeaderService, NavigationService } from '@wtw/platform/services';


@Injectable()
export class NavbarExtenderService {

    private componentRef: any = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {
        this.appendComponentToBody(NavbarExtenderComponent);
    }

    appendComponentToBody(component) {
        this.componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);

        this.appRef.attachView(this.componentRef.hostView);

        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

}

