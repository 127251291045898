﻿
import * as Dto                          from '../dtos'; 
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable }                    from '@angular/core';
import { Observable }                    from 'rxjs';
import { ApiResult,BinaryTransferObject} from '@wtw/platform/api';
import { map }                           from  'rxjs/operators';
export { ApiResult };

@Injectable()
export class ClientCoverageDataProxy {
    	constructor(private _http: ProxyHttp) { 
        }
        
             
                coverageData () : Observable<ApiResult<Dto.ClientCoverageDataViewModel>> {
                return this._http.get(`/backend/GetClientCoverageData/CoverageData`,null||{}).pipe(map(ret=>({ data: ret.body, originalResponse: ret})));
	        }
             
        
}

	
