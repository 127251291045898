import { ScenarioService } from '../../../../../services/scenario.service';

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/scenarioSummaryHeader/scenarioSummaryHeader.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { IScenarioSummaryHeader } from '@acl-cq-lib-pages-scenario-summary/scenarioSummaryHeader/IScenarioSummaryHeader';

@Component({
    selector: 'app-scenario-summary-header',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioSummaryHeader implements OnInit, IScenarioSummaryHeader {
    public translationKey: string = 'COLLATERAL.SCENARIOS.SUMMARY';

    public translation = {};

    constructor(private scenarioService: ScenarioService) { }

    ngOnInit() { }

    clickEdit() {
        this.scenarioService.selectCurrentScenarioForEdit();
    }

    get showWarningMessage(): boolean {
        return !this.scenarioService.selectedScenario.isValid && (this.scenarioService.selectedScenario.isSaved || this.scenarioService.selectedScenario.isRTriggered);
    }
}
