import { FormGroup, FormArray } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ScenarioService } from 'app/services/scenario.service';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-edit/collateralCostRate/collateralCostRate.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-edit/scenarioEdit.component.scss';
import { ICollateralCostRateEdit } from '@acl-cq-lib-pages-scenario-edit/collateralCostRate/ICollateralCostRateEdit';

@Component({
    selector: 'app-collateral-costRate',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class CollateralCostRateComponent extends ReactiveComponent implements ICollateralCostRateEdit {
    @Input() form: FormGroup;

    public translationKey: string = 'COLLATERAL.SCENARIOS.EDIT.COLLATERAL_COST_RATE';
    public translation: any = {};

    constructor(private _scenarioService: ScenarioService) {
        super();
    }

    ngOnInit() {
        this._subscriptions.push(this.carriers.valueChanges.subscribe((_) => {
            this.calculateTotals();
        }));

        if (this._scenarioService.selectedScenario.isSaved || this._scenarioService.selectedScenario.isRTriggered) {
            // this._scenarioService.markFormGroupTouched(this.form);
            // this._scenarioService.markFormArrayTouched(this.carriers);
        }
    }

    public get carriers(): FormArray {
        return this.form.get('carriers') as FormArray;
    }

    public securitiesAtCarrierIndex(index: number): FormArray {
        return (this.carriers.controls[index].get('collateralCostRate') as FormGroup).get('securities') as FormArray;
    }

    public get SecurityType(): { [key: string]: string } {
        return this._scenarioService.securityTypeDictionary;
    }

    private calculateTotals() {
        this.carriers.controls.forEach((carrierControl, index) => {
            const formArray = this.securitiesAtCarrierIndex(index);

            const securitiesArray = formArray.value;

            const carrierWeightTotal =
                securitiesArray.map(o => +o.weight)
                    .reduce((a: number, b: number) => a + (b || 0), 0);

            const carrierCostAverage =
                securitiesArray.map(o => {
                    return {
                        cost: +o.cost,
                        weight: +o.weight
                    };
                }).reduce((a: number, b: any) => a + (b.weight * b.cost || 0), 0) / carrierWeightTotal;

            carrierControl.get('collateralCostRate').patchValue({
                'weightTotal': carrierWeightTotal,
                'costAverage': carrierCostAverage
            }, { emitEvent: false });

            carrierControl.get('collateralCostRate').get('weightTotal').markAsTouched();
            carrierControl.get('collateralCostRate').get('costAverage').markAsTouched();


            carrierControl.get('collateralCostRate').updateValueAndValidity({ emitEvent: false });
        });
    }

}
