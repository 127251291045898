import { FileUploadType } from '../models/index';
import { RiskTypeEnum } from '../api/generated/RiskTypeEnum';
import { Observable } from 'rxjs/internal/Rx';
import { FileUploadStatus } from '../api/generated/FileUploadStatus';
import { Injectable } from '@angular/core';
import { ProxyHttp, ApiResult } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
import { CollateralCoverage, DataUpload } from 'app/api/dtos';


@Injectable()
export class ExcelUploadService {

    constructor(private _phttp: ProxyHttp) { }

    public upload(form: FormData, type: FileUploadType, runId: number): Observable<DataUpload> {
        return this._phttp.upload(`/backend/UploadData/upload/${type}/${runId}`, form).pipe(map(ret => ret.body));
    }

    // public getCoverageList(runId: number): Observable<ApiResult<Data>>

    public removeFile(runId, type: FileUploadType) {
        return this._phttp.get(`/backend/UploadData/removeFile/${type}/${runId}`).pipe(map(ret => ret));
    }

    public removeCoverageDataForRun(runId, fileUploadType: FileUploadType, riskType: RiskTypeEnum) {
        return this._phttp.get(`/backend/UploadData/removeCoverage/${fileUploadType}/${runId}/${riskType}`).pipe(map(ret => ret));
    }


    public getCoverageList(runId): Observable<CollateralCoverage[]> {
        return this._phttp.get(`/backend/UploadData/getCoverageList/${runId}`).pipe(map(ret => ret.body));
    }

}
