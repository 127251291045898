import { Component, OnInit } from '@angular/core';
import { ScenarioService } from 'app/services/scenario.service';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results-summary/summaryButton/summaryButton.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-results-summary/summaryButton/summaryButton.component.scss';
import { ISummaryButton } from '@acl-cq-lib-pages-scenario-results-summary/summaryButton/ISummaryButton';

@Component({
    selector: 'scenarioTabs-summary-button',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle],
})

export class SummaryButtonComponent implements OnInit, ISummaryButton {
    translation = {
        summary: 'Summary'
    };
    translationKey: string;

    constructor(private _scenarioService: ScenarioService) { }

    ngOnInit() { }

    selectSummary() {
        this._scenarioService.setSummaryResultsSelected();
    }

    public get summaryClass() {
        return this._scenarioService.summaryResultsSelected ? 'selected' : 'deselected';
    }
}