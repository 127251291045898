/**
    The service file will keep the upload data file functionality
*/
import { Injectable } from '@angular/core';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { Observable, Subscription } from 'rxjs';
import { UploadDataProxy } from '../api/generated/UploadDataController';
import { UtilityService } from '../shared/utility.service';
import { FileResponse, SelectedFile } from '../api/dtos';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class UploadService {
    subscriptions: Subscription[] = [];
    constructor(private _api: UploadDataProxy,
        private _utilService: UtilityService) {
    }

    /* upload(file: File, fileModel: SelectedFile): Promise<ApiResult<FileResponse>> {
        return this._utilService._readFile(file, fileModel).then(result => {
            return this._api.(new BinaryTransferObject(result.fileModel, result.res)).toPromise();
        });
    }//end of upload

    readDataType(file: File, fileModel: InitialUploadedFile): Observable<ApiResult<FileResponse>> {
        return this._utilService.readFile(file, fileModel).pipe(mergeMap(result => {
            return this._api.readDataType(new BinaryTransferObject(result.fileModel, result.result));
        }));
    }//end of upload */

}//end of class