
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-shared/scenarioTitle/scenarioTitle.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-shared/scenarioTitle/scenarioTitle.component.scss';

import { IScenarioTitle } from '@acl-cq-lib-pages-scenario-shared/scenarioTitle/IScenarioTitle';
import { ScenarioService } from 'app/services/scenario.service';
import { RunService, SiteHeaderService } from '@wtw/platform/services';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ProxyProxy } from '@wtw/platform/api/proxies';
import { WindowRef } from '@wtw/toolkit/src/utils/window-ref.service';

@Component({
    selector: 'app-scenario-title',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ScenarioTitleComponent extends ReactiveComponent implements OnInit, IScenarioTitle {
    @Output() nextClick: EventEmitter<any> = new EventEmitter();
    public translationKey: string = 'COLLATERAL.SCENARIOS';
    public downloadPDF_link: string = 'MISSING_KEY';
    public translation: any = {};
    public downloadList: any;
    public pdfUrl;
    private runId: number;
    private clientId: number;
    constructor(private _scenarioService: ScenarioService, private _runService: RunService, private window: WindowRef, private _headerService: SiteHeaderService) {
        super();
        this._subscriptions.push(this._runService.activeRun.subscribe(c => {
            this.downloadList = this._scenarioService.scenarios.filter(c => c.scenarioResult).map(c => {
                return {
                    name: c.scenarioName,
                    checked: true,
                    scenarioId: c.scenarioId
                };
            });
            this.runId = c.info.runId;
            this.clientId = c.info.clientId;
        }));

        this._subscriptions.push(this._runService.persisted.subscribe(c => {
            this.downloadList = this._scenarioService.scenarios.filter(c => c.scenarioResult).map(c => {
                return {
                    name: c.scenarioName,
                    checked: true,
                    scenarioId: c.scenarioId
                };
            });
        }));
    }

    ngOnInit() { }

    public generateDownloadReportUrl(listOfScenarios) {
        let scenarios = listOfScenarios.filter(c => c.checked).map(c => c.scenarioId).join(',');
        this.pdfUrl = `pdf/root?runId=${this.runId}&clientId=${this.clientId}&pdfIdentifier=downloadReport&parameter=${scenarios}&pdfLanguage=enGB&isLandscape=true&filename=report.pdf`;
        let url = ProxyProxy.binary_url(this.pdfUrl).url;

        this.window.nativeElement.open(url);
    }

    public goToNegotiation(): void {
        this._headerService.navigation.nextClicked.next();
    }
}
