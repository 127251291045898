import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector : 'loader-component', templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    @Input() showLoader : boolean;
    @Input() title      : string;
    @Input() authorizing: boolean;
    Roles = ['', '', ''];
    constructor() { }
    ngOnInit() {

    }

    showLoaderIcon(): boolean {
        return this.showLoader;
    }

    showLoginPage(): boolean {
        return false;
    }
}
