import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { of } from 'rxjs';
import { ReferenceDataProxy } from '../api/generated/ReferenceDataController';
import * as Dto from '../api/dtos';
import { map } from 'rxjs/operators';
import { NavbarExtenderService } from './navbar-extender.service';

@Injectable()
export class ReferenceDataService {

  loading = new Subject<void>();
  referenceData: Dto.ReferenceData;
  private _loaded: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _navbarExtenderService: NavbarExtenderService;

  constructor(private referenceDataProxy: ReferenceDataProxy, private _injector: Injector) { }

  public init() {
    // <edgar>We are Injecting this service here so he can be instanciated at a global level on the application,
    // so the Navigation DOM can be manipulated on all components
    // </edgar>
    if (!this._navbarExtenderService) {
      this._navbarExtenderService = this._injector.get(NavbarExtenderService);
    }
    this.getData();
  }

  private getData() {
    this.referenceDataProxy.getReferenceData().subscribe(c => {
      this.referenceData = c.data;
      this._loaded.next(true);
    });
  }

  public get isLoaded() {
    return this._loaded;
  }
}
