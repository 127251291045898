import { Injectable, Injector } from '@angular/core';
import { NavigationService, RunService, AlertService, ConfigService, SiteHeaderService } from '@wtw/platform/services';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorProxy } from '@wtw/platform/api/proxies';
import { Location } from '@angular/common';
import { CanNavigateResponse, CanNavigateRequest } from '@wtw/platform/interfaces';


@Injectable()
export class NavigationExtenderService extends NavigationService {

    constructor(private runService: RunService,
        private alertService: AlertService,
        private router: Router,
        private config: ConfigService,
        private location: Location,
        private _injector: Injector,
        private translate: TranslateService,
        private errorProxy: ErrorProxy,
        private headerServiceTEMPORARY: SiteHeaderService) {
        super(runService, alertService, router, config, location, _injector, translate, errorProxy, headerServiceTEMPORARY);
    }

    setCanActivate() {
        const service = this as any;
        service._headerServiceTEMPORARY.pageList.forEach(c => {
            const path = [];
            const current = c.identifier;
            if (current === 'scenarios' || current === 'negotiation') {
                const canNavigate = this.canNavigateTo({
                    path,
                    runModel: service.currentRun,
                    targetIdentifier: 'scenarios'
                }).canNavigate;
                c.showRunNavigation = canNavigate;
            } else {
                if (c.isResultsPage) {
                    c.showRunNavigation = this.canNavigateTo({
                        path,
                        runModel: service.currentRun,
                        targetIdentifier: current
                    }).canNavigate;
                }
            }
        });
    }

    canNavigateTo(input: CanNavigateRequest): CanNavigateResponse {
        if (input.targetIdentifier === 'negotiation') {
            input.targetIdentifier = 'scenarios';
        }
        return super.canNavigateTo(input);
    }
}