import { OnDestroy } from '@angular/core/src/core';
import { Formatting } from '../../../../../shared/formatting';
import { ChartType, UtilityService } from '../../../../../shared/utility.service';
import { ScenarioResult, CarrierDetails } from '../../../../../api/dtos';
import { ScenarioService } from '../../../../../services/scenario.service';


import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-results/scenarios-analysis-result-content/estimatedCollateral/estimatedCollateral.component.html';
@Component({
    selector: 'app-estimated-collateral-chart',
    templateUrl: `${componentTemplate}`
})

export class EstimatedCollateralComponent implements OnInit, OnDestroy {
    scenarioResults: ScenarioResult;
    carriers: CarrierDetails[];
    plotDataBase: any[] = [];
    plotDataCurrentScenario: any[] = [];
    plotCategories: any[] = [];
    options: Object;
    subscriptions: Subscription;
    legend: any[] = [];
    translationKey: string = 'COLLATERAL.SCENARIOS.RESULTS.ESTIMATED_COLLATERAL_CHART_';

    private _legendType1: string;
    private _legendType2: string;
    private _scenarioCarrierIds: number[];
    private _futureColors = ['#9A0D80', '#333674'];
    private _legacyColors = ['#D764C1', '#9699E2'];

    constructor(private _scenarioService: ScenarioService, private _utilService: UtilityService, private _translateService: TranslateService) {
        this._legendType1 = this._translateService.instant(this.translationKey + 'LEGEND1');
        this._legendType2 = this._translateService.instant(this.translationKey + 'LEGEND2');
    }

    ngOnInit(): void {
        //filter the array (get unique year value)   - sort it and than pupulate the categories
        this.subscriptions = this._scenarioService.scenarioChanged.subscribe(isTabChanged => {
            if (isTabChanged) {
                this.scenarioResults = this._scenarioService.selectedScenario.scenarioResult;
                this.carriers = this._scenarioService.selectedScenario.carriers.sort((a, b) => a.carrierOrderId - b.carrierOrderId);
                if (this.scenarioResults) {
                    this._scenarioCarrierIds = this._scenarioService.selectedScenario.carriers.sort((a, b) => a.carrierOrderId - b.carrierOrderId).map(c => c.scenarioCarrierId);
                    //this.scenarioResults.estimatedCollateralResults.map(ccr => ccr.scenarioCarrierId).filter((value, index, self) => self.indexOf(value) === index) as number[];
                    this.createLegends();
                    this.setOptions();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    stackClicked(item) {
        item.enabled = !item.enabled;
        item.data.forEach(c => {
            c.enabled = item.enabled;
        });
        this.setOptions();
    }

    legendItemClicked(stack, item) {
        item.enabled = !item.enabled;
        stack.enabled = stack.data.some(c => c.enabled);
        this.setOptions();
    }

    createLegends() {
        this.legend = this._scenarioService.selectedScenario.carriers.map((c, i) => {
            return {
                nameObj: this.getObjectName(i),
                actualName: c.name,
                enabled: true,
                data: [
                    {
                        name: this._translateService.instant(this.translationKey + 'LEGENDKEY1'),
                        enabled: true,
                        color: this._futureColors[i]
                    },
                    {
                        name: this._translateService.instant(this.translationKey + 'LEGENDKEY2'),
                        enabled: true,
                        color: this._legacyColors[i]
                    }
                ]
            };
        });
    }

    setOptions() {
        this.plotCategories = this.scenarioResults.estimatedCollateralResults.filter(c => c.scenarioCarrierId === this.scenarioResults.estimatedCollateralResults[0].scenarioCarrierId).map(c => c.year);
        this.options = {
            title: { text: '' },
            chart: {
                height: this._utilService.ChartConfiguration_Height,
                zoomType: this._utilService.ChartConfiguration_ZoomType,
                type: ChartType.Column,
                backgroundColor: this._utilService.ChartConfiguration_BackgroundColor,
                style: {
                    fontFamily: this._utilService.ChartConfiguration_FontFamily,
                    fontSize: this._utilService.ChartConfiguration_FontSize,
                    fontWeight: this._utilService.ChartConfiguration_FontWeight,
                    color: '#A0A0A0'
                }
            },
            xAxis: {
                categories: this.plotCategories,
                title: {
                    margin: 10,
                    text: this._translateService.instant(this.translationKey + 'X_AXIS'),
                    style: {
                        fontSize: '14px'
                    }
                },

                tickWidth: 0
            },

            plotOptions: {
                series: {
                    stacking: 'normal',
                    pointWidth: 20,
                    pointPadding: 0
                }
            },
            series: this.createSeriesData(),
            yAxis: {
                title: {
                    margin: 10,
                    text: this._translateService.instant(this.translationKey + 'Y_AXIS'),
                    style: {
                        fontSize: '14px'
                    }

                },
                lineWidth: .5
            },

            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                followPointer: true,
                formatter: function s() {
                    let s = '<div style="color:white; padding:5px;">';
                    s += `<div style="font-size: 10px" class="row"> <div class="col-sm-12">${this.point.category}</div></div>`;
                    s += `<span style="width: 10px; height:10px; border-radius: 10px; background-color:${this.color}; display: inline-block"></span>
                        <span style="padding-left:5px;">${this.series.name}</span>:
                    <span style="padding-right: 10px">$${Formatting.format(this.point.y, 0)}</span>`;
                    return `${s}</div>`;
                }
            }
        };
    }

    setVisibleFlag(stack, name) {
        return this.legend.find(c => c.nameObj === stack).data.find(c => c.name === name).enabled;
    }

    createSeriesData(): any[] {
        let data = [];

        for (let i = 0; i < this._scenarioCarrierIds.length; i++) {
            let carrierId = this._scenarioCarrierIds[i];

            data.push(
                {
                    data: this.scenarioResults.estimatedCollateralResults.filter(result => result.scenarioCarrierId === carrierId).map(c => c.future),
                    name: this._translateService.instant(this.translationKey + 'LEGENDKEY1'),
                    color: this._futureColors[i],
                    borderWidth: 0,
                    stack: this.getObjectName(i),
                    visible: this.setVisibleFlag(this.getObjectName(i), this._translateService.instant(this.translationKey + 'LEGENDKEY1'))
                },
                {
                    data: this.scenarioResults.estimatedCollateralResults.filter(c => c.scenarioCarrierId === carrierId).map(d => d.legacy),
                    name: this._translateService.instant(this.translationKey + 'LEGENDKEY2'),
                    color: this._legacyColors[i],
                    borderWidth: 0,
                    stack: this.getObjectName(i),
                    visible: this.setVisibleFlag(this.getObjectName(i), this._translateService.instant(this.translationKey + 'LEGENDKEY2'))
                }
            );
        }

        return data;
    }

    getObjectName(id: number): string {
        let legendName;

        if (this.isIncumbentComparison && id === 0) {
            legendName = this._legendType1;
        } else if (this.isIncumbentComparison) {
            legendName = this._legendType2;
        } else {
            legendName = this._legendType2 + ' ' + (id + 1);
        }

        return legendName;
    }

    public get isIncumbentComparison(): boolean {
        return this._scenarioService.isIncumbentComparison();
    }
}

