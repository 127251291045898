import { CoverageName, PaidLossCreditType } from '../../../../../models/index';
import { Component } from '@angular/core';
import { ProjectionData, LossCreditType, CarrierDetails, CarrierType, ExposureGrowth } from 'app/api/dtos';
import { ScenarioService } from 'app/services/scenario.service';
import { ReferenceDataService } from 'app/services/referenceData.service';
import * as Dto from '../../../../../api/dtos';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';

import * as componentTemplate from '@acl-cq-lib-pages-scenario-summary/projectionSummary/projectionSummary.component.html';
import * as componentStyle from '@acl-cq-lib-pages-scenario-summary/scenarioSumamry.component.scss';
import { IProjectionsSummary } from '@acl-cq-lib-pages-scenario-summary/projectionSummary/IProjectionSummary';
import { projection } from '@angular/core/src/render3';

@Component({
    selector: 'app-projection-summary',
    templateUrl: `${componentTemplate}`,
    styleUrls: ['' + componentStyle]
})

export class ProjectionSummaryComponent extends ReactiveComponent implements IProjectionsSummary {
    projections: any;
    public translationKey: string = 'COLLATERAL.SCENARIOS.SUMMARY.SCENARIO_PROJECTION';
    public translation = {};

    constructor(private _scenarioService: ScenarioService, private _refData: ReferenceDataService) {
        super();
    }

    ngOnInit() {
        this._subscriptions.push(this._scenarioService.scenarioChanged.subscribe(d => {
            this.setModelValues();
        }));
        this.setModelValues();
    }

    setModelValues() {

        const years = this.carriers[0].projections.map(p => p.year).slice(0, this._scenarioService.selectedScenario.projectionPeriod);
        let exposureGrowths = this._scenarioService.selectedScenario.coverageAssumptions.filter(c =>
            !this._scenarioService.selectedScenario.coverageDisplayData[c.riskTypeId].isDisabled).map(ca => ca.riskTypeExposureGrowths) as ExposureGrowth[][];

        this.projections = years.map(year => {
            return {
                year: year,
                plcValues: (this.carriers.map(d => d.projections.filter(e => e.year === year).map(e => +d.plcType === PaidLossCreditType.percent ? e.plcData.percentageValue : +d.plcType === PaidLossCreditType.dollars ? e.plcData.monetaryValue : e.plcData.monthsValue)) as any).flat(),
                egValues: (exposureGrowths.map(d => d.filter(e => e.year === year).map(e => e.value)) as any).flat()
            };
        });
    }

    public get carriers(): CarrierDetails[] {
        const aux = this._scenarioService.selectedScenario.carriers;
        aux.forEach((c, i) => {
            c.translation = this._scenarioService.ivpTranslation(i);
        });
        return aux;
    }

    public get model() {
        return {
            projectionPeriod: this._scenarioService.selectedScenario.projectionPeriod,
            projections: this.projections
        };
    }

    public get coverageDisplayData(): { [key: number]: CoverageName; } {
        return this._scenarioService.selectedScenario.coverageDisplayData;
    }

    public get coverageDisplayDataKeys() {
        return this._scenarioService.riskTypeList().map(c => c.id);
    }

    public get paidLossCreditTypes(): any {
        const riskTypes = this._refData.referenceData.lossCreditTypes;
        let plcTypesWithAbbreviation = [];

        riskTypes.forEach(rt => plcTypesWithAbbreviation.push({
            id: rt.id,
            abbreviationName: rt.abbreviationName,
            abbreviation: rt.abbreviation
        }));
        return plcTypesWithAbbreviation.toDictionary('id');
    }

    public get displayPercentage() {
        const coverageLength = Object.keys(this.coverageDisplayData).filter(k => !this.coverageDisplayData[k].isDisabled).length;
        if (coverageLength === 1) {
            return 65 / coverageLength / 2;
        } else {
            return 80 / coverageLength / 2;
        }
    }

    public get displayCoveragePercentage() {
        const coverageLength = Object.keys(this.coverageDisplayData).filter(k => !this.coverageDisplayData[k].isDisabled).length;
        return (90 - this.displayPercentage * 2) / coverageLength;
    }
}
