import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NegotiationReportComponent } from 'app/pages/negotiationReport';

@Injectable()
export class NegotiationGuard implements CanDeactivate<NegotiationReportComponent> {
    canDeactivate(component: NegotiationReportComponent) {
        return component.canDeactivate();
    }
}
